import { useCallback, useState } from 'react';

function FiltroOnda({ OnSetWave }) {
  const [onda, setOnda] = useState(0);

  const handleFilter = useCallback ((evt) => {
    evt.preventDefault();
    if (onda === 0 || onda === undefined || onda === null) return;
    if (OnSetWave) OnSetWave(onda);
  }, [onda, OnSetWave])

  return <div className="section-filters-bar v2" style={{ height: 'auto' }}>
    <form className="form">
      <div className="form-item split medium">
        <div className="form-select">
          <label htmlFor="quest-filter-show">Onda</label>
          <select id="quest-filter-show" defaultValue={onda.onda} onChange={(evt) => setOnda(evt.target.value)}>
            <option value="">Selecionar</option>
            <option value="1">Onda 1</option>
            <option value="2">Onda 2</option>
            <option value="3">Onda 3</option>
            <option value="4">Onda 4</option>
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>

        <button className="button secondary" onClick={handleFilter}>Filtrar</button>
      </div>
    </form>
  </div>
}

export default FiltroOnda;
