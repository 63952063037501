import styled from 'styled-components';

export const Dropbox = styled.div`
    position: absolute;
    z-index: 9999;
    top: 64px;
    right: 6px;
    opacity: ${props => props.active ? 1 : 0};
    visibility: ${props => props.active ? 'visible' : 'hidden'} ;
    transform: ${props => props.active ? 'translate(0px)' : 'translate(0px, -40px)'};
    transition: transform 0.4s ease-in-out 0s, opacity 0.4s ease-in-out 0s, visibility 0.4s ease-in-out 0s;
    width: 230px !important;
`;

export const Logo = styled.img`
    width: 100%;
    max-width: 125px;
    margin-top: -10px;
    max-height: 125px;
    height: auto;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-left: ${props => props.marginLeft}px;
    transition: all 0.4s ease-in-out 0s;
`;

export const LeaveButton = styled.button`
  i {
    margin-right: 16px;
  }
`

export const DownloadButton = styled.button`
  display: block;
  padding: 8px 0;
  font-size: .875rem;
  font-weight: 700;
  transition: padding .2s ease-in-out,color .2s ease-in-out;
  background-color: transparent;
  text-align: left;
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;

  &:focus {
    outline: none;
  }

  &:hover {
    padding-left: 4px;
    color: #00c7d9;
  }
`