import React, { useContext, useState } from "react";

const DesktopMenuContext = React.createContext();

export default function DesktopMenuProvider ({ children }) {
    const [open, setOpen] = useState(false);

    return (
        <DesktopMenuContext.Provider value={{ open, setOpen }}>
            {children}
        </DesktopMenuContext.Provider>
    );
};

export function useMenu () {
    const context = useContext(DesktopMenuContext)

    if (!context) throw new Error("useMenu must be used within a DesktopMenuProvider");

    const { open, setOpen } = context;

    return { open, setOpen };
}
