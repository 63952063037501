import { useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import api from "../../services/api";
import PageLoaderComponent from "../../components/PageLoader";
import BannerTitle from "../../assets/img/banner/marketplace-icon.png"
import BannerTitleComponent from "../../components/BannerTitle"
import Template from "../../template";
import CheckoutItem from "../../components/Checkout/Item";
import CheckoutCartTotal from "../../components/Checkout/CartTotal";
import InputText from "../../components/Forms/InputText";
import Modal from "../../components/Modal";

export default function Checkout (props) {
  const [data, setData] = useState();
  const [address, setAddress] = useState();
  const [modal, setModal] = useState(false);

  const loadData = async () => {
    try {
      const { data } = await api.get('/checkout/6382');
      setData(data);
    } catch (e) {
      if (e.response.data) {
        alert(e.response.data.message);
      } else {
        alert("Ocorreu um erro inesperado");
      }

      window.location.href = "/marketplace";
    }
  }

  const loadAddress = async () => {
    const { data } = await api.get('/me/address');
    if (!data.address) {
      setAddress({});
      return;
    }
    setAddress(data.address);
  }

  const cartTotal = useMemo(() => {
    if (!data) return;
    return parseFloat(data.valorTotaldosProdutos).toFixed(2).toString().replace(/\./g, ',');
  }, [data]);

  const valorFrete = useMemo(() => {
    if (!data) return;
    return parseFloat(data.valorFrete).toFixed(2).toString().replace(/\./g, ',');
  }, [data])

  const valorTotal = useMemo(() => {
    if (!data) return;
    return parseFloat(data.valorTotaldoPedido).toFixed(2).toString().replace(/\./g, ',');
  }, [data])

  const handleSaveAddress = useCallback(async (e) => {
    e.preventDefault();
    try {
      await api.post('/me/address', { ...address });
      toast.success('Os dados foram atualizado com sucesso', { theme: "colored" });
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar conectar com o servidor', { theme: "colored" });
    }
  }, [address]);

  useEffect(() => {
    loadData();
    loadAddress();
  }, []);

  if (!data || !address) return <PageLoaderComponent />;
  return <Template>

    <BannerTitleComponent
      imgUrl={BannerTitle}
      title="Marketplace"
      subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
      imgAlt="marketplace-icon"
    />

    <div className="grid grid-8-4 small-space">
      <div className="grid-column">
        <div className="widget-box">
          <p className="widget-box-title">Detalhes do pagamento</p>

          <div className="widget-box-content">
            <form className="form">
              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Bairro" value={address.bairro} onChange={(value) => setAddress({ ...address, bairro: value })} />
                </div>

                <div className="form-item">
                  <InputText label="Cidade" value={address.cidade} onChange={(value) => setAddress({ ...address, cidade: value })} />
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Estado" value={address.estado} onChange={(value) => setAddress({ ...address, estado: value })} />
                </div>

                <div className="form-item">
                  <InputText label="Cep" value={address.cep} onChange={(value) => setAddress({ ...address, cep: value })} />
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Logradouro" value={address.logradouro} onChange={(value) => setAddress({ ...address, logradouro: value })} />
                </div>

                <div className="form-item">
                  <InputText label="Complemento" value={address.complemento} onChange={(value) => setAddress({ ...address, complemento: value })} />
                </div>
              </div>


              <div className="form-row split">
                <div className="form-item">
                  <InputText label="Numero" value={address.numero} onChange={(value) => setAddress({ ...address, numero: value })} />
                </div>

                <div className="form-item">
                  <InputText label="Referencia" value={address.referencia} onChange={(value) => setAddress({ ...address, referencia: value })} />
                </div>
              </div>

              <button className="button primary" style={{ marginTop: '16px' }} onClick={handleSaveAddress}>Savar endereco</button>
            </form>
          </div>
        </div>
      </div>

      <div className="grid-column">
        <div className="sidebar-box">
          <p className="sidebar-box-title">Resumo do pedido</p>

          <div className="sidebar-box-items">
            <div className="totals-line-list separator-bottom">
              {data.produtos.map((p) => <CheckoutItem {...p} />)}

            </div>

            <div className="totals-line-list separator-bottom">
              <CheckoutCartTotal value={cartTotal} />

              <div className="totals-line">
                <p className="totals-line-title">Frete</p>

                <p className="price-title"><span className="currency"></span> {valorFrete}</p>
              </div>

              <div className="totals-line">
                <p className="totals-line-title">Total</p>

                <p className="price-title"><span className="currency"></span> {valorTotal}</p>
              </div>
            </div>

            <p className="price-title big separator-bottom"> {valorTotal} <span className="currency">Moedas</span></p>
          </div>

          <div className="sidebar-box-items">
            <button typ="button" className="button primary" onClick={(e) => {
              e.preventDefault();
              setModal(true)
            }}>Fazer pedido!</button>
          </div>
        </div>
      </div>
    </div>

    <Modal showModal={modal} title="Obrigado!" onClose={() => setModal(false)}>
      <form className="form">
        <div className="form-row">
          <p>Em breve seu pedido chegará no endereço cadastrado</p>
        </div>

        <div className="popup-box-actions medium void">
          <Link to="/marketplace" className="popup-box-action full button secondary">Continuar resgatando pontos</Link>
        </div>
      </form>
    </Modal>
  </Template >
}