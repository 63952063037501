import * as S from './style'
import Template from '../../../template';
import fundo from '../../../assets/mecanicas/fundo-pascoa.jpg';
import formas from '../../../assets/mecanicas/formas.png';
import pascoaImage from '../../../assets/img/pascoa.png'
import logoGigantes from '../../../assets/mecanicas/logo-principal.png'
import '../style.css'

function MecanicasPascoaPadrao () {
  return <S.Pascoa className="pascoa" style={{backgroundImage: `url(${fundo})`}}><Template>
    <S.Container>

    <div className="gigantes">
      <img src={logoGigantes} alt="Logo gigantes da execução 2022" />
    </div>

    <S.Title>
      <h1>MECÂNICA <span>PÁSCOA</span></h1>
    </S.Title>

    <S.SubTitle>
      <div className="amarelo">
        <h2>Bateu Levou</h2>
      </div>
    </S.SubTitle>

    <S.ImageMobileOnce src={pascoaImage} alt="" className="mobile-once" />

    <S.Content>
      <S.Formas>
        <img src={formas} alt="formas" />
        <p>Páscoa</p>
        <div class="vantagens">
          <h4>0,5%</h4>
          <h5>Devolução Total Brasil</h5>
          <h1>VENDAS</h1>
          <h2>500 pontos</h2>
          <h3>1 ponto = 1 real</h3>
        </div>
      </S.Formas>

      <div class="site">
        <h1>Acesse já, através do site Gigantes da Execução</h1>
        <a href="www.gigantesdaexecucao2022.com">www.gigantesdaexecucao2022.com</a>
      </div>
    </S.Content>

  </S.Container>
</Template></S.Pascoa>
}

export default MecanicasPascoaPadrao;
