import { useCallback, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import Template from '../../template';
import BannerTitleComponent from "../../components/BannerTitle";
import PageLoaderComponent from '../../components/PageLoader';
import InputText from '../../components/Forms/InputText';
import InputSelect from '../../components/Forms/InputSelect';
import Icon from '../../assets/img/banner/overview-icon.png';
import * as S from './style';

function LogAcesso () {
  const [acessos, setAcessos] = useState();
  const [acessosFiltrados, setAcessosFiltrados] = useState([]);
  const [filter, setFilter] = useState({ name: ''});
  const { doLogout } = useAuth();
  const loadApiData = useCallback(async () => {
    try {
      const response = await api.get(`/access-log/count`);
      setAcessos(response.data.signInLogCount);
      setAcessosFiltrados(response.data.signInLogCount);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('Seu token expirou', { theme: "colored" });
          doLogout();
          return;
        }

        toast.error('Ocorreu um erro interno no servidor', { theme: "colored" });
      }
    }
  }, [doLogout]);

  const handleFilter = useCallback((evt) => {
    evt.preventDefault();
    let filtrados = acessos;
    if (filter.name) {
      filtrados = filtrados.filter(acesso => {
        return acesso.name.startsWith(filter.name);
      });
    }

    console.log(filter);
    if(filter.acesso) {
      filtrados = filtrados.filter(acesso => {
        if (filter.acesso === '0')
          return parseInt(acesso.accessCount, 10) === 0;
        
        return parseInt(acesso.accessCount, 10) > 0;
      });
    }
    setAcessosFiltrados(filtrados);
  }, [filter, acessos]);

  useEffect(() => {
    loadApiData();
  }, [loadApiData])

  if (!acessosFiltrados) return <PageLoaderComponent />

  return <Template>
    <BannerTitleComponent
      imgUrl={Icon}
      title="Acessos"
      subTitle="Veja o acesso dos usuários ao sistema"
      imgAlt="accounthub-icon"
    />

    <div className="section-filters-bar v2" style={{height: 'auto'}}>
      <form className="form">
        <div className="form-item split medium">
          <div className="form-select">
            <InputText value={filter.name || ""} onChange={(value) => setFilter({...filter, name: value})} label="Filtrar por nome" />
          </div>

          <div className="form-select">
            <InputSelect label="Acessos" value={filter.acesso} onChange={(v) => setFilter({...filter, acesso: v})}>
              <option value="0">Sem acessos</option>
              <option value="1">Com acessos</option>
            </InputSelect>
          </div>

          <button className="button secondary" onClick={handleFilter}>Filtrar</button>
        </div>
      </form>
    </div>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle">Acessos</p>

        <h2 className="section-title">Contage de acessos</h2>

      </div>
    </div>

    <div className="widget-box" style={{marginTop: '32px'}}>
    <p className="widget-box-title">Resumo do Resultado</p>

    <div className="widget-box-content no-margin-top">
      <S.Table>
        <thead>
          <tr>
            <th>Usuário</th>
            <th>Contagem de acessos</th>
          </tr>
        </thead>

        <tbody>
          {acessosFiltrados.map(result => <tr>
            <td>{result.name}</td>
            <td>{result.accessCount}</td>
          </tr>)}
        </tbody>
      </S.Table>
    </div>
  </div>

  </Template>
}

export default LogAcesso;
