import { useState } from 'react';
import Template from '../../template';
import BannerTitle from '../../components/BannerTitle';
import Icon from '../../assets/img/banner/overview-icon.png';
import Accordion from '../../components/Accordion';
import * as S from './style';

function Faq () {
  const [activeAccordion, setActiveAccordion] = useState(-1);
  return <Template>
    <BannerTitle
        imgUrl={Icon}
        imgAlt="overview-icon"
        title="FAQ"
        subTitle="As dúvidas mais frequêntes são encontradas aqui!"
      />
  
    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
  
        <h2 className="section-title">{""}</h2>
      </div>
    </div>

    <div className="widget-box">
      
  
      <p style={{fontSize: '24px', padding: '3% 15%'}} className="widget-box-title">
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final da ela.
      </p>
  
      <div className="widget-box-content">
        <div className="simple-accordion-list">
          <Accordion
              index={0}
              question="1. Quem participa da Campanha?"
              isOpen={activeAccordion === 0}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >Todos da equipe de Vendas e Merchandising convidados pela Mondelez a participarem da campanha.</Accordion>

          <Accordion
              index={1}
              question="2. Como faço para saber a minha meta?"
              isOpen={activeAccordion === 1}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >Suas metas estarão disponíveis no site e no celular durante todo o período da campanha. Acesse o menu e clique no ícone “Metas”.</Accordion>
          
          <Accordion
              index={2}
              question="3. Qual o período da campanha?"
              isOpen={activeAccordion === 2}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
          >A campanha terá duração de 01 de fevereiro a 31 de dezembro/2022. Resgates de prêmios até o dia 31/03/2023.</Accordion>
  
          <Accordion
              index={3}
              question="4. Os pontos são acumulativos?"
              isOpen={activeAccordion === 3}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Sim. Você pode acumular seus pontos e resgatá-los no mês em que forem creditados, ou durante o período da campanha de 2022.</Accordion>
  
          <Accordion
              index={4}
              question="5. Como eu faço para acumular estrelas mensalmente?"
              isOpen={activeAccordion === 4}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Para acumular estrelas, basta bater suas metas mensais. A quantidade de estrelas de cada meta será previamente informado na plataforma.</Accordion>

          <Accordion
              index={5}
              question="6. Caso eu esteja dentro do ranking de premiados, como faço para resgatar os prêmios?"
              isOpen={activeAccordion === 5}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Acesse o link de resgate de prêmios, insira suas informações para receber no endereço e cadastrar na loja parceira/escolhida.</Accordion>

          <Accordion
              index={6}
              question="7. Caso o prêmio entregue esteja avariado, quem devemos acionar?"
              isOpen={activeAccordion === 6}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Entre em contato com o SAC na plataforma na área <strong>fale conosco</strong> na plataforma <a href="mail:contato@gigantesdaexecucao2022.com">contato@gigantesdaexecucao2022.com</a></Accordion>
  
          <Accordion
              index={7}
              question="8. Quem acionar caso seja identificado erro no cálculo da Meta?"
              isOpen={activeAccordion === 7}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Entre em contato com o SAC Gigantes na área <strong>fale conosco</strong> na plataforma no <a href="mail:contato@gigantesdaexecucao2022.com">contato@gigantesdaexecucao2022.com</a></Accordion>

          <Accordion
              index={8}
              question="9. Quais os KPI´s de cada onda?"
              isOpen={activeAccordion === 8}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Verifique a mecânica completa e separada por canal dentro da plataforma.</Accordion>

          <Accordion
              index={9}
              question="10. Quais os produtos de cada onda?"
              isOpen={activeAccordion === 9}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Verifique a mecânica completa e separada por canal dentro da plataforma.</Accordion>

          <Accordion
              index={10}
              question="11. Qual o período de duração de cada onda?"
              isOpen={activeAccordion === 11}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
            <>
              <p style={{fontSize: '18px'}} className="simple-accordion-text">1ª onda: fevereiro/março/abril</p>
              <p style={{fontSize: '18px'}} className="simple-accordion-text">2ª onda: maio/junho/julho</p>
              <p style={{fontSize: '18px'}} className="simple-accordion-text">3ª onda: agosto/setembro/outubro</p>
              <p style={{fontSize: '18px'}} className="simple-accordion-text">4ª onda: novembro/dezembro</p>
            </>
          </Accordion>

          <Accordion
              index={11}
              question="12. Qual o fluxo da campanha?"
              isOpen={activeAccordion === 12}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              <ul>
                <li><p style={{fontSize: '18px', textDecoration: 'dotted'}} className="simple-accordion-text">Execução</p></li>
                <li><p style={{fontSize: '18px'}} className="simple-accordion-text">Apuração – avaliação e cumprimento de metas.</p></li>
                <li><p style={{fontSize: '18px'}} className="simple-accordion-text">Divulgação - Realizada em <strong>60</strong> dias a contar do final da apuração*.</p></li>
              </ul>
                <p style={{fontSize: '14px'}} className="simple-accordion-text">*Exceção a pontuação referente à Páscoa: divulgação será feita até 30 de setembro de 2022.</p>
              <ul>
                <li><p style={{fontSize: '18px'}} className="simple-accordion-text"> - Premiação - Realizada em 30 dias após a divulgação do resultado</p></li>
              </ul>
          </Accordion>

          <Accordion
              index={12}
              question="13. Como funciona a pontuação em caso de mudança de cargo ou posição do participante?"
              isOpen={activeAccordion === 12}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              <p style={{fontSize: '18px', textDecoration: 'dotted'}} className="simple-accordion-text">
                Nesses casos os resultados já obtidos não poderão ser aproveitados para atingir suas novas metas.
                Se a alteração for dentro de algum cargo elegível de vendas 
                <strong>
                  Será considerado o resultado do canal em que a permanencia for maior.
                  Ex: 60% GKA e 40% no LMT. Resultado: Será considerdo o GKA que você ficou 60% da onda.
                </strong>
              </p>
          </Accordion>

          <Accordion
              index={13}
              question="14. Como fica a premiação caso do participante que for desligado?"
              isOpen={activeAccordion === 13}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >Em caso de desligamento, ele receberá a premiação de acordo com as metas que já tiver atingido até o momento do desligamento.</Accordion>

          <Accordion
              index={14}
              question="15. No caso de participantes afastados durante a campanha, como fica a pontuação?"
              isOpen={activeAccordion === 14}
              onOpen={(newIndex) => setActiveAccordion(newIndex)}
            >
              <S.OrdenedList>
                <S.ListItem>O Participante que permanecer afastado durante parte da duração da Campanha terá os pontos que obteve considerados proporcionalmente ao período efetivamente trabalhado.</S.ListItem>
                <S.ListItem>O Participante que permanecer afastado durante toda a duração da Campanha não será elegível ao Programa, pois não haverá prestação de serviços</S.ListItem>
              </S.OrdenedList>
            </Accordion>

        </div>
      </div>
    </div>
  </Template>;
}

export default Faq;