import React, { useEffect } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import Login from "./pages/Login";
import RecoveryPassword from "./pages/Login/Recovery";
import Activation from "./pages/Login/Activation";
import Home from "./pages/Home"
import Newsfeed from "./pages/Newsfeed"
import NewsfeedHistory from "./pages/NewsfeedHistory"
import Forums from "./pages/Forums";
import Overview from "./pages/Overview";
import Members from "./pages/Members";
import Badges from "./pages/Badges";
import Marketplace from "./pages/Marketplace";
import Product from "./pages/Marketplace/Product";
import Quests from "./pages/Quests";
import Quiz from "./pages/Quiz";
import TesteQuiz from "./pages/TesteQuiz";
import QuizInterno from "./pages/QuizInterno";
import QuizTotal from "./pages/QuizTotal";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Treinamentos from "./pages/Treinamentos";
import Days100 from "./pages/Treinamentos/100days";
import AprendaAVender from "./pages/Treinamentos/AprendaAVender";
import Digital2021 from "./pages/Treinamentos/Digital2021";
import ConstrutorDeVendas from "./pages/Treinamentos/ConstrutorDeVendas";
import Perfil from "./pages/Perfil";
import Usuarios from "./pages/Usuarios";
import AddNewUser from "./pages/AddNewUser";
import EditUser from "./pages/EditUser";
import Category from "./pages/Category";
import AdminNotice from "./pages/AdminNotice";
import AdminQuiz from "./pages/AdminQuiz";
import AdminNoticeUser from "./pages/AdminNoticeUser";
import FaleConosco from "./pages/FaleConosco";
import Faq from "./pages/Faq";
import PoliticaDeUso from "./pages/PoliticaDeUso";
import Regulamento from "./pages/Regulamento";
import Score from "./pages/Score";
import Mecanicas from './pages/Mecanicas'
import MecanicaPascoa from './pages/MecanicasPascoa'
import MecanicaBoost from './pages/MecanicasBoost'
import ResultadosPorMembro from './pages/ResultadosPorMembro'
import Resultados from './pages/Resultados'
import Manutencao from './pages/Maintenance'
import LogAcesso from './pages/LogAcesso'
import Ranking from './pages/Ranking'
import RankingQuizAtivo from './pages/RankingQuizTang'
import NotFound from "./pages/404";
import AdminUpload from "./pages/AdminUpload";
import ExternalAuth from './pages/ExternalAuth'
import SurveyAnsers from "./pages/Survey/Answers";

const isAuthenticated = (adminOnly) => {
  const hasUserData = localStorage.getItem('user');
  if (!hasUserData) return false;
  const userData = JSON.parse(hasUserData);
  const useIsActive = userData.active;
  const useAceptedTerms = !userData.activationToken;

  // ToDo: Mudar para roles e pegar a role admin
  if (userData.document !== '333.333.331-71' && adminOnly) {
    return false
  }

  return useIsActive && useAceptedTerms
}

const PrivateRoute = ({ component: Component, adminOnly, componentProps, ...rest }) => {
  window.loadChat();

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated(adminOnly) ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect to={{ pathname: "/" }} />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/home" }} />
        )
      }
    />
  )
};

export default function Rotas() {
  const history = useHistory()

  useEffect(() => {
    return history.listen((location) => {
      // ReactGA.pageview(window.location.pathname + window.location.search);
    })
  }, [history])
  return (
    <Switch>
      <PublicRoute component={Login} path="/" exact />
      <Route component={Activation} path="/activation/:token" />
      <Route component={ExternalAuth} path="/auth" />
      <Route component={PoliticaDeUso} path="/politica-de-uso" />
      <PublicRoute component={RecoveryPassword} path="/recovery/:token" />
      <PrivateRoute component={Home} path="/home" />
      <PrivateRoute component={Newsfeed} path="/newsfeed" />
      <PrivateRoute component={NewsfeedHistory} path="/historico-de-noticias" />
      <PrivateRoute component={Forums} path="/forums" />
      <PrivateRoute component={Overview} path="/overview" />
      <PrivateRoute component={Badges} path="/badges" />
      <PrivateRoute component={Quests} path="/quests" />
      <PrivateRoute component={QuizTotal} path="/quiz/total/:quizId" />
      <PrivateRoute component={QuizInterno} path="/quiz/:quizId" />
      <PrivateRoute component={Quiz} path="/quiz" />
      <PrivateRoute component={TesteQuiz} path="/teste-quiz" />
      <PrivateRoute component={Perfil} path="/perfil" />
      <PrivateRoute component={Cart} path="/carrinho" />
      <PrivateRoute component={Checkout} path="/checkout" />
      <PrivateRoute component={Treinamentos} path="/treinamentos" exact />
      <PrivateRoute component={Days100} path="/treinamentos/100-days" />
      <PrivateRoute component={AprendaAVender} path="/treinamentos/aprenda-a-vender" />
      <PrivateRoute component={Digital2021} path="/treinamentos/digital-2021" />
      <PrivateRoute component={ConstrutorDeVendas} path="/treinamentos/construtor-de-vendas" />
      <PrivateRoute component={Marketplace} path="/marketplace" />
      <PrivateRoute component={Category} path="/marketplace-category" />
      <PrivateRoute component={Product} path="/product/:cod" />
      <PrivateRoute component={FaleConosco} path="/fale-conosco" />
      <PrivateRoute component={Faq} path="/faq" />
      <PrivateRoute component={Regulamento} path="/regulamento" />
      <PrivateRoute component={Score} path="/score" />
      <PrivateRoute component={Members} path="/members" />
      <PrivateRoute component={SurveyAnsers} path="/admin/respostas" />
      <PrivateRoute adminOnly={true} component={AdminNotice} exact path="/admin/notice" />
      <PrivateRoute adminOnly={true} component={AdminQuiz} exact path="/admin/quiz" />
      <PrivateRoute adminOnly={true} component={Usuarios} path="/admin/usuarios" exact />
      <PrivateRoute adminOnly={true} component={AddNewUser} path="/admin/usuarios/novo" />
      <PrivateRoute adminOnly={true} component={EditUser} path="/admin/usuarios/:userId" />
      <PrivateRoute adminOnly={true} component={AdminUpload} path="/admin/resultados" componentProps={{ type: 'results' }}/>
      <PrivateRoute adminOnly={true} component={AdminUpload} path="/admin/ranking" componentProps={{ type: 'ranking' }}/>
      <PrivateRoute component={AdminNoticeUser} path="/admin/notice/:userId" />
      <PrivateRoute component={Mecanicas} exact path="/mecanicas" />
      <PrivateRoute component={Mecanicas} path="/mecanicas/:mecanica" />
      <PrivateRoute component={MecanicaBoost} path="/mecanicas-boost" />
      <PrivateRoute component={MecanicaPascoa} path="/mecanica-pascoa" />
      <PrivateRoute component={ResultadosPorMembro} path="/resultados/:userId" />
      <PrivateRoute component={Resultados} path="/resultados" />
      <PrivateRoute component={Manutencao} path="/maintenance" />
      <PrivateRoute component={LogAcesso} path="/log-acessos" />
      <PrivateRoute component={Ranking} path="/ranking" />
      <PrivateRoute component={RankingQuizAtivo} path="/ranking-quiz" />
      <Route component={NotFound} path="*" />
    </Switch>
  );
}
