import { useCallback, useState } from 'react';
import api from '../../services/api';
import ptBR from 'date-fns/locale/pt-BR';
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import BannerTitleComponent from "../../components/BannerTitle";
import Template from "../../template";
import * as S from './style';
import DatePicker from 'react-datepicker';
import { v4 as uuidv4 } from 'uuid';
import { useFetch } from '../../hooks/useFetch';
import CardQuiz from '../../components/CardQuiz';
import InputText from '../../components/Forms/InputText';
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify';

const A_MONTH_MS = 30 * 24 * 60 * 60 * 1000

function AdminNotice() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() + A_MONTH_MS));
  const [saving, setSaving] = useState(false);
  const [newQuizTitle, setNewQuizTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const { data } = useFetch('/quiz');

  const handleSelectCorrect = useCallback((questionId, alternativeId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      const alternativeIndex = newItems[questionIndex].alternatives.indexOf(question.alternatives.find((alternative) => alternative?.id === alternativeId));

      const resetAlternatives = newItems[questionIndex].alternatives.map((alternative) => ({ ...alternative, is_correct: false }))
      newItems[questionIndex].alternatives = resetAlternatives;
      newItems[questionIndex].alternatives.splice(alternativeIndex, 1, {
        ...resetAlternatives[alternativeIndex],
        is_correct: true,
      })
      return newItems;
    })
  }, [setQuestions])

  const handleRemoveAlternative = useCallback((questionId, alternativeId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      const newAlternatives = newItems[questionIndex].alternatives.filter((alternative) => alternative.id !== alternativeId)
      newItems[questionIndex].alternatives = newAlternatives;
      return newItems;
    })
  }, [setQuestions])

  const handleRemoveQuestion = useCallback((questionId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const newQuestions = newItems.filter((question) => question.id !== questionId)
      return newQuestions;
    })
  }, [setQuestions])

  const handleAddQuestion = useCallback(() => {
    const newQuestion = {
      id: uuidv4(),
      title: "",
      alternatives: [
        {
          id: uuidv4(),
          title: "",
          is_correct: false,
        },
        {
          id: uuidv4(),
          title: "",
          is_correct: false,
        }
      ]
    }
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      newItems.push(newQuestion)
      return newItems;
    })
  }, [setQuestions])

  const handleAddAlternatives = useCallback((questionId) => {
    const newAlternative = {
      id: uuidv4(),
      title: "",
      is_correct: false,
    }

    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      if (newItems[questionIndex].alternatives.indexOf(newAlternative) === -1) {
        newItems[questionIndex].alternatives.push(newAlternative);
      }
      return newItems;
    })
  }, [setQuestions])

  const handleChangeStatement = useCallback((v, questionId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      newItems[questionIndex].title = v;
      return newItems;
    })
  }, [setQuestions])

  const handleChangeAlternativeTxt = useCallback((v, questionId, alternativeId) => {
    setQuestions((oldQ) => {
      const newItems = [...oldQ]
      const question = newItems.find((question) => question.id === questionId)
      const questionIndex = newItems.indexOf(question);
      const alternative = question.alternatives.find((alternative) => alternative.id === alternativeId)
      alternative.title = v
      question.alternative = alternative;
      newItems[questionIndex] = question;
      return newItems;
    })
  }, [setQuestions])

  const handleSubmitQuiz = async () => {
    setSaving(true);
    try {
      const response = await api.post('/quiz', {
        title: newQuizTitle,
        enableAt: startDate,
        disableAt: endDate,
        questions: questions.map((question) => {
          return {
            title: question.title,
            alternatives: question.alternatives.map((alternative) => (
              {
                title: alternative.title,
                is_correct: alternative.is_correct
              }))
          }
        })
      })
      setSaving(false)
      if (response.status === 200) {
        toast.success('Quiz enviado com sucesso!', { theme: "colored" })
        setQuestions([])
        setNewQuizTitle("")
        setStartDate(new Date())
        setEndDate(new Date(new Date().getTime() + A_MONTH_MS))
      }
      return;
    } catch (error) {
      setSaving(false)

      if (error.response) {
        toast.error(error.response.data.message || error.response.statusText, {
          theme: "colored"
        });
        return;
      }
      toast.error('Ocorreu um erro no servidor', {
        theme: "colored"
      });
      return;
    }
  }

  return <Template>
    <BannerTitleComponent
      imgUrl={AccountHubIcon}
      title="Quiz"
      subTitle="Gerencie os quizzes"
      imgAlt="accounthub-icon"
    />
    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="section-header">
          <div className="section-header-info">
            <p className="section-pretitle">Quiz Admin</p>
            <h2 className="section-title">Adicione um novo quiz</h2>
          </div>
        </div>
        <div className="grid-column">
          <div className="widget-box">
            <div className="widget-box-content">
              <form className="form" action="" method="POST" onSubmit={(e) => { e.preventDefault(); handleSubmitQuiz() }}>
                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Título" value={newQuizTitle} onChange={(v) => setNewQuizTitle(v)} />
                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <S.DatePickerLabel>Data de início</S.DatePickerLabel>
                    <DatePicker
                      locale={ptBR}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy HH:mm"
                      selected={startDate}
                      minDate={new Date()}
                      timeCaption="hora"
                      minTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 17)}
                      maxTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 20)}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="form-item">
                    <S.DatePickerLabel>Data de encerramento</S.DatePickerLabel>
                    <DatePicker
                      locale={ptBR}
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={60}
                      dateFormat="dd/MM/yyyy HH:mm"
                      selected={endDate}
                      minDate={new Date()}
                      timeCaption="hora"
                      minTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 17)}
                      maxTime={new Date().setHours(new Date().setMinutes(new Date(), 15), 20)}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <div className="section-header">
                      <div className="section-header-info">
                        <p className="section-pretitle">Questões</p>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleAddQuestion()}>+ Questão</button>
                  </div>
                </div>
                <hr />
                <div className="form-row split">
                  <div className="form-item">
                    {questions.map((question, idx) => (
                      <S.NewQuestion key={`question-${question.id}`}>
                        <InputText
                          label={`Questão ${idx + 1}`}
                          value={question.title}
                          onChange={(v) => handleChangeStatement(v, question.id)} />
                        <S.NewAlternatives>
                          <div className="form-row split">
                            {question.alternatives.map((alternative, altIdx) => (
                              <div key={`question-${question.id}-alternative-${alternative.id}`} className="form-item">
                                <S.AlternativeBox>
                                  <InputText
                                    label={`Alternativa ${altIdx + 1}`}
                                    value={alternative.title}
                                    onChange={(v) => handleChangeAlternativeTxt(v, question.id, alternative.id)} />
                                  <div>
                                    <button
                                      type="button"
                                      onClick={() => handleSelectCorrect(question.id, alternative.id)}
                                      className={`${questions[idx].alternatives[altIdx].is_correct ? "correct" : ""}`}
                                    >
                                      CORRETA
                                    </button>
                                    <button
                                      type="button"
                                      onClick={(e) => { e.preventDefault(); handleRemoveAlternative(question.id, alternative.id) }}
                                      className="danger"
                                    >
                                      X
                                    </button>
                                  </div>
                                </S.AlternativeBox>
                              </div>
                            ))}
                          </div>
                        </S.NewAlternatives>
                        <div className="question-button-container">
                          <button
                            type="button"
                            className="button"
                            onClick={(e) => { e.preventDefault(); handleAddAlternatives(question.id) }}
                          >
                            + Alternativas
                          </button>
                          <button
                            type="button"
                            className="button remove danger"
                            onClick={(e) => { e.preventDefault(); handleRemoveQuestion(question.id) }}
                          >
                            X
                          </button>
                        </div>
                        <hr />
                        <br />
                      </S.NewQuestion>
                    ))}

                  </div>
                </div>
                <div className="form-row split">
                  <div className="form-item">
                    <button
                      type="submit"
                      className="button"
                      disabled={saving}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="section-header">
          <div className="section-header-info">
            <h2 className="section-title">Selecione um quiz para editar</h2>
          </div>
        </div>

        <div className="grid-column">
          <div className="widget-box">
            <div className="widget-box-content">
              {data?.quizzes?.length > 0 && <div className="grid">
                {data?.quizzes?.map(quiz => <CardQuiz template="trofeu" key={quiz.id} quiz={quiz}></CardQuiz>)}
              </div>}
              {data?.quizzes?.length ?? <p style={{ textAlign: "center", marginTop: '64px', fontSize: '1rem' }}><strong>Não há quizzes para edição</strong></p>}
            </div>
          </div>
        </div>

      </div>
    </div>
  </Template>
}

export default AdminNotice;