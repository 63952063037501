import styled from "styled-components";

export const Container = styled.div``

export const Rank = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img{
    width: 120px;
  }

  div {
    position: absolute;
    inset: 0;
    color: var(--light);
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    small {
      font-size: 40%;
      display: block;
      margin-bottom: -8px;
    }
  }
  `;
  