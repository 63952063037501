import styled, { css } from "styled-components";

export const ProgressContainer = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid var(--dark);
  width: 90%;
  height: 20px;
  border-radius: 8px;
  margin-top: 16px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.progressValue || 0}%;
  position:absolute;
  background-color:var(--primary);
  top: 0;
  bottom: 0;
`;

export const ErrorTooltip = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  right: 0;
  text-align: center;
  background-color: var(--danger);
  color: var(--light);
`

export const Box = styled.div`
  position: relative;
  z-index: 2;
  overflow: hidden;

  ${({error}) => error && css`
    border: 1px solid var(--danger);
  `}
`

export const SvgIcon = styled.svg`
  fill: var(--text-light);
  width: 20px;
  height: 20px;
  transition: fill .25s ease-in-out;
`;

export const UploadBoxText = styled.p`
  margin-top: 4px;
  color: var(--text-light);
  font-size: .75rem;
  font-weight: 500;
`;