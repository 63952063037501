import { Fragment, useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';
import { useMenu } from '../../contexts/DesktopMenuContext';
import HexagonComponent from '../../components/Hexagon';
import DefaultAvatar from '../../assets/img/avatar.png';
import { Tooltip, Li } from "./style";
import * as S from "./style";
import { useMenuActive } from "../../contexts/MenuContext";
import cover from '../../assets/img/banner/banner-profile-stats.jpg';
import MenuMobile from './MenuMobile';


export default function SidebarComponent() {
  let location = useLocation();
  const { userCan } = useAuth();

  const { activeMenuMobile, setActiveMenuMobile } = useMenuActive()
  const { open } = useMenu();

  const { avatar, catalogUrl, doLogout, user } = useAuth();
  const Avatar = avatar ? avatar : DefaultAvatar;

  const name = useMemo(() => user ? user.name : "", [user]);

  if (!user) return <></>

  return (
    <Fragment>
      <nav id="navigation-widget-small" className={`navigation-widget navigation-widget-desktop closed sidebar left ${open ? 'delayed' : 'hidden'}`}>
        <Link className="user-avatar small no-outline online" to="/perfil">
          <div className="user-avatar-content">
            <HexagonComponent src={Avatar} alt="Avatar" className="Avatar" />
          </div>

          <div className="user-avatar-progress">
            <div className="hexagon-progress-40-44"></div>
          </div>

          <div className="user-avatar-progress-border">
            <div className="hexagon-border-40-44"></div>
          </div>

          <div className="user-avatar-badge">
            <div className="user-avatar-badge-border">
              <div className="hexagon-22-24"></div>
            </div>

            <div className="user-avatar-badge-content">
              <div className="hexagon-dark-16-18"></div>
            </div>

            <p className="user-avatar-badge-text"></p>
          </div>
        </Link>

        <ul className="menu small">
          <Li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/home">
              <svg className="menu-item-link-icon icon-info">
                <use href="#svg-info"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Home</p>
              </Tooltip>
            </Link>
          </Li>

          <Li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/home">
              <i class="fa-regular fa-square-poll-vertical"></i>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Pesquisa</p>
              </Tooltip>
            </Link>
          </Li>

          {user.canal === 'STAFF' && <Li className={`menu-item ${location.pathname === '/members' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/members">
              <svg className="menu-item-link-icon icon-group">
                <use href="#svg-group"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Membros</p>
              </Tooltip>
            </Link>
          </Li>}

          <Li className={`menu-item ${location.pathname === '/newsfeed' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/newsfeed">
              <svg className="menu-item-link-icon icon-newsfeed">
                <use href="#svg-newsfeed"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Canal de notícias</p>
              </Tooltip>
            </Link>
          </Li>

          {userCan('read:result') && <Li className={`menu-item ${location.pathname === '/resultados' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/resultados">
              <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Resultados</p>
              </Tooltip>
            </Link>
          </Li>}

          <Li className={`menu-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/ranking">
              <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Ranking</p>
              </Tooltip>
            </Link>
          </Li>

          <Li className={`menu-item ${location.pathname === '/quiz' ? 'active' : ''}`}>
            <Link className="menu-item-link text-tooltip-tfr" to="/quiz" data-title="Medalhas">
              <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Jogada extra</p>
              </Tooltip>
            </Link>
          </Li>

          <Li className={`menu-item ${location.pathname === '/ranking-quiz' ? 'active' : ''}`}>
            <Link className="menu-item-link text-tooltip-tfr" to="/ranking-quiz" data-title="Medalhas">
              <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Ranking do Quiz</p>
              </Tooltip>
            </Link>
          </Li>

          <Li className={`menu-item ${location.pathname === '/quests' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/quests">
              <svg className="menu-item-link-icon icon-quests">
                <use href="#svg-quests"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">KPI</p>
              </Tooltip>
            </Link>
          </Li>

          <Li className={`menu-item ${location.pathname === '/mecanicas' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/mecanicas">
              <S.AwesomeMenuIconSmall>
                <i className="fas fa-cog"></i>
              </S.AwesomeMenuIconSmall>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Mecânicas</p>
              </Tooltip>
            </Link>
          </Li>


          <Li className={`menu-item ${location.pathname === '/marketplace' ? 'active' : ''}`}>
            <a className="menu-item-link text-tooltip-tfr" href={catalogUrl} data-title="Marketplace" target="_blank" rel="noreferrer">
              <svg className="menu-item-link-icon icon-marketplace">
                <use href="#svg-marketplace"></use>
              </svg>
              <Tooltip className="xm-tooltip">
                <p className="xm-tooltip-text">Troca de pontos</p>
              </Tooltip>
            </a>
          </Li>
        </ul>
      </nav>

      <nav id="navigation-widget" className={`navigation-widget navigation-widget-desktop sidebar left ${open ? 'hidden' : 'delayed'}`} data-simplebar>
        <figure className="navigation-widget-cover liquid">
          <img src={cover} alt="cover-01" style={{ objectFit: 'cover' }} />
        </figure>

        <S.ShortDesc className="user-short-description">
          <HexagonComponent src={avatar} size="large" />

          <p className="user-short-description-title"><span>{name}</span></p>
          <p className="user-short-description-text"><span>&nbsp;</span></p>
        </S.ShortDesc>

        <ul className="menu">
          <li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/home">
              <svg className="menu-item-link-icon icon-info">
                <use href="#svg-info"></use>
              </svg>
              Home
            </Link>
          </li>

          {user.canal === 'STAFF' && <li className={`menu-item ${location.pathname === '/members' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/members">
              <svg className="menu-item-link-icon icon-group">
                <use href="#svg-group"></use>
              </svg>
              Membros
            </Link>
          </li>}

          <li className={`menu-item ${location.pathname === '/newsfeed' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/newsfeed">
              <svg className="menu-item-link-icon icon-newsfeed">
                <use href="#svg-newsfeed"></use>
              </svg>
              Canal de notícias
            </Link>
          </li>

          {userCan('read:result') && <li className={`menu-item ${location.pathname === '/resultados' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/resultados">
              <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Resultados
            </Link>
          </li>}

          <li className={`menu-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/ranking">
              <svg class="menu-item-link-icon icon-overview">
                <use href="#svg-overview"></use>
              </svg>
              Ranking
            </Link>
          </li>

          <li className={`menu-item ${location.pathname === '/quiz' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/quiz">
              <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
              </svg>
              Jogada extra
            </Link>
          </li>


          <li className={`menu-item ${location.pathname === '/ranking-quiz' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/ranking-quiz">
              <svg className="menu-item-link-icon icon-badges">
                <use href="#svg-badges"></use>
              </svg>
              Ranking do Quiz
            </Link>
          </li>

          <li className={`menu-item ${location.pathname === '/quests' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/quests">
              <svg className="menu-item-link-icon icon-quests">
                <use href="#svg-quests"></use>
              </svg>
              KPI
            </Link>
          </li>

          <li className={`menu-item ${location.pathname === '/mecanicas' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/mecanicas">
              <S.AwesomeMenuIconSmall>
                <i className="fas fa-cog"></i>
              </S.AwesomeMenuIconSmall>
              Mecânicas
            </Link>
          </li>

          <li className={`menu-item ${location.pathname === '/mecanicas-boost' ? 'active' : ''}`}>
            <Link className="menu-item-link" to="/mecanicas-boost">
              <S.AwesomeMenuIconSmall>
                <i className="fas fa-cog"></i>
              </S.AwesomeMenuIconSmall>
              Mecânicas Boost
            </Link>
          </li>

          <li className={`menu-item ${location.pathname === '/marketplace' ? 'active' : ''}`}>
            <a className="menu-item-link" href={catalogUrl} target="_blank" rel="noreferrer">
              <svg className="menu-item-link-icon icon-marketplace">
                <use href="#svg-marketplace"></use>
              </svg>
              Troca de pontos
            </a>
          </li>

          <li>&nbsp;</li>
        </ul>
      </nav>

      <nav id="navigation-widget-mobile" className={`navigation-widget navigation-widget-mobile sidebar left ${activeMenuMobile ? 'hidden' : ''}`} >
        <div className="simplebar-wrapper" style={{ margin: "0px 0px -40px" }}>

          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>

          <div className="simplebar-mask" onClick={() => setActiveMenuMobile(!activeMenuMobile)}>
            <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
              <div className="simplebar-content-wrapper">
                <div className="simplebar-content" style={{ padding: "0px 0px 40px" }}>

                  <div className="navigation-widget-close-button" onClick={() => setActiveMenuMobile(!activeMenuMobile)}>
                    <svg className="navigation-widget-close-button-icon icon-back-arrow">
                      <use href="#svg-back-arrow"></use>
                    </svg>
                  </div>

                  <div className="navigation-widget-info-wrap">
                    <div className="navigation-widget-info">
                      <Link className="user-avatar small no-outline" to="/perfil">
                        <div className="user-avatar-content">
                          <HexagonComponent src={Avatar} alt="Avatar" className="Avatar" />
                        </div>
                      </Link>

                      <p className="navigation-widget-info-title"><Link to="/perfil">{name}</Link></p>

                      <p className="navigation-widget-info-text">Bem vindo!</p>
                    </div>

                    <button
                      type="text"
                      onClick={doLogout}
                      className="navigation-widget-info-button button small secondary"
                    >
                      Sair
                    </button>
                  </div>

                  <p className="navigation-widget-section-title">Seções</p>

                  <MenuMobile catalogUrl={catalogUrl} user={user} />
                </div>
              </div>
            </div>
          </div>
          <div className="simplebar-placeholder" style={{ width: "auto", height: "1976px" }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }}>
          <div className="simplebar-scrollbar" style={{ height: "33px", transform: "translate3d(0px, 0px, 0px)", display: "block" }}></div>
        </div>
      </nav>
    </Fragment>
  )
}