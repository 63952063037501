import { useState, useCallback, useEffect, useMemo } from 'react';
import * as S from './style';

export default function InputText(props) {
  const [internalvalue, setInternalValue] = useState();
  const [isActive, setIsActive] = useState(false);
  const { name, id, label, value, onChange, placeholder, readonly, type, mask, disabled } = props;

  const isDirty = useMemo(() => {
    if (internalvalue) return true;
    if (isActive) return true;

    return false;
  }, [isActive, internalvalue]);

  const handleValueChange = useCallback((e) => {
    let value = e.target.value
    if(mask) value = mask(value)
    if (onChange) onChange(value);
    setInternalValue(value);
  }, [onChange, mask]);

  const isSmall = useMemo(() => !props.large, [props]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return <S.Container className={`form-input ${isSmall && 'small'} ${isDirty ? 'active' : ''}`}>
    <label htmlFor={id}>{label}</label>
    <input
      type={type || "text"}
      id={id}
      name={name}
      disabled={disabled}
      value={internalvalue || ""}
      onChange={handleValueChange}
      placeholder={placeholder}
      onFocus={() => setIsActive(true)}
      onBlur={() => setIsActive(false)}
      readOnly={readonly}
      maxLength={props.maxLength}
    />
  </S.Container>
}