import { useEffect, useState, useCallback } from 'react'
import api from '../../services/api'
import Template from '../../template'
import BannerTitleComponent from "../../components/BannerTitle";
import ResultsTable from '../../components/ResultsTable';
import FiltroResultados from '../../components/FiltroResultados';
import FiltroRanking from '../../components/FiltroRanking';
import RankingTable from '../../components/RankingTable';
import Icon from '../../assets/img/banner/overview-icon.png';
import { useUpdateData } from '../../hooks/useUpdateData';
import * as S from './style'

function Resultados() {

  const [filter, setFilter] = useState({});

  const [results, setResults] = useState();
  const [onda, setOnda] = useState(1);
  const [ranking, setRanking] = useState();

  const [showResults, setShowResults] = useState(true);

  const loadData = useCallback(async (params) => {
    setResults(null);
    if (!params) params = { tipo: 'results' };
    if (!params.onda) params.onda = 1;
    const response = await api.get('/results', {
      params
    });
    if (params) {
      if (params.channelName === 'GKA') {
        const byHolding = response.data.target.reduce((acc, r) => {
          const index = acc.findIndex(a => {
            return a.cdgHolding === r.cdgHolding
          })
          if (index === -1) {
            acc.push(r);
          }
          return acc;
        }, [])
        setResults(byHolding);
        return;
      }
    }
    setResults(response.data.target);
  }, []);

  const loadRankingData = useCallback(async (params) => {
    setRanking(null);
    if (!params) params = {};
    if (!params.onda) params.onda = 1;
    const response = await api.get('/rank-results', {
      params
    });
    if (params) {
      if (params.channelName === 'GKA') {
        const byHolding = response.data.target.reduce((acc, r) => {
          const index = acc.findIndex(a => {
            return a.cdgHolding === r.cdgHolding
          })
          if (index === -1) {
            acc.push(r);
          }
          return acc;
        }, [])
        setRanking(byHolding);
        return;
      }
    }
    setRanking(response.data.target);
  }, []);

  const handleLoadResults = useCallback(async (filter) => {
    setOnda(filter.onda);
    loadData(filter);
  }, [loadData]);
  
  const handleLoadRanking = useCallback(async (filter) => {
    setOnda(filter.onda);
    loadRankingData(filter);
  }, [loadRankingData]);

  const loadAllData = useCallback(async () => {
    await loadData();
    await loadRankingData();
  }, [loadData, loadRankingData])

  const periodoDado = useUpdateData();

  useEffect(() => {
    loadAllData();
  }, [loadAllData]);

  return <Template>
    <BannerTitleComponent
      imgUrl={Icon}
      title="Resultados"
      subTitle="Seu resumo, sua visão geral e mais"
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle">Resultados</p>

        <h2 className="section-title">Análise de desempenho</h2>
      </div>
    </div>

    <S.Toggle>
      <S.Button active={showResults} onClick={() => setShowResults(true)}>Resultados clientes</S.Button>
      <S.Button active={!showResults} onClick={() => setShowResults(false)}>Resultados por KPIs</S.Button>
    </S.Toggle>


    {showResults && <FiltroResultados OnFilter={handleLoadResults} OnChangeCanais={(canal, channel) => setFilter({ ...filter, canal, channel })} />}
    {!showResults && <FiltroRanking OnFilter={handleLoadRanking} OnChangeCanais={(canal, channel) => setFilter({ ...filter, canal, channel })} />}

    <S.TableArea>
      {showResults && <ResultsTable onda={onda} periodoDado={periodoDado(filter.channel?.name)} results={results} />}
      {!showResults && <RankingTable onda={onda} periodoDado={periodoDado(filter.channel?.name)} results={ranking} />}
    </S.TableArea>

  </Template>
}

export default Resultados;
