import { createRef, useEffect, useState } from 'react';
import { toast } from 'react-toastify'
import api from "../../../services/api";
import * as S from './styles';

export default function ImageUpload(props) {
  const [status, setStatus] = useState('awaiting');
  const [imgError, setImgError] = useState('awaiting');
  const [progress, setProgress] = useState('awaiting');
  const { title, text, onSend, error } = props;
  let fileInputRef = createRef();

  const allowerdFormats = ['image/jpeg', 'image/png'];

  const callForUpload = () => {
    if (status === 'awaiting') fileInputRef.click();
  }

  const handleUpload = async (e) => {
    const [file] = e.target.files;
    if (!file) return;
    if (!allowerdFormats.includes(file.type)) {
      console.log(file.type)
      toast.error('O formato do arquivo não é permitido', { theme: 'colored' })
      return
    }
    let formData = new FormData();
    formData.append("image", file);
    setStatus('sending');
    const response = await api.post('/upload', formData, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (e) => {
        let progress = Math.round((e.loaded * 100) / e.total);
        setProgress(progress);
      },
    });

    if (onSend) onSend(response.data);
    setStatus('completed')
    setTimeout(() => setStatus('awaiting'), 10000);
  }

  useEffect(() => {
    setImgError(error);
  }, [error])

  const statusList = {
    awaiting: <>
      <div style={{ width: '0px', height: '0px', overflow: 'hidden' }}>
        <input type="file" id="input" ref={(fileInput) => { fileInputRef = fileInput }} onChange={handleUpload} />
      </div>
      <S.SvgIcon>
        <use href="#svg-members"></use>
      </S.SvgIcon>

      <p className="upload-box-title">{title}</p>

      <S.UploadBoxText>{text}</S.UploadBoxText>
      </>,
    sending: <><p>Enviando: {progress}%</p>
      <S.ProgressContainer>
        <S.ProgressBar progressValue={progress} />
      </S.ProgressContainer>
    </>,
    completed: <>Sua imagem foi enviada com sucesso!</>
  }

  return (<S.Box className="upload-box" onClick={callForUpload} error={imgError}>
    {statusList[status]}

    {imgError && <S.ErrorTooltip>{imgError}</S.ErrorTooltip>}
  </S.Box>)
}