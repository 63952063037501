import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import HexagonComponent from '../../components/Hexagon';
import RankingImage from '../../components/RankingImage';
import star from '../../assets/img/banner/star.png';
import * as S from './style';

const Row = ({rowData, isRankingVisible}) => {
  const name = useMemo(() => {
    return <Link to={`/resultados/${rowData.id}`}>{rowData.name.toUpperCase()}</Link>
  }, [rowData.name, rowData.id])
  return <tr>
    {isRankingVisible && <S.PositionColumn><RankingImage position={rowData.rank} /></S.PositionColumn>}
    <td style={{padding: '16px'}} className="avatar"><HexagonComponent size="large" src={`${process.env.REACT_APP_IMG_URL}${rowData.path}`} /></td>
    <S.Name>{name}</S.Name>
    <td>
      <S.StarCell>
        {rowData.estrelas || 0}
        <S.Star src={star} alt="Estrela" />
      </S.StarCell>
    </td>
  </tr>
}


function RankingSTAFF ({ranking, tipo, canal, onda}) {
  const isRankingVisible = useMemo(() => {
    if (onda !== '2') return true
    return true
  }, [onda]);
  const nomeTipoRanking = useMemo(() => {
    switch(tipo) {
      case 'promotor':
        return 'Promotor';
      case 'gerente_de_area':
        return canal === 'MERCHANDISING' ? 'Supervisor' : 'GA';
      case 'gerente_regional':
        return canal === 'MERCHANDISING' ? 'Gerente' : 'GR';
      case 'executivo':
        return canal === 'MERCHANDISING' ? 'Coordenador' : 'Executivo';
      default:
        return '';
    }
  }, [tipo, canal])
  return <div className="widget-box-content">
    <S.Pergunta>
      <h4>Ranking {canal} {nomeTipoRanking}</h4>
      <p style={{fontSize: '.75rem', fontWeight: 'bold'}}>Ranking aplicado de acordo com o critério de desempate</p>

      <div className="widget-box">
        <S.Tabela>
          <table>
            <tr>
              {isRankingVisible && <th>Posição no ranking</th>}
              <th></th>
              <th className="avatar">Clique no nome para ver o resultado</th>
              <th>Estrelas</th>
            </tr>
            {ranking.map(r => <Row isRankingVisible={isRankingVisible} rowData={r} />)}

          </table>
        </S.Tabela>
      </div>
    </S.Pergunta>
  </div>
}

export default RankingSTAFF;
