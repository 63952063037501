import styled from 'styled-components';

export const SubmitButton = styled.button`
  min-width: 168px;
  width: auto;
  margin-top: 0;
  float: right;
  background-color: var(--button-primary);
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  display: inline-block;
  height: 48px;
  border-radius: 10px;
  color: var(--white);
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62,63,94,.2);
`;

export const FieldWithError = styled.div`
.form-input.small {
  margin-bottom: 0;
}
`;

export const InputWrapper = styled.div`
  display: flex;

  .form-input.small input {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
`

export const SmallError = styled.small`
  font-size: 60%;
  color: var(--danger);
`;

export const PasswordForm = styled.form`
margin-bottom: 28px;
`;

export const EyeButton = styled.button`
  	max-width: 60px;
    margin-left: -30px;
    z-index: 2;
`