import { useCallback, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import InputText from "../Forms/InputText";
import Checkbox from "../Forms/Checkbox";
import ImageUpload from "../Forms/ImageUpload";
import api from "../../services/api";
import { useAuth } from '../../contexts/AuthContext';
import Modal from '../Modal';
import { MaskCEP, MaskCPF, MaskPhone, MaskDate } from "../../utils/mask";
import * as S from './style'

function FormUserModal(props) {
    const { avatar, setAvatar, user: userData } = useAuth();
    const [user, setUser] = useState();
    const [address, setAddress] = useState();
    
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordError, setOldPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState();
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState();
    const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState();
    const [passwordType, setPasswordType] = useState('password');
    const [newPasswordType, setNewPasswordType] = useState('password');

    const [acceptInfo, setAcceptInfo] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [avatarError, setAvatarError] = useState("");
    const [acceptTermsError, setAcceptTermsError] = useState("");

    const handleAvatarUpload = useCallback(async (img) => {
        try {
            const response = await api.patch(`/me/avatar`, { avatarId: img.image.id });
            console.log(response);
            const newUser = { ...user, avatarId: img.image.id, avatar: img.image };
            setUser(newUser);
            localStorage.setItem('user', JSON.stringify(newUser));
            setAvatar(`${process.env.REACT_APP_IMG_URL}${newUser.avatar.path}`);
        } catch (e) { }
    }, [setAvatar, user]);

    const handleClose = useCallback(() => {
        props.onClose();
    }, [props]);

    const handleConfirm = useCallback(() => {
      if (user.avatar === null) {
        setAvatarError("Por favor envie um avatar");
        return;
      }

      if (!oldPassword) {
        setOldPasswordError("Por favor atualize sua senha");
        return;
      }

      if (!newPassword) {
        setNewPasswordError("Por favor atualize sua senha");
        return;
      }

      if (!newPasswordConfirmation) {
        setNewPasswordConfirmationError("Por favor atualize sua senha");
        return;
      }

      if (newPassword !== newPasswordConfirmation) {
        setNewPasswordError("A senha e a confirmação são diferentes");
        return;
      }

      if (acceptTerms === false) {
        setAcceptTermsError("Você precisa aceitar os termos para continuar");
        return;
      }

      props.onConfirm(user, address, acceptInfo, acceptTerms, oldPassword, newPassword);
    }, [user, props, address, acceptInfo, acceptTerms, newPassword, newPasswordConfirmation, oldPassword]);

    const handleAcceptInfo = useCallback((value) => {
      setAcceptInfo(value);
    }, []);

    const handleAcceptTerms = useCallback((value) => {
      setAcceptTerms(value);
    }, []);

    useEffect(() => {
      const newUserData = {...userData}
      if (userData.equipe) {
        if (!userData.canal && userData.equipe.toLowerCase() === "merchan") {
          newUserData.canal = "Merchandising"
        }
      }
      setUser(newUserData);
      setAddress(userData.address || {});
    }, [userData]);

    if(!user) return <></>

    return <Modal showModal={true} onClose={handleClose} title="Atualize seus dados" size="mid" style={{ position: 'fixed' }}>
        <div className="form">
        <div className="grid grid-3-3-3 centered">
        <div className="user-preview small fixed-height">
            <figure className="user-preview-cover liquid " style={{ "background": "url('avatar') center center / cover no-repeat" }}>
            <img src={avatar} alt="cover-01" style={{ "display": "none" }} />
            </figure>

            <div className="user-preview-info">
            <div className="user-short-description small">
                <div className="user-short-description-avatar user-avatar">
                <div className="user-avatar-border">
                    <div className="perfect-circle large">
                    <img src={avatar} alt="cover-01" />
                    </div>
                </div>

                <div className="user-avatar-content">
                    <div className="hexagon-image-68-74" data-src="img/avatar/01.jpg" style={{ width: "68px", height: "74px", position: "relative" }}><canvas width="68" height="74" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
                </div>

                <div className="user-avatar-progress">
                    <div className="hexagon-progress-84-92" style={{ width: '84px', height: '92px', position: 'relative', }}><canvas width="84" height="92" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
                </div>

                <div className="user-avatar-progress-border">
                    <div className="hexagon-border-84-92" style={{ width: '84px', height: '92px', position: 'relative', }}><canvas width="84" height="92" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
                </div>

                <div className="user-avatar-badge">
                    <div className="user-avatar-badge-border">
                    <div className="hexagon-28-32" style={{ width: '28px', height: '32px', position: 'relative', }}><canvas width="28" height="32" style={{ position: 'absolute', top: '0px', left: '0px' }}></canvas></div>
                    </div>

                    <div className="user-avatar-badge-content">
                    <div className="hexagon-dark-22-24" style={{ width: '22px', height: '24px', position: 'relative' }}><canvas width="22" height="24" style={{ position: 'absolute', top: '0px', left: '0px', }}></canvas></div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <ImageUpload title="Trocar Avatar" text="110x110px tamanho minimo" onSend={handleAvatarUpload} error={avatarError} />
        </div>

        <div className="widget-box">
        <p className="widget-box-title">Sobre seu perfil</p>

        <div className="widget-box-content">
          <form className="form" action="" method="POST">
            
          <div className="form-row split">
            <S.FieldWithError className="form-item">
              <S.InputWrapper>
                <InputText type={passwordType} name="old-password" label="Senha atual" id="old-password" value={oldPassword} onChange={(value) => {
                  setOldPassword(value);
                  setOldPasswordError("");
                }} />
                {
                  passwordType === "password" ?
                  <S.EyeButton type="button" className='button primary' onClick={() => setPasswordType("text")}>
                    <i class="far fa-eye"></i>
                  </S.EyeButton> :
                  <S.EyeButton type="button" className='button primary' onClick={() => setPasswordType("password")}>
                    <i class="far fa-eye-slash"></i>
                  </S.EyeButton>
                }
              </S.InputWrapper>
              {oldPasswordError && <S.SmallError>{oldPasswordError}</S.SmallError>}
            </S.FieldWithError>
            <S.FieldWithError className="form-item">
              <S.InputWrapper>
                <InputText type={newPasswordType} name="new-password" label="Nova senha" id="new-password" value={newPassword} onChange={(value) => {
                  setNewPassword(value);
                  setNewPasswordError("");
                }} />
                {
                  newPasswordType === "password" ?
                  <S.EyeButton type="button" className='button primary' onClick={() => setNewPasswordType("text")}>
                    <i class="far fa-eye"></i>
                  </S.EyeButton> :
                  <S.EyeButton type="button" className='button primary' onClick={() => setNewPasswordType("password")}>
                    <i class="far fa-eye-slash"></i>
                  </S.EyeButton>
                }
              </S.InputWrapper>
              {newPasswordError && <S.SmallError>{newPasswordError}</S.SmallError>}
            </S.FieldWithError>
            <S.FieldWithError className="form-item">
              <S.InputWrapper>
                <InputText type={newPasswordType} name="new-password-confirmation" label="Confirme a senha" id="new-password-confirmation" value={newPasswordConfirmation} onChange={(value) => {
                  setNewPasswordConfirmation(value);
                  setNewPasswordConfirmationError("");
                }} />
                {
                  newPasswordType === "password" ?
                  <S.EyeButton type="button" className='button primary' onClick={() => setNewPasswordType("text")}>
                    <i class="far fa-eye"></i>
                  </S.EyeButton> :
                  <S.EyeButton type="button" className='button primary' onClick={() => setNewPasswordType("password")}>
                    <i class="far fa-eye-slash"></i>
                  </S.EyeButton>
                }
              </S.InputWrapper>
              {newPasswordConfirmationError && <S.SmallError>{newPasswordConfirmationError}</S.SmallError>}
            </S.FieldWithError>
          </div>

            <div className="form-row split">
                <div className="form-item">
                <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                </div>

                <div className="form-item">
                <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} readonly={userData.email} />
                </div>
            </div>

            <div className="form-row split">
                <div className="form-item">
                <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                </div>
                <div className="form-item">
                <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskCPF(value) })} maxLength="14" />
                </div>
            </div>

            <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="canal" label="Canal" id="canal" value={user.canal} onChange={(value) => setUser({ ...user, canal: value })} readonly={true} />
                    </div>
                    <div className="form-item">
                      <InputText name="birthday" label="Data de nascimento" id="birthday" value={user.birthday} onChange={(value) => setUser({ ...user, birthday: value })} maxLength="10" mask={MaskDate} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cep" label="CEP" id="cep" value={address.cep} onChange={(value) => setAddress({ ...address, cep: MaskCEP(value) })} maxLength="9" />
                    </div>
                    <div className="form-item">
                      <InputText name="estado" label="Estado" id="estado" value={address.estado} onChange={(value) => setAddress({ ...address, estado: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cidade" label="Cidade" id="cidade" value={address.cidade} onChange={(value) => setAddress({ ...address, cidade: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="bairro" label="Bairro" id="bairro" value={address.bairro} onChange={(value) => setAddress({ ...address, bairro: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="logradouro" label="Rua" id="logradouro" value={address.logradouro} onChange={(value) => setAddress({ ...address, logradouro: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="numero" label="Numero" id="numero" value={address.numero} onChange={(value) => setAddress({ ...address, numero: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="complemento" label="Complemento" id="complemento" value={address.complemento} onChange={(value) => setAddress({ ...address, complemento: value })} />
                    </div>
                  </div>

            <div className="form-row split">
              <div className="form-item">
                <Checkbox
                    label="Aceito receber informações por e-mail e WhatsApp da Mondelez."
                    value={true}
                    onSelect={() => handleAcceptInfo(true)}
                    onDeselect={() => handleAcceptInfo(false)}
                  />
              </div>
              
              <div className="form-item">
                <Checkbox
                    label={<>Aceito os termos de privacidade da <a href="/MONDELEZ.pdf" target="_blank">Mondelez</a> e da <Link to="/politica-de-uso" target="_blank">plataforma</Link>.</>}
                    value={true}
                    onSelect={() => handleAcceptTerms(true)}
                    onDeselect={() => handleAcceptTerms(false)}
                    required={true}
                    error={acceptTermsError}
                  />
              </div>

            </div>

            </form>
        </div>
        </div>
              
              <div className="popup-box-actions medium void">
                    <button className="button medium tertiary" onClick={handleClose}>Cancelar</button>
                    &nbsp;
                    <button className="button medium secondary" onClick={handleConfirm}>Confirmar</button>
              </div>
            </div>
    </Modal>
}

export default FormUserModal;