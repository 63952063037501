import { useMemo } from 'react'
import Template from "../../template";
import PageLoader from '../../components/PageLoader';
import bannerIcon from '../../assets/img/banner/overview-icon.png';
import regulamentoMerchan from "../../assets/documents/2022.02.17 Gigantes da Execução Merchan.pdf";
import regulamentoVendas from "../../assets/documents/2022.02.17 - Gigantes da Execução Vendas.pdf";

import regulamentoMerchanOnda2Desktop from "../../assets/documents/onda2/2022. Onda 2 Gigantes da Execução Merchan 2504_desktop.pdf";
import regulamentoMerchanOnda2Mobile from "../../assets/documents/onda2/2022. Onda 2 Gigantes da Execução Merchan 2504_mobile.pdf";

import regulamentoVendasOnda2Desktop from "../../assets/documents/onda2/2022. Onda 2 - Gigantes da Execução Vendas 2504_desktop.pdf";
import regulamentoVendasOnda2Mobile from "../../assets/documents/onda2/2022. Onda 2 - Gigantes da Execução Vendas 2504_mobile.pdf";

import regulamentoMerchanOnda3Desktop from "../../assets/documents/onda3/2022. Onda 3 Gigantes da Execução Merchan 0106.pdf";
import regulamentoVendasOnda3Desktop from "../../assets/documents/onda3/2022. Onda 3 - Gigantes da Execução Vendas 0106.pdf";

import regulamentoMerchanOnda4Desktop from "../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Merchan 0109.pdf";
import regulamentoVendasOnda4Desktop from "../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Vendas 1609.pdf";

import regulamentoBoost from "../../assets/documents/RegulamentoBoostGigantes.pdf";


import { useAuth } from '../../contexts/AuthContext';
import * as S from './style';

function Regulamento () {
  const { user } = useAuth();

  const regulamentoOnda4Desktop = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda4Desktop;

    return regulamentoVendasOnda4Desktop;
  }, [user]);

  const regulamentoOnda3Desktop = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda3Desktop;

    return regulamentoVendasOnda3Desktop;
  }, [user]);

  const regulamentoOnda2Desktop = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda2Desktop;

    return regulamentoVendasOnda2Desktop;
  }, [user]);

  const regulamentoOnda2Mobile = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda2Mobile;

    return regulamentoVendasOnda2Mobile;
  }, [user]);

  const regulamentoCorreto = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchan;

    return regulamentoVendas;
  }, [user]);

  if (!user) return <PageLoader />

  return <Template>
    <div className="section-banner">
      <img className="section-banner-icon" src={bannerIcon} alt="overview-icon" />
  
      <p className="section-banner-title">POLÍTICAS DE PRIVACIDADE</p>
  
      <p className="section-banner-text"></p>
    </div>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
  
        <h2 className="section-title">&nbsp;</h2>
      </div>
    </div>

    <div className="widget-box">
      
  
      <p style={{fontSize: '24px', padding: '3% 15%', textAlign: 'center'}} className="widget-box-title">TERMOS DE USO</p>

      <h5>Regulamento Boost de vendas</h5>
      <div className="widget-box-content">

          <S.Iframe src={regulamentoBoost} title="Regulamento" />

      </div>

      <h5>Regulamento da ONDA 4</h5>
      <div className="widget-box-content">

          <S.Iframe src={regulamentoOnda4Desktop} title="Regulamento" />

      </div>

      <h5>Regulamento da ONDA 3</h5>
      <div className="widget-box-content">

          <S.Iframe src={regulamentoOnda3Desktop} title="Regulamento" />

      </div>
  
      <h5 style={{marginTop: '48px'}}>Regulamento da ONDA 2</h5>
      <div className="widget-box-content">

          <S.Iframe className='desk-visible' src={regulamentoOnda2Desktop} title="Regulamento" />
          <S.Iframe className='mobile-visible' src={regulamentoOnda2Mobile} title="Regulamento" />

      </div>

      <h5 style={{marginTop: '48px'}}>Regulamento da ONDA 1</h5>
      <div className="widget-box-content">

          <S.Iframe src={regulamentoCorreto} title="Regulamento" />

      </div>
    </div>
  </Template>
}

export default Regulamento;
