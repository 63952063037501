import styled from 'styled-components';

export const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TextAreaContainer = styled.div`
  position: relative;
`;

export const TextAreaLabel = styled.label`
  position: absolute;
  top: 0;
  left: 1rem;
  background-color: #fff;
  padding: .5rem;
  z-index: 2;
`;

export const TextArea = styled.textarea`
  position: relative;
  top: 1rem;
  left: 0rem;
  z-index: 1;
`;