import { useCallback } from 'react';
import * as S from './style';

function Checkbox (props) {
  const {label, value, onSelect, onDeselect, required, error } = props;
  const name = Math.random().toString(16).replace(/0\./, '');
  const handleChange = useCallback((evt) => {
    if (evt.target.checked) {
      onSelect(evt.target.value);
      return;
    }
    onDeselect(evt.target.value);
  }, [onSelect, onDeselect]);
  return <S.Label htmlFor={name} hasError={!!error}>
      <S.Input
          type="checkbox"
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
          required={required}
        />
      {label}

      {error && <S.Error>{error}</S.Error>}
    </S.Label>;
}

export default Checkbox;