import { useContext, useState, useCallback, createContext } from "react";
import Modal from "../../components/Modal";

const ModalContext = createContext();

export default function ModalProvider ({ children }) {
    const [text, setText] = useState();
    const [header, setHeader] = useState();
    const [visible, setVisible] = useState(false);
    const [size, setSize] = useState();
    const [onCancelModal, setOnCancelModal] = useState({action: null});
    const [onConfirmModal, setOnConfirmModal] = useState({action: null});
    const [cancelTitle, setCancelTitle] = useState('Não');
    const [confirmTitle, setConfirmTitle] = useState('Sim');
    
    const openModal = useCallback(({body, title, onConfirm, onCancel, size, confirmText, cancelText}) => {
      setVisible(true);
      setHeader(title);
      setText(body);

      setOnCancelModal({action: onCancel});
      setOnConfirmModal({action: onConfirm});
      setSize(size || "small");
      setConfirmTitle(confirmText || "Sim");
      setCancelTitle(cancelText || "Não");
    }, []);

    const handleClose = useCallback(() => {
        if(onCancelModal.action) onCancelModal.action();
        setVisible(false);
    }, [onCancelModal]);

    const handleConfirm = useCallback(() => {
        if(onConfirmModal) onConfirmModal.action();
        setVisible(false);
    }, [onConfirmModal]);

    return <ModalContext.Provider value={{openModal, setVisible}}>
          {children}
          <Modal showModal={visible} title={header} onClose={handleClose} size={size}>
            <div className="form">
                {text}
              
              {onConfirmModal.action && <>
              <div className="popup-box-actions medium void">
                    <button className="button medium tertiary" onClick={handleClose}>{cancelTitle}</button>
                    &nbsp;
                    <button className="button medium secondary" onClick={handleConfirm}>{confirmTitle}</button>
              </div>
              </>}
            </div>
          </Modal>
        </ModalContext.Provider>
    ;
};

export function useModal () {
    const context = useContext(ModalContext)

    if (!context) throw new Error("useNotify must be used within a NotifyProvider");

    const { openModal, setVisible } = context;

    return { openModal, setVisible };
}
