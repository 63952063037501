import styled from "styled-components";
import bg1 from '../../assets/img/landing/manutencao.png';
import bg2 from '../../assets/img/landing/logo-principal.png';
import bg3 from '../../assets/img/landing/dot-texture.png';


export const Content = styled.div`
  .error-section{
    background: url(${bg1}) no-repeat 0 0,
    url(${bg2}) right 40px top 40px no-repeat,
    url(${bg3}) repeat 0 0, #fff;
    background-size: contain,270px,auto;
  }
  .error-section .error-section-info{
    width: 420px;
    position: absolute;
    top: 270px;
    right: 140px;
    text-align: initial;
  }
  .error-section .error-section-title{
    color: #3e3f5e;
  }
  .error-section-subtitle span{
    display: none;
  }
  @media (max-width: 1365px){
    .error-section{
      position: fixed;
    }
    .error-section .error-section-title{
      display: none;
    }
    .error-section-subtitle span{
      display: initial;
    }
  }
  @media (max-width: 565px){
    .error-section{
      background: url(${bg2}) no-repeat 0 0,
      url(${bg3}) repeat 0 0, #fff;
      background-size: 270px, auto;
      background-position: center top;
    }
    .error-section .error-section-info{
      position: relative;
      top: 135px;
      right: initial;
      text-align: center;
    }
  }
  @media (max-width: 420px){
    .error-section .error-section-info{
      width: 90%;
    }
    .error-section .error-section-text{
      font-size: 18px;
    }
  }
`;
