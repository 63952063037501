import styled from 'styled-components';
import background from '../../assets/img/ranking-quiz/background.jpg';
import backgroundMobile from '../../assets/img/ranking-quiz/background-mobile.jpg';
import banner from '../../assets/img/ranking-quiz/topo-novo.jpg';
import rodape from '../../assets/img/ranking-quiz/rodape.png';

export const Container = styled.div`
  background-image: url(${background});
  width: 100vw;
  height: 100vh;
  background-size: cover;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: 107% auto;
`;

export const RankingImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`

export const Footer = styled.div`
  display: none;
  position: absolute;
  background-image: url(${rodape});
  background-repeat: no-repeat;
  background-position: right;
  left: 0;
  right: 0;
  bottom: 0;
  height: 94px;
  
  @media (max-width: 1366px) {
    
  }
`

export const PageData = styled.div`
  display: flex;
  margin-top: -160px;
  align-items: flex-start;
  
  @media (max-width: 1366px) {
    margin-top: 0;
    flex-direction: column;
  }
`;

export const TableArea = styled.table`
  width: 100%;

  th, td {
    font-size: .9rem;
    font-weight: bold;
    font-family: 'Titillium Web', cursive;
    background-color: rgba(255, 255, 255, .3);
  }

  th {
    text-align: center;
  }

  td {
    padding: 0 32px;
    text-transform: uppercase;
    color: #293e8f;
  }

  td:first-child, td:last-child {
    width: 10px;
    text-align: center;
    color: #000;
  }

  @media (max-width: 1137px) {
    thead img {
      max-height: 19px;
      height: auto;
      display: block;
    }
  }

  @media (max-width: 440px) {
    td:nth-child(2) {
      font-size: .5rem;
      padding: 0;
    }
  }
`;

export const Brindes = styled.img`
  width: 506px;
  margin-top: 150px;
  margin-left: 50px;
  @media (max-width: 1366px) {
    display: none;
  }
`;

export const TextoMobile = styled.img`
  margin: -150px auto 32px auto;
  @media (min-width: 1367px) {
    display: none;
  }

  @media (max-width: 1137px) {
    margin-top: -10%;
  }
`;

export const Logo = styled.img`
  max-width: 18%;
`;

export const Banner = styled.div`
  background-image: url(${banner});
  @media (max-width: 1366px) {
    height: 25%;
  }
`;

export const PageContent = styled.div`
  width: 60%;
  
  @media (max-width: 1366px) {
    margin: 0 auto;
    margin-bottom: 64px;
  }

  @media (max-width: 440px) {
    width: 90%;
  }
`;

export const ButtonContainer = styled.div`
  display: grid;
  margin-top: 32px;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  button {
    background-color: var(--primary);
    color: var(--white);
    padding: 16px;  
    user-select: none;
  }

  button.next {
    grid-column: 2;
  }
`;

export const Content = styled.div`
  img.responsive {
    width: 100%;
    display: block;
    height: auto;
  }

  img.desktop-visible {
    display: none;
  }
  img.mobile-visible {
    display: none;
  }

  .content-grid {
    padding: 80px 0 0 0;
    max-width: none;
    background-image: url(${background});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
    
    @media (max-width: 1366px) {
      height: 100%;
    }
    
    @media (max-width: 680px) {
      background-image: url(${backgroundMobile});
      background-size: contain;
      width: 100%;
      padding: 60px 0 0 0;
      margin: 0;      
    }
  }
`;
