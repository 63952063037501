import { useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useModal } from '../../contexts/ModalContext';
import api from '../../services/api';
import * as S from './style';

function MenuMobile({ catalogUrl, user }) {
  let location = useLocation();
  const { userCan } = useAuth();
  const { openModal } = useModal();

  const handleDownloadCetificate = useCallback(async (onda) => {
    try {
      const response = await api.get(`/certificate`, { params: { onda }, responseType: 'arraybuffer' })
      const blob = new Blob([response.data])
      console.log(blob);
      const anchor = document.createElement("a");
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = `certificado-onda${onda}.pdf`;
      document.body.appendChild(anchor);
      anchor.click();
      setTimeout(function() {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);  
      }, 0);
    } catch (err) {
      openModal({
        body: <div className='form-row'>Você não tem certificado para baixar!<br /><br /></div>, title: 'Sinto muito'})
    }
  }, [openModal])

  return <><ul className="menu">

    <li className={`menu-item ${location.pathname === '/home' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/home">
        <svg className="menu-item-link-icon icon-info">
          <use href="#svg-info"></use>
        </svg>
        Home
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/newsfeed' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/newsfeed">
        <svg className="menu-item-link-icon icon-newsfeed">
          <use href="#svg-newsfeed"></use>
        </svg>
        Canal de Notícias
      </Link>
    </li>

    {user.canal === 'STAFF' && <li className={`menu-item ${location.pathname === '/members' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/members">
        <svg className="menu-item-link-icon icon-group">
          <use href="#svg-group"></use>
        </svg>
        Membros
      </Link>
    </li>}

    {userCan('read:result') && <li className={`menu-item ${location.pathname === '/resultados' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/resultados">
        <svg class="menu-item-link-icon icon-overview">
          <use href="#svg-overview"></use>
        </svg>
        Resultados
      </Link>
    </li>}

    <li className={`menu-item ${location.pathname === '/ranking' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/ranking">
        <svg class="menu-item-link-icon icon-overview">
          <use href="#svg-overview"></use>
        </svg>
        Ranking
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/quiz' ? 'active' : ''}`}>
      <Link className="menu-item-link text-tooltip-tfr" to="/quiz" data-title="Medalhas">
        <svg className="menu-item-link-icon icon-badges">
          <use href="#svg-badges"></use>
        </svg>
        Jogada extra
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/ranking-quiz' ? 'active' : ''}`}>
      <Link className="menu-item-link text-tooltip-tfr" to="/ranking-quiz" data-title="Medalhas">
        <svg className="menu-item-link-icon icon-badges">
          <use href="#svg-badges"></use>
        </svg>
        Ranking do Quiz
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/quests' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/quests">
        <svg className="menu-item-link-icon icon-quests">
          <use href="#svg-quests"></use>
        </svg>
        KPI
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/mecanicas' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/mecanicas">
        <S.AwesomeMenuIconSmallMobile>
          <i className="fas fa-cog"></i>
        </S.AwesomeMenuIconSmallMobile>
        Mecânicas
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/mecanicas-boost' ? 'active' : ''}`}>
      <Link className="menu-item-link" to="/mecanicas-boost">
        <S.AwesomeMenuIconSmall>
          <i className="fas fa-cog"></i>
        </S.AwesomeMenuIconSmall>
        Mecânicas Boost
      </Link>
    </li>

    <li className={`menu-item ${location.pathname === '/marketplace' ? 'active' : ''}`}>
      <a className="menu-item-link" href={catalogUrl} target="_blank" rel="noreferrer">
        <svg className="menu-item-link-icon icon-marketplace">
          <use href="#svg-marketplace"></use>
        </svg>
        Troca de pontos
      </a>
    </li>
  </ul>

    <p className="navigation-widget-section-title">Mais opções</p>

    <Link className="navigation-widget-section-link" to="/faq">Perguntas frequentes</Link>
    <Link className="navigation-widget-section-link" to="/regulamento">Regulamento</Link>
    <Link class="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
    <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(1)}>Certificado da onda 1</S.DownloadButton>
    <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(2)}>Certificado da onda 2</S.DownloadButton>
    <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(3)}>Certificado da onda 3</S.DownloadButton>
    <Link className="navigation-widget-section-link" to="/perfil">Meu perfil</Link>
  </>
}

export default MenuMobile;
