import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../services/api";

const AuthContext = createContext();

export default function AuthProvider ({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [permissions, setPermisssions] = useState([]);
  const [refreshToken, setRefreshToken] = useState(null);
  const [catalogUrl, setCatalogUrl] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const history = useHistory();

  const userCan = useCallback((permission) => {
    console.log(permissions)
    if (!permissions) return false
    return permissions.includes(permission);
  }, [permissions])

  const updateBoostAccept = useCallback(async () => {
    const response = await api.post(`/accept/boost`)
    setUser({...user, acceptBoost: response.data.user.accept_boost})
    localStorage.setItem('user', JSON.stringify({...user, acceptBoost: response.data.user.accept_boost}));
  }, [user])

  const doLogin = useCallback(async (login, password) => {
    const response = await api.post('session', { login, password });
    const tokenData = response.data.token;
    const refreshTokenData = response.data.token;
    const catalogUrlData = response.data.catalogUrl;
    const userData = JSON.stringify(response.data.user);
    
    localStorage.setItem('token', tokenData);
    localStorage.setItem('refreshToken', refreshTokenData);
    localStorage.setItem('user', userData);
    localStorage.setItem('catalogUrl', catalogUrlData);
    
    setToken(tokenData);
    setRefreshToken(refreshTokenData);
    setUser(response.data.user);
    setCatalogUrl(response.data.catalogUrl)
    
    if (response.data.permissions) {
      const permissionsData = JSON.stringify(response.data.permissions);
      localStorage.setItem('permissions', permissionsData);
      setPermisssions(response.data.permissions || [])
    }

    setAvatar('https://gifted-archimedes-4a47f4.netlify.app/static/media/avatar.c2b96abe.png');
    if(response.data.user.avatar) {
      setAvatar(`${process.env.REACT_APP_IMG_URL}${response.data.user.avatar.path}`);
    }

    return response;
  }, []);

  const doLoginWithData = useCallback((response) => {
    const tokenData = response.data.token;
    const refreshTokenData = response.data.token;
    const catalogUrlData = response.data.catalogUrl;
    const userData = JSON.stringify(response.data.user);
    
    localStorage.setItem('token', tokenData);
    localStorage.setItem('refreshToken', refreshTokenData);
    localStorage.setItem('user', userData);
    localStorage.setItem('catalogUrl', catalogUrlData);
    
    setToken(tokenData);
    setRefreshToken(refreshTokenData);
    setUser(response.data.user);
    setCatalogUrl(response.data.catalogUrl)
    
    if (response.data.permissions) {
      const permissionsData = JSON.stringify(response.data.permissions);
      localStorage.setItem('permissions', permissionsData);
      setPermisssions(response.data.permissions || [])
    }

    setAvatar('https://gifted-archimedes-4a47f4.netlify.app/static/media/avatar.c2b96abe.png');
    if(response.data.user.avatar) {
      setAvatar(`${process.env.REACT_APP_IMG_URL}${response.data.user.avatar.path}`);
    }

    return response;
  }, []);

  useEffect (() => {
    const tokenData = localStorage.getItem('token');
    if (!tokenData) return;

    const userData = JSON.parse(localStorage.getItem('user'));
    const catalogUrlData = localStorage.getItem('catalogUrl');
    setToken(tokenData);
    setUser(userData);
    setCatalogUrl(catalogUrlData);
    if(userData.avatar) {
      setAvatar(`${process.env.REACT_APP_IMG_URL}${userData.avatar.path}`);
      return;
    }
    setAvatar('https://gifted-archimedes-4a47f4.netlify.app/static/media/avatar.c2b96abe.png');
  }, []);

  const doLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('catalogUrl');
    localStorage.removeItem('permissions');
    setToken(null);
    setUser(null);
    setCatalogUrl(null);
    setPermisssions(null);

    history.push("/");

  }, [history]);

  useEffect(() => {
    if (!token) return;
    console.log(`[Refresh token] Set call ${new Date().toLocaleTimeString()}`);
    const timeout = setTimeout(async () => {
      console.log(`[Refresh token] Calling ${new Date().toLocaleTimeString()}`);
      try {
        const response = await api.post(`/session/refresh`, null, {
          headers: {
            'Authorization': `Bearer ${refreshToken}`
          }
        });
        const { token: newToken, refreshToken: newRefreshToken } = response.data;
        localStorage.setItem('token', newToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        setToken(newToken);
        setRefreshToken(newRefreshToken);
      } catch (error) {
        toast.error("O token expirou", { theme: "colored" });
        doLogout();
        return;
      }
    }, 30 * 60 * 1000);

    return () => clearTimeout(timeout);
  }, [token, refreshToken, doLogout])

  return (
    <AuthContext.Provider value={{user, token, catalogUrl, avatar, setAvatar, setUser, doLogin, doLoginWithData, doLogout, userCan, updateBoostAccept}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth () {
    const context = useContext(AuthContext)

    if (!context) throw new Error("useAuth must be used within a AuthProvider");

    const { user, token, avatar, catalogUrl, setAvatar, setUser, doLogin, doLoginWithData, doLogout, userCan, updateBoostAccept } = context;

    return { user, token, avatar, catalogUrl, setAvatar, setUser, doLogin, doLoginWithData, doLogout, userCan, updateBoostAccept };
}
