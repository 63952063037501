import { useParams } from 'react-router-dom'
import PageLoader from "../../components/PageLoader";
import MecanicasLmtOndaAtiva from "../../components/MecanicasLmtOnda4";
import MecanicasGkaOndaAtiva from "../../components/MecanicasGkaOnda4";
import MecanicasCecOndaAtiva from "../../components/MecanicasCecOnda4";
import MecanicasAtacadoOndaAtiva from "../../components/MecanicasAtacadoOnda4";
import MecanicasFoodOndaAtiva from "../../components/MecanicasFoodOnda4";
import MecanicasHibOndaAtiva from "../../components/MecanicasHibOnda4";
import MecanicasTdOndaAtiva from "../../components/MecanicasTdOnda4";
import MecanicasVjOndaAtiva from "../../components/MecanicasVjOnda4";
import MecanicasMerchanOndaAtiva from "../../components/MecanicasMerchanOnda4";
import MecanicasSeletor from "../../components/MecanicasSeletor";
import { useAuth } from '../../contexts/AuthContext';

function Mecanicas (){
  const { mecanica } = useParams();
  const { user } = useAuth();
  if (!user) return <PageLoader />;

  const selected = mecanica

  if(selected === 'LMT') return <MecanicasLmtOndaAtiva />
  if(selected === 'GKA') return <MecanicasGkaOndaAtiva />
  if(selected === 'C&C') return <MecanicasCecOndaAtiva />
  if(selected === 'ATC') return <MecanicasAtacadoOndaAtiva />
  if(selected === 'FOOD+DIA+FARMA') return <MecanicasFoodOndaAtiva />
  if(selected === 'FDF') return <MecanicasFoodOndaAtiva />
  if(selected === 'DIST HIB') return <MecanicasHibOndaAtiva />
  if(selected === 'HIB') return <MecanicasHibOndaAtiva />
  if(selected === 'DIST TD') return <MecanicasTdOndaAtiva />
  if(selected === 'TD') return <MecanicasTdOndaAtiva />
  if(selected === 'VJ') return <MecanicasVjOndaAtiva />
  if(selected === 'MERCHAN') return <MecanicasMerchanOndaAtiva />
  if(selected === 'MERCHANDISING') return <MecanicasMerchanOndaAtiva />
  
  return <MecanicasSeletor />
}

export default Mecanicas;
