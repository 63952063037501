import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 8fr 2fr;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    button {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0.75rem;
    }
`

export const Table = styled.table`
  margin-top: 16px;
  width: 100%;
`