import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import AuthWithCode from '../domain/use-case/authWithCode'

function ExternalAuth ({location}) {
  const { doLoginWithData } = useAuth()
  const history = useHistory()
  const code = new URLSearchParams(location.search).get("code")

  const loadData = useCallback (async () => {
    const response = await AuthWithCode(code)
    doLoginWithData(response)
    history.push('/home')
  }, [code, doLoginWithData, history])

  useEffect (() => {
    loadData()
  }, [loadData])
  
  return <></>
}

export default ExternalAuth
