import axios from 'axios';
import useSWR from 'swr'
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { toast } from 'react-toastify';

export function useFetch(url) {
  const history = useHistory();
    const { data, error, mutate } = useSWR(url, async url => {
      try {
        const response = await api.get(url);

        return response.data;
      } catch (e) {
        if(axios.isAxiosError(e)) {
          if (e.response.data.message === 'Você precisa aceitar os termos de uso antes de continuar' ||
            e.response.data.message === "Token inválido" ||
            e.response.data.message === "O token de acesso não foi informado") {
            localStorage.clear();
            toast.error(e.response.data.message, { theme: "colored" })
            history.push('/')
          }
        }
      }
    }, { refreshInterval: 60000 })

    return { data, error, mutate }
}