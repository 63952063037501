import styled from 'styled-components';

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
  &.desk-visible {
    display: none;
  }

  &.mobile-visible {
    display: none;
  }

  @media (min-width: 1068px) {
    &.desk-visible {
      display: block;
    }
  }

  @media (max-width: 1068px) {
    &.mobile-visible {
      display: block;
    }
  }
`;