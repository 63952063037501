import { Fragment } from 'react';
import { useMenu } from '../contexts/DesktopMenuContext';
import * as S from './style';

// components
import HeaderComponent from '../components/Header'
import SidebarComponent from '../components/SideBar'

export default function Template ({ children }) {
    const { open } = useMenu();
    return (
        <Fragment>
            <SidebarComponent />
            <HeaderComponent />
            <S.ContentGrid className={`content-grid ${open ? 'large' : 'small'}`}>
                {children}
            </S.ContentGrid>
        </Fragment>
    )
}

export function TemplateMecanica ({ children }) {
  const { open } = useMenu();
  return (
      <Fragment>
          <SidebarComponent />
          <HeaderComponent />
          <S.ContentGridMecanica className={`${open ? 'large' : 'small'}`}>
              {children}
          </S.ContentGridMecanica>
      </Fragment>
  )
}