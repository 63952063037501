import { useEffect, useState, useCallback } from "react";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
import api from "../../services/api";
import Template from "../../template"
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import PageLoaderComponent from "../../components/PageLoader";
import InputText from '../../components/Forms/InputText';
import Hexagon from '../../components/Hexagon';
import DefaultAvatar from '../../assets/img/avatar.png';
import { MaskCEP, MaskCPF, MaskPhone } from "../../utils/mask";
import * as S from './style';

// Component
import BannerTitleComponent from "../../components/BannerTitle"

export default function EditUser ({ match }) {
  const [user, setUser] = useState({});
  const [address, setAddress] = useState({});
  const [avatar, setAvatar] = useState({});
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  });

  const { data } = useFetch(`/user/${match.params.userId}`);

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })

    try {
      await api.put(`/user/${user.id}`, {...user, address});
      toast.success('Os dados foram salvos', { theme: "colored" });
    } catch (e) {

    }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user, address]);

  useEffect(() => {
    if(!data) return;
    setUser(data);
    setAddress(data.address || {});
    if (data.avatar) {
      setAvatar(`${process.env.REACT_APP_IMG_URL}${data.avatar.path}`)
    } else {
      setAvatar(DefaultAvatar);
    }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [data])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Meu perfil"
        subTitle="Info de perfil, mensagens, configuração e muito mais!"
        imgAlt="accounthub-icon"
      />

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Meu perfil</p>

              <h2 className="section-title">Informação de perfil</h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="grid grid-3-3-3 centered">
              <div className="user-preview small fixed-height">
                <figure className="user-preview-cover liquid " style={{ "background": "url('avatar') center center / cover no-repeat" }}>
                  <img src={avatar} alt="cover-01" style={{ "display": "none" }} />
                </figure>

                <S.PosAvatar className="user-avatar-border">
                  <Hexagon size="large" src={avatar} />
                </S.PosAvatar>
              </div>
            </div>

            <div className="widget-box">
              <p className="widget-box-title">Sobre seu perfil</p>

              <div className="widget-box-content">

                <form className="form" action="" method="POST" onSubmit={handleUserSave}>
                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskCPF(value) })}  maxLength="14" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="canal" label="Canal" id="canal" value={user.canal} onChange={(value) => setUser({ ...user, canal: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cep" label="CEP" id="cep" value={address.cep} onChange={(value) => setAddress({ ...address, cep: MaskCEP(value) })} maxLength="9" />
                    </div>
                    <div className="form-item">
                      <InputText name="estado" label="Estado" id="estado" value={address.estado} onChange={(value) => setAddress({ ...address, estado: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cidade" label="Cidade" id="cidade" value={address.cidade} onChange={(value) => setAddress({ ...address, cidade: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="bairro" label="Bairro" id="bairro" value={address.bairro} onChange={(value) => setAddress({ ...address, bairro: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="logradouro" label="Rua" id="logradouro" value={address.logradouro} onChange={(value) => setAddress({ ...address, logradouro: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="numero" label="Numero" id="numero" value={address.numero} onChange={(value) => setAddress({ ...address, numero: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="complemento" label="Complemento" id="complemento" value={address.complemento} onChange={(value) => setAddress({ ...address, complemento: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="matricula" label="Matrícula" id="matricula" value={user.matricula} onChange={(value) => setUser({ ...user, matricula: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="perfil" label="Perfil" id="perfil" value={user.perfil} onChange={(value) => setUser({ ...user, perfil: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <S.SubmitButton type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}