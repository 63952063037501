import { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "../../contexts/ModalContext";
import * as S from "./style";
import trofeu from "../../assets/img/quiz/bg-trofeu.png";

const templateList = {
    trofeu
}

export default function CardQuiz ({children, template, quiz}) {
    const bg = templateList[template];
    const history = useHistory();
    const { openModal } = useModal();
    const handleOpenQuiz = useCallback((link) => {
        openModal({
            title: 'Atenção',
            body: <S.ModalQuiz className="form-row">
                <p>Quando o Quiz for iniciado não poderá ser interrompido.</p>
                <p>São 8 perguntas no total.</p>
                <p>O tempo de duração de cada pergunta é de 60s.</p>
                <p>Apenas 1 alternativa é  a correta.</p>
                <p>Só é permitido jogar o Quiz uma vez. </p>
                <p>Disponível de 21/11 a 01/12. </p>
                <p>Deseja iniciar agora?</p>
            </S.ModalQuiz>,
            onConfirm: () => history.push(link),
            onCancel: () => {},
        });
    }, [openModal, history]);
    const enableAt = useMemo(() => new Date(quiz.enableAt), [quiz])
    const disableAt = useMemo(() => new Date(quiz.disableAt), [quiz])
    const status = useMemo(() => {
        if (quiz.hasAnswer) return 'Respondido'
        if (process.env.REACT_APP_ENV === "development") return 'Disponivel';
        if(enableAt > new Date()) return 'Em breve';
        if(disableAt < new Date()) return 'Fechado';
        return 'Disponivel';
    }, [enableAt, disableAt, quiz]);
    const color = useMemo(() => {
        if (quiz.hasAnswer) return '#6c757d80'
        if (process.env.REACT_APP_ENV === "development") return 'var(--success)';
        if(enableAt > new Date()) return '#6c757d80';
        if(disableAt < new Date()) return '#6c757d80';
        return 'var(--success)';
    }, [enableAt, disableAt, quiz]);
    const buttonText = useMemo(() => {
      if (quiz.hasAnswer) return 'Você já respondeu esse quiz'
      if (process.env.REACT_APP_ENV === "development") return 'Iniciar Quiz';
      if(enableAt > new Date()) return `Disponível em ${enableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')}`;
      if(disableAt < new Date()) return 'Encerrado';
      return 'Iniciar Quiz';
    }, [quiz, disableAt, enableAt]);
    const disableButton = useMemo(() => {
        if (quiz.hasAnswer) return true;
        if (process.env.REACT_APP_ENV === "development") return false;
        if(enableAt > new Date()) return true;
        if(disableAt < new Date()) return true;
        return false;
    }, [enableAt, disableAt, quiz]);
    return (
        <S.Principal bg={bg}>
            <h3>{quiz.title}</h3>

            <S.Status color={color}>
                <p>{status}</p>
            </S.Status>

            <S.Score>
                {/* <div className="score">
                    <span>100</span>
                    <p>Realizados</p>
                </div> */}
                <div className="score">
                    <span>{quiz.points}</span>
                    <p>Pontos</p>
                    <S.Data>Disponível de {enableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')} a {disableAt.toLocaleDateString('pt-BR').replace(/\/\d{4}$/, '')}</S.Data>
                </div>
            </S.Score>
            <S.Link onClick={() => handleOpenQuiz(`/quiz/${quiz.id}`)} disabled={disableButton}>{buttonText}</S.Link>
        </S.Principal>
    )
}