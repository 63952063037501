import { useMemo } from "react";

export default function CheckoutItem(props) {
  console.log(props)
  const toFloat = (value) => {
    const val = parseFloat(value).toFixed(2);
    return val.replace(/\./, ',');
  }
  const preco = useMemo(() => toFloat(props.valorTotal), [props]);
  const precoUnitario = useMemo(() => toFloat(props.valorUnitario), [props]);
  const nome = useMemo(() => props.sku.produto.displayName, [props]);
  return <div className="totals-line">
    <div className="totals-line-info">
      <p className="totals-line-title"><span className="bold">{nome}</span></p>

      <p className="totals-line-text">{precoUnitario}</p>
    </div>

    <p className="price-title"><span className="currency"></span> {preco}</p>
  </div>
}