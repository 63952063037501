import { useCallback, useEffect } from "react";
import { useHistory } from 'react-router-dom'
import { TemplateMecanica } from "../../template";
import ImagemDesktop from '../../assets/img/mecanicas/boost.jpg'
import ImagemMobile from '../../assets/img/mecanicas/boost_mobile.jpg'
import Regulamento from '../../assets/documents/RegulamentoBoostGigantes.pdf'
import { useModal } from '../../contexts/ModalContext'
import { useAuth } from "../../contexts/AuthContext"; 
import * as S from './style'

function MecanicasBoost () {
  const { openModal } = useModal()
  const { updateBoostAccept, user } = useAuth()
  const history = useHistory()

  const handleClose = useCallback(() => {
    history.push('/')
  }, [history])

  useEffect(() => {
    if (!user) return
    if (user.acceptBoost) return
    openModal({
      title: 'Regulamento boost de vendas',
      size: 'mid',
      body: <div className="form-row">
        <iframe src={Regulamento} alt="" title="Regulamento" style={{ width: '100%', height: 'auto', aspectRatio: '1 / .60' }} />
      </div>,
      onConfirm: updateBoostAccept,
      onCancel: handleClose
  })
  }, [openModal, updateBoostAccept, user, handleClose])
  return <S.Content>
    <TemplateMecanica>
      <img className="responsive desktop-visible" src={ImagemDesktop} alt="Mecânica" />
      <img className="responsive mobile-visible" src={ImagemMobile} alt="Mecânica" />
    </TemplateMecanica>
  </S.Content>
}

export default MecanicasBoost;
