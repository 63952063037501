import * as S from './style'
function CardWidget ({children, status, message}) {
  return <div className="widget-box no-padding">
    <div className="widget-box-status">
      <div className="widget-box-status-content">
        {status}
        <S.MessageContainer className="widget-box-status-text">{message}</S.MessageContainer>
      </div>
      <div className="widget-box-status-content"><div className="content-actions">{children}</div></div>
    </div>
  </div>
}

export default CardWidget;