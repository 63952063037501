import { useCallback } from 'react';

/**
 * Add ClickOutside event to element
 * 
 * @param {callback} onClickOutside - Callback to perform when click outside
 * @returns [wrappedRef] - Add this to ref on element
 */
export function useUpdateData () {
  const period = useCallback((channel) => {
    switch (channel) {
      case 'LMT':
      case 'GKA':
      case 'MERCHANDISING':
        return <p style={{ fontWeight: 'bold', fontSize: '.75rem'}}>Dados P12W5</p>;
      case 'FOOD+DIA+FARMA':
      case 'FDF':
      case 'CC':
      case 'C&C':
      case 'ATC':
      case 'HIB':
      case 'TD':
      case 'VJ':
        return <p style={{ fontWeight: 'bold', fontSize: '.75rem'}}>Dados Fechamento P12</p>;
      default:
        return <p style={{fontWeight: 'bold', fontSize: '.75rem'}}>Dados parciais</p>;
    }
  }, [])

  return period
}