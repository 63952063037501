import styled from "styled-components";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return props.typeText ? "var(--dark)" : "#eeeeee";
};

export const DropZone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 30px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const DropZoneMessage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: ${(props) => getColor(props)};
  outline: none;
  transition: color 0.15s ease-in-out;
`;

export const ProgressContainer = styled.div`
  border: 1px solid var(--dark);
  width: 100%;
  height: 30px;
  border-radius: 8px;
  margin-top: 16px;
  p {
    font-weight: 700;
    position: absolute;
    bottom: 4px;
    font-size: 20px;
  }
  p.error {
    color: var(--red);
  }

  p.completed {
    color: #00e676;
  }
`;

export const ProgressBar = styled.div`
  width: calc(${(props) => (props.progressValue) || 0}% + 2px);
  position: relative;
  bottom: 1px;
  right: 1px;
  height: inherit;
  border-radius: inherit;
  background-color: var(--primary);
  transition: width 0.4s ease;
  &.completed {
    background-color: #00e676;
  }
  &.error {
    border: 1px solid var(--red);
    background-color: var(--red);
  }
`;
