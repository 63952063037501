import React, { useCallback, useEffect, useState } from "react";
import { isAxiosError } from 'axios'
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from '../../contexts/ModalContext'
import * as S from './style';
import Template from "../../template"
import BannerTitleComponent from "../../components/BannerTitle"
import PaginationComponent from "../../components/Pagination"
import InputTextWithButton from "../../components/Forms/InputTextWithButton"
import LinkButton from "../../components/Forms/LinkButton"
import BannerTitle from "../../assets/img/banner/accounthub-icon.png"
import api from "../../services/api";
import PageLoaderComponent from "../../components/PageLoader";

const translations = {
  "Invalid param: User birthday not exists": 'A data de nascimento não foi preenchida',
  "Invalid param: User document is not exists": 'O CPF não foi preenchido',
  "Invalid param: User document is invalid": 'O CPF é inválido',
  "Invalid param: User email is not exists": 'O email não foi preenchido',
  "Invalid param: User email is invalid": 'O email é inválido',
}

const translate = (text) => {
  if (translations[text]) return translations[text]
  return text
}

export default function Usuarios () {
    const { doLogout } = useAuth();
    const { openModal } = useModal();
    const [users, setUsers] = useState()
    const [filter, setFilter] = useState("")
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const loadUsers = useCallback(async (page) => {
      try {
        const response = await api.get(`/user?page=${page}&filter=${filter}`)
        setUsers (response.data.users)
        setTotalPages(response.data.totalPages)
        setCurrentPage(response.data.currentPage)
      } catch (err) {
        if (!err.response.data.message) {
          toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: "colored" });
          return;
        }
  
        if (err.response.data.message === 'Token inválido') {
          doLogout();
          toast.error(err.response.data.message, { theme: "colored" });
          return;
        }
  
        toast.error(err.response.data.message, { theme: "colored" });
      }
    },[doLogout, filter])

    const formatDate = useCallback((dt) => {
      if (!dt) return "Não aceitou"
      const date = new Date(dt)
      return date.toLocaleString()
    }, [])

    const confirmExclude = useCallback(async (userId) => {
      try {
        await api.delete(`/user/${userId}`)
        const userIndex = users.findIndex(user => user.id === userId);
        setUsers ((users) => {
          users.splice(userIndex, 1)
          console.log(users);
          return [...users]
        })
        toast.success('O usuário foi excluido com sucesso', { theme: "colored" });
      } catch (err) {
        if (!err.response.data.message) {
          toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: "colored" });
          return;
        }
  
        if (err.response.data.message === 'Token inválido') {
          doLogout();
          toast.error(err.response.data.message, { theme: "colored" });
          return;
        }
  
        toast.error(err.response.data.message, { theme: "colored" });
      }
    }, [users, doLogout])

    const handleExclude = useCallback((userId) => {
      const user = users.find(user => user.id === userId)
      openModal({
        title: "ATENÇÃO",
        body: <div className="form-row">Essa ação é irreversivel.<br />Você quer mesmo excluir o usuário "{user.name}"?</div>,
        onConfirm: () => confirmExclude(userId)
      })
    }, [users, openModal, confirmExclude])

    const handleFilter = useCallback(async (newFilter) => {
      setFilter(newFilter || "")
      loadUsers(1)
    }, [loadUsers]);

    const handleResetPass = useCallback(async (userId) => {
      await api.patch(`/user/${userId}/clear-password`)
      
      openModal({
        title: "ATENÇÃO",
        body: <div className="form-row">A senha foi resetada com sucesso!</div>
      })
    }, [openModal])

    const handleRegisterAtPremmiar = useCallback(async (userId) => {
      try {
        const response = await api.post('/reward-service', { userId })
        console.log(response)
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.response.status === 424) {
            const errorsList = err.response.data.errors.map(e => e.message)
            openModal({
              title: "ATENÇÃO",
              body: <div style={{ paddingBottom: '20px' }} className="form-row">{errorsList.join('\r\n')}</div>
            })
            return;
          }

          if (err.response.status === 400) {
            openModal({
              title: "ATENÇÃO",
              body: <div style={{ paddingBottom: '20px' }} className="form-row">{translate(err.response.data)}</div>
            })
            return;
          }

          toast.error('Ocorreu um erro interno', { theme: 'colored' })
        }
      }
    }, [openModal])

    const handleUpdateAtPremmiar = useCallback(async (userId) => {
      try {
        const response = await api.put('/reward-service', { userId })
        console.log(response)
      } catch (err) {
        if (isAxiosError(err)) {
          if (err.response.status === 424) {
            const errorsList = err.response.data.errors.map(e => e.message)
            openModal({
              title: "ATENÇÃO",
              body: <div style={{ paddingBottom: '20px' }} className="form-row">{errorsList.join('\r\n')}</div>
            })
            return;
          }

          if (err.response.status === 400) {
            openModal({
              title: "ATENÇÃO",
              body: <div style={{ paddingBottom: '20px' }} className="form-row">{translate(err.response.data)}</div>
            })
            return;
          }

          toast.error('Ocorreu um erro interno', { theme: 'colored' })
        }
      }
    }, [openModal])

    const handleExportAccept = useCallback(async (wave) => {
      const response = await api.get(`/accept-log/`, { responseType: 'arraybuffer', params: { wave } })
      const blob = new Blob([response.data])
      console.log(blob)
      const anchor = document.createElement("a")
      const url = URL.createObjectURL(blob)
      anchor.href = url
      anchor.download = `log-aceite-${wave}.xlsx`
      document.body.appendChild(anchor)
      anchor.click()
      setTimeout(function() {
          document.body.removeChild(anchor)
          window.URL.revokeObjectURL(url)  
      }, 0)
    }, [])

    useEffect(() => {
      loadUsers(1)
    },[loadUsers])

    if (!users) return <PageLoaderComponent />

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Usuários"
                subTitle="Veja todos os usuários cadastrados"
                imgAlt="newsfeed-icon"
            />

            <S.Tabela>
              <S.Wrapper className="grid mobile-prefer-content">
                <S.HeaderWrapper>
                  <button onClick={() => handleExportAccept("4")} className="btn btn-primary">Exportar aceites de onda 4</button>
                  <button onClick={() => handleExportAccept("boost")} className="btn btn-primary">Exportar aceites de boost</button>
                </S.HeaderWrapper>
                <S.HeaderWrapper>
                  <InputTextWithButton label="Matricula ou Email" onClick={(value) => handleFilter(value)} />

                  <LinkButton to="/admin/usuarios/novo" >Adicionar novo</LinkButton>
                </S.HeaderWrapper>
                <div className="grid-column">
                  <table>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>CPF</th>
                      <th>Matrícula</th>
                      <th>Termos Onda 3</th>
                      <th>Comunicação</th>
                      <th>&nbsp;</th>
                    </tr>
                    {users.map(user => <tr>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.document}</td>
                      <td>{user.matricula}</td>
                      <td>{formatDate(user.acceptedWave3At)}</td>
                      <td>{formatDate(user.acceptInfo)}</td>
                      <S.ActionContainer>
                        <S.Premmiar type="button" onClick={() => handleResetPass(user.id)}>
                          <i class="fas fa-key"></i>
                        </S.Premmiar>
                        <S.Premmiar type="button" onClick={() => handleRegisterAtPremmiar(user.id)}>
                          <i class="fas fa-award"></i>
                        </S.Premmiar>
                        <S.Premmiar type="button" onClick={() => handleUpdateAtPremmiar(user.id)}>
                          <i class="fas fa-award"></i>
                        </S.Premmiar>
                        <S.Edit to={`/admin/usuarios/${user.id}`}>
                          <i class="fas fa-edit"></i>
                        </S.Edit>
                        <S.Exclude type="button" onClick={() => handleExclude(user.id)}>
                          <i class="fas fa-trash-alt"></i>
                        </S.Exclude>
                      </S.ActionContainer>
                    </tr>)}
                  </table>
                </div>
              </S.Wrapper>
            </S.Tabela>

            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onChagePage={(page)=> loadUsers(page)} />

        </Template >
    )
}