import { useCallback } from 'react';
import * as S from './style'

export default function InputSelect({ name, id, label, value, onChange, children, large }) {
  const handleChange = useCallback((evt) => {
    if(onChange) onChange(evt.target.value)
  }, [onChange]);

  return <S.FormSelect>
    <label for={id}>{label}</label>
    <select id={id} name={name} onChange={handleChange}>
      {children}
    </select>
    <S.FormSelectIcon>
      <use href="#svg-small-arrow"></use>
    </S.FormSelectIcon>
  </S.FormSelect>
}