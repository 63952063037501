import styled from "styled-components";

export const Table = styled.table`
  margin-top: 16px;
  width: 100%;
  font-size: 12.8px;
  color: #3e3f5e;
  font-family: Rajdhani, sans-serif;
  line-height: 1em;

  thead > tr > th {
    border-bottom: 1px solid #eaeaf5;
  }

  th:first-child, td:first-child {
    padding-left: 0;
    width: auto;
  }

  th:last-child, td:last-child {
    padding-right: 0;
    width: 300px;
    text-align: right;
  }

  tbody > tr:first-child > td {
    padding-top: 32px;
  }

  th, td {
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
    padding: 16px 16px;
  }
`;
