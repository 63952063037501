import { useEffect, useCallback, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { toast } from 'react-toastify'
import axios from 'axios'
import Template from "../../template"
import cover from '../../assets/img/banner/banner-profile-stats.jpg'
import { useAuth } from "../../contexts/AuthContext"
import HexagonComponent from "../../components/Hexagon"
import PageLoaderComponent from "../../components/PageLoader"
import rank from '../../assets/img/rank/position.png'
import api from '../../services/api'
import versus from '../../assets/img/achievement/banner/vsicon.png'
import trofeu from '../../assets/img/achievement/banner/premio-icon.png'
import bannerBrackground from '../../assets/img/banner/bannerhome.png'
import star from '../../assets/img/banner/star.png'
import BannerFestasDesktop from '../../assets/img/banner/banner_resgate_carrossel.jpg'
import BannerFestastMobile from '../../assets/img/banner/banner_resgate_mobile.jpg'
import Banner2023Desktop from '../../assets/img/banner/banner-2003-desktop.jpg'
import Banner2023tMobile from '../../assets/img/banner/banner-2003-mobile.jpg'
import { useUpdateData } from '../../hooks/useUpdateData'
import { useModal } from '../../contexts/ModalContext'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as S from './style'

export default function Perfil (props) {
  const { user, avatar, catalogUrl, doLogout } = useAuth();
  const [dashboardData, setDashboardData] = useState({});
  const { openModal } = useModal()
  
  const loadDashboard = useCallback(async () => {
    try {
      const response = await api.get(`/dashboard`);
      setDashboardData(response.data.my_position || {});
      console.log(response.data.my_position);
    } catch (err) {
      if(axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: "colored" });
          doLogout();
          return;
        }
      }
    }
  }, [doLogout]);

  const periodoAtt = useUpdateData();

  useEffect(() => {
    loadDashboard();
    openModal({
      title: 'Ei Gigante, vai perder os prêmios?',
      body: <p style={{margin: '16px'}}>Seus pontos da campanha 2022 expiram em <strong>31/05.</strong><br />Resgate já!</p>
    })
  }, [loadDashboard, openModal])

  if(!user || !dashboardData) return <PageLoaderComponent />;

  return (
    <>
    <Template>

      <S.SliderHome>
        <Carousel showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={5000}>
            <div>
                <img src={bannerBrackground} alt='BannerHome'/>
            </div>
            <a href={catalogUrl} target="blank">
              <div>
                <S.DesktopImage src="https://img.casadebraganca.com/banners/BannerHomeDesktop.jpg" alt='BannerHome' />
                <S.MobileImage src="https://img.casadebraganca.com/banners/BannerHomeMobile.jpg" alt='BannerHome' />
              </div>
            </a>
            <div>
              <S.DesktopImage src={BannerFestasDesktop} alt='BannerHome' />
              <S.MobileImage src={BannerFestastMobile} alt='BannerHome' />
            </div>
            <a href="http://gigantedaexecucao.com.br/" target="blank">
              <div>
                <S.DesktopImage src={Banner2023Desktop} alt='BannerHome' />
                <S.MobileImage src={Banner2023tMobile} alt='BannerHome' />
              </div>
            </a>
        </Carousel>
      </S.SliderHome>

      <div className="grid medium-space">
        <S.Grid>
          <div className="user-preview small animate-slide-down">
            <S.Cover className="user-preview-cover" style={{backgroundImage: `url(${cover})`}}>
              <h4>Bem vindo de volta!</h4>
              <small>{user.name}</small>
            </S.Cover>
            <div className="user-preview-info">
              <div className="user-short-description small">
                <span className="user-avatar user-short-description-avatar">
                  <HexagonComponent src={avatar} size="extra-large"/>
                </span>
              </div>
              
              <S.UserStats>

                <S.Stat className="user-stat">
                  <S.Rank>
                    <img src={rank} alt="posição no rank" />
                    <div><small>POSIÇÃO</small>{dashboardData.rank}</div>
                  </S.Rank>
                </S.Stat>

                <S.Stat className="user-stat vertical">
                  <img className="star" src={star} alt="estralas" />
                  <S.ExpLine>{dashboardData.estrelas}</S.ExpLine>
                  <p className="user-stat-title">Estrelas</p>
                  <S.UserStatText>conquistadas</S.UserStatText>
                  <small>{periodoAtt(user?.canal)}</small>
                </S.Stat>
              </S.UserStats>

            </div>
            <S.Solo>
              <small className='results'>Resultados parciais da Onda 4</small>
            </S.Solo>
          </div>

          <S.Widget className="widget-box">
            <S.VimeoArea>
              <S.VimeoEmbed title="vimeo-player" src="https://player.vimeo.com/video/691544711?h=1ed27da7b2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allowfullscreen />
            </S.VimeoArea>
          </S.Widget>

          <S.GridArea>
            <S.Banner to="">
              <S.IconContainer>
                <S.Icon alt='Troféu' src={trofeu} />
              </S.IconContainer>

              <S.BannerTitle>
                Acesse o catálogo de prêmios
              </S.BannerTitle>

              <S.BannerLink href={catalogUrl}></S.BannerLink>
            </S.Banner>

            <S.Banner to="">
              <S.IconContainer>
                <S.Icon alt='Vs' src={versus} />
              </S.IconContainer>

              <S.BannerTitle>
                Faça sua Jogada extra
              </S.BannerTitle>

              <S.BannerButton to="/quiz"></S.BannerButton>
            </S.Banner>
          </S.GridArea>
        </S.Grid>
      </div>

    </Template >
  </>
  )
}