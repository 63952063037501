import React from "react";

export default function BannerTitle (props) {
    return (
        <div className="section-banner">
            <img className="section-banner-icon" src={props.imgUrl} alt={props.imgAlt} />

            <p className="section-banner-title">{props.title}</p>

            <p className="section-banner-text">{props.subTitle}</p>
        </div>
    )
}