import styled from 'styled-components';

export const QuestTitle = styled.p`
  text-align: center;
`;

export const QuestDescription = styled.p`
  text-align: center;
`;

export const QuestImage = styled.img`
  max-width: 100%;
  width: 100px;
  height: auto;
  display: block;
  margin: 24px auto 0 auto;
`;

export const CenterReward = styled.div`
    width: 100%;
    position: absolute;
    top: 10px;
    right: -6px;
    display: flex;
    justify-content: center;
    align-items: center;

    .text-sticker.small-text {
      position: static;
    }
`;
