import { useMemo } from 'react';
import { useAuth } from "../../contexts/AuthContext";
import MecanicasPascoaPadrao from '../../components/MecanicasPascoa/Padrao'
import MecanicasPascoaMerchan from '../../components/MecanicasPascoa/Merchan'

function MecanicasLmt () {
  const { user } = useAuth();
  const isMerchan = useMemo(() => {
    if (!user) return false;
    if (user.equipe?.toLowerCase() === 'merchan') return true;
    return false;
  }, [user])
  return isMerchan ? <MecanicasPascoaMerchan /> : <MecanicasPascoaPadrao />
}

export default MecanicasLmt;
