import { useCallback, useState, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useModal } from "../../contexts/ModalContext";
import api from "../../services/api";
import InputButton from "../InputButton";
import * as S from "./style";

export default function Dropzone({ apiEndpoint }) {
  const initialMessage =
    "Arraste sua planilha para cá, ou clique para selecionar";
  const errorMessage = "Oops, não podemos fazer upload desse arquivo";
  const acceptMessage = "Planilha pronta para o envio";
  const actionMessage = "Solte seu arquivo aqui";

  const { openModal } = useModal();
  const [dropMessage, setDropMessage] = useState(initialMessage);
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [willAccept, setWillAccept] = useState(false);
  const [willReject, setWillReject] = useState(false);
  const [status, setStatus] = useState("awaiting");
  const [progress, setProgress] = useState();
  const [apiError, setApiError] = useState("");

  const errorMessages = useMemo(() => ({
    "file-invalid-type": (
      <>
        É permitido apenas o uso de planilhas no formato Excel (extensão .xlsx).{" "}
        <br /> Por favor, verifique seu arquivo e tente novamente.
      </>
    ),
    "too-many-files": "É permitido apenas uma única planilha por vez",
    default: "Ocorreu um erro ao fazer o upload da planilha",
  }), []);

  // Callbacks dropzone
  const onDrop = useCallback(
    (newAcceptedFiles) => {
      setAcceptedFiles([...newAcceptedFiles, ...acceptedFiles]);
      setDropMessage(acceptMessage);
    },
    [acceptedFiles]
  );

  const onDragLeave = useCallback(() => {
    setWillAccept(false);
    setWillReject(false);
    setDropMessage(initialMessage);
  }, []);

  const onDragEnter = useCallback(
    (isAccepted, isReject) => {
      if (isAccepted) {
        setWillAccept(true);
      } else if (isReject) {
        setWillReject(true);
      }
      setDropMessage(actionMessage);
    },
    []
  );
  const onDropRejected = useCallback((files) => {
    setDropMessage(errorMessage);
    const [file] = files;
    const { errors } = file;
    const [error] = errors;
    openModal({
      title: "ATENÇÃO",
      body: (
        <div className="form-row">{errorMessages[error.code || "default"]}</div>
      ),
      onCancel: () => {
        setDropMessage(initialMessage);
        setWillReject(false);
        setWillAccept(false);
      },
    });
  }, [openModal, errorMessage, errorMessages]);

  const removeFile = () => {
    setAcceptedFiles([]);
    setDropMessage(initialMessage);
    setWillAccept(false);
    setWillReject(false);
  };

  // Call api to import datasheet
  const sendFile = async () => {
    let formData = new FormData();
    const [file] = acceptedFiles;
    formData.append("resultados", file);
    setStatus("sending");
    try {
      await api.post(apiEndpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (e) => {
          let progress = Math.round((e.loaded * 100) / e.total);
          setProgress(progress);
        },
      });
      setStatus("completed");
    } catch (error) {
      setStatus("error");
      setApiError(error.toString());
    }
  };

  // Instantiate dropzone with configs
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: {
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
      disabled: !!acceptedFiles.length,
      maxFiles: 1,
      onDrop,
      onDropRejected,
      onDragLeave,
      onDragEnter: () => onDragEnter(isDragAccept, isDragReject),
    });

  // status handler
  const Status = useCallback(() => {
    switch (status) {
      case "awaiting":
        return [];
      case "sending":
        return (
          <S.ProgressContainer>
            <p>Enviando {progress}%</p>
            <S.ProgressBar progressValue={progress} />
          </S.ProgressContainer>
        );

      case "completed":
        toast.success("Planilha enviada com sucesso!", {
          theme: "colored",
          toastId: "success",
        });
        return (
          <S.ProgressContainer>
            <p className="completed">Concluído</p>
            <S.ProgressBar className="completed" progressValue={progress} />
          </S.ProgressContainer>
        );

      case "error":
        toast.error(
          `Servidor relatou erro ao processar o arquivo.\n${apiError}`,
          { theme: "colored", toastId: "error" }
        );
        return (
          <S.ProgressContainer>
            <p className="error">Erro</p>
            <S.ProgressBar className="error" progressValue={progress} />
          </S.ProgressContainer>
        );

      default:
        break;
    }
  }, [status, apiError, progress]);

  return (
    <>
      <S.DropZone
        {...getRootProps({
          className: "dropzone",
          isFocused,
          isDragAccept: willAccept,
          isDragReject: willReject,
        })}
      >
        <input {...getInputProps()} />
        <svg style={{ width: "40px", height: "40px" }}>
          <use href="#svg-upload-xlsx" />
        </svg>
        <S.DropZoneMessage
          {...{
            isFocused,
            typeText: true,
            isDragAccept: willAccept,
            isDragReject: willReject,
          }}
        >
          {dropMessage}
        </S.DropZoneMessage>
        {acceptedFiles.length === 1 && <p>{acceptedFiles[0].name}</p>}
      </S.DropZone>

      {acceptedFiles.length === 1 && (
        <>
          <InputButton onClick={sendFile}>Enviar Planilha</InputButton>
          <InputButton onClick={removeFile}>Cancelar</InputButton>
        </>
      )}
      <Status />
    </>
  );
}
