import styled, { keyframes } from 'styled-components'
import { generateMedia } from "styled-media-query";

//imagens
import Stadium from "../../assets/img/landing/stadium.jpg"

const customMedia = generateMedia({
    desktop: "1200px",
    notebook: "991px",
    tablet: "768px",
    mobile: "576px",
  });

const fadeEaseInOut = keyframes`
 0% {
    transform: translate(50%);
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    position: absolute;
    left: 0px;
 }
 100% { 
    opacity: 1, 
    transform: translateX(0px);
    visibility: visible;
    z-index: 1;
    position: absolute;
    left: 0px;
 }
`

export const ContainerLogin = styled.div`
   @media(min-width: 1365px) {
      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const ContainerRegister = styled.div`
   @media(min-width: 1365px) {
      animation-name: ${fadeEaseInOut};
      animation-duration: 0.6s;
      animation-timing-function: ease-in-out;
   }
`

export const Principal = styled.div`
      input{
         :focus{
            border-color: #36194c
         }
      }
      .landing {
        background-image: url(${Stadium});
      }
      .logo{
         
      }
      .button.primary{
         background-color: #4e246e;
         :hover{
            background-color: #36194c;
         }
      }
      .button.secondary{
         background-color: #4e246e;
         :hover{
            background-color: #36194c;
         }
      }
      .form-text{
         a{
            color: #36194c;
            font-weight: 600;
         }
      }
   ${customMedia.lessThan("tablet")`
         .logo{
            img{
               width: 100%;
               max-width: 300px;
               height: auto;
            }
         }
         .popup-box{
            position: absolute;
            top: 0;
         }
        `}

  @media (max-width: 1366px) {
    .landing {
      padding-top: 20px !important;
    }

    .landing-form {
      margin-top: 20px !important;
    }
  }
`