import { useCallback, useState } from 'react';
import api from '../../services/api';
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import Checkbox from '../../components/Forms/Checkbox';
import BannerTitleComponent from "../../components/BannerTitle";
import Template from "../../template";
import * as S from './style';

import { useModal } from '../../contexts/ModalContext';

function AdminNotice () {
  const token = localStorage.getItem('token');
  const [selectedCanais, setSelectedCanais] = useState([]);
  const allCanais = [
    'VJ',
    'C&C',
    'MERCHANDISING',
    'GKA',
    'LMT',
    'FOOD+DIA+FARMA',
    'STAFF',
    'SAC',
    'XX',
    'ATC',
    'PRD',
    'TD',
    'COM',
    'HIB',
  ];
  const [message, setMessage] = useState("");
  const [saving, setSaving] = useState(false);
  const { openModal } = useModal();

  const addCanalToSelected = useCallback((roleId) => {
  setSelectedCanais([...selectedCanais, roleId]);
  }, [selectedCanais]);

  const removeCanalToSelected = useCallback((roleId) => {
    const selected = selectedCanais.filter(r => r !== roleId);
    setSelectedCanais(selected);
  }, [selectedCanais]);

  const handleSend = useCallback(async (evt) => {
    evt.preventDefault();
    setSaving(true);
    const data = {
      canal: selectedCanais,
      message,
    }
    
    await api.post('notice', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    openModal({body: <div className='form-row'>A mensagem "{message}" foi enviada!</div>, title: 'Mensagem enviada'});
    setMessage('');
    setSaving(false);
  }, [token, selectedCanais, message, openModal]);

  return <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Account Hub"
        subTitle="Info de perfil, mensagens, configuração e muito mais!"
        imgAlt="accounthub-icon"
      />
     <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Notificações</p>

              <h2 className="section-title">Envie mensagens para seus usuários</h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Cargos</p>

              <div className="widget-box-content">
                <form className="form" action="" method="POST" onSubmit={handleSend}>
                  <S.RoleGrid>
                    {allCanais && allCanais.map(canal =>
                      <Checkbox value={canal} label={canal} onSelect={addCanalToSelected} onDeselect={removeCanalToSelected} />
                    )}
                  </S.RoleGrid>
                  <div className="form-row split">
                    <div className="form-item">
                      <S.TextAreaContainer>
                        <S.TextAreaLabel htmlFor="message">Mensagem</S.TextAreaLabel>
                        <S.TextArea name="message" id="message" rows="10" onChange={(evt) => setMessage(evt.target.value)} value={message || ""} />
                      </S.TextAreaContainer>
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <button
                        type="submit"
                        className="button primary add-field-button"
                        style={{ 'margin-top': '0', float: 'right' }}
                        disabled={saving}
                      >{saving ? 'Salvando...' : 'Salvar'}</button>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
  </Template>
}

export default AdminNotice;