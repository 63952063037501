import { TemplateMecanica } from "../../template";
import * as S from './style';

import ImagemDesktop from '../../assets/img/mecanicas/mecanica_hib_onda4.png'
import ImagemMobile from '../../assets/img/mecanicas/mecanica_hib_onda4_mobile.png'

function MecanicasHibOnda4 () {
  return <S.Content>
    <TemplateMecanica>
      <img className="responsive desktop-visible" src={ImagemDesktop} alt="Mecânica" />
      <img className="responsive mobile-visible" src={ImagemMobile} alt="Mecânica" />
    </TemplateMecanica>
  </S.Content>
}

export default MecanicasHibOnda4;