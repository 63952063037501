import React, { useCallback, useState } from "react";
import { Link } from 'react-router-dom'

// Template
import Template from "../../template"

// Component
import BannerTitleComponent from "../../components/BannerTitle"
import PageLoaderComponent from "../../components/PageLoader"
import Filter from "../../components/Filter"

// Image
import BannerTitle from "../../assets/img/banner/marketplace-icon.png"

// Hooks
import { useFetch } from "../../hooks/useFetch"

import { Preview, Figure } from './styled';

export default function Marketplace () {
    //request API
    const { data } = useFetch("products")
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('')

    const longDesc = useCallback((item) => {
        return item.descricaoLonga.length < 70 ? `${item.descricaoLonga.replace(/&lt;/g, '<').replace(/&gt;/g, '<')}` : `${item.descricaoLonga.replace(/&lt;/g, '<').replace(/&gt;/g, '<').substring(0, 80)}...`
    }, []);

    if (!data) {
        return <PageLoaderComponent />
    }

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Marketplace"
                subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
                imgAlt="marketplace-icon"
            />

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Pesquise o que você quiser!</p>

                    <h2 className="section-title">Categorias</h2>
                </div>
            </div>

            <div className="grid grid-3-3-3-3 centered">
                <Link className="product-category-box category-all" to="/marketplace-category">
                    <p className="product-category-box-title">Todos os itens</p>

                    <p className="product-category-box-text">Navegue por todos os itens</p>

                    {/* <p className="product-category-box-tag">1360 itens</p> */}
                </Link>

                <Link className="product-category-box category-featured" to="/marketplace-category">
                    <p className="product-category-box-title">Casa e Cozinha</p>

                    <p className="product-category-box-text">A melhor seleção</p>

                    {/* <p className="product-category-box-tag">254 itens</p> */}
                </Link>

                <Link className="product-category-box category-digital" to="/marketplace-category">
                    <p className="product-category-box-title">Produtos Digital</p>

                    <p className="product-category-box-text">Vouchers, banners...</p>

                    {/* <p className="product-category-box-tag">1207 itens</p> */}
                </Link>

                <Link className="product-category-box category-physical" to="/marketplace-category">
                    <p className="product-category-box-title">Eletrônicos</p>

                    <p className="product-category-box-text">Notebooks, videogames...</p>

                    {/* <p className="product-category-box-tag">153 items</p> */}
                </Link>
            </div>

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Veja as novidades!</p>

                    <h2 className="section-title">Últimos itens</h2>
                </div>

                <div className="section-header-actions">
                    <Link className="section-header-action" to="/marketplace-category">Mostrar todos os itens</Link>
                </div>
            </div>

            <Filter onChange={setSearch} onSubmit={() => setFilter(search.value)} label="Pesquisar produtos" />

            <div className="grid grid-3-3-3-3 centered">
                {data.data.filter((item) => item.displayName.toLowerCase().includes(filter.toLowerCase())).map(item => (
                    <Preview className="product-preview" key={item.codigo}>
                        <Link to={`/product/${item.codigo}`}>
                            <Figure className="product-preview-image liquid" style={{ padding: '16px', background: "rgba(0, 0, 0, 0) url(&quot;../../assets/img/marketplace/items/12.jpg&quot;) no-repeat scroll center center / cover" }}>
                                <img src={`https://www.${item.lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=${item.fotoGrande}`} alt={item.displayName} />
                            </Figure>
                        </Link>

                        <div className="product-preview-info">
                            <p className="text-sticker">
                                <span className="highlighted"></span> {(item.skus[0].preco / 100).toFixed(2).toString().replace(/\./g, ',')}
                            </p>

                            <p className="product-preview-title">
                                <Link to={`/product/${item.codigo}`}>{item.displayName}</Link>
                            </p>

                            <p className="product-preview-category physical">
                                <Link to="/marketplace-category">{item.category}</Link>
                            </p>

                            <p className="product-preview-text" dangerouslySetInnerHTML={{ __html: longDesc(item) }}></p>
                        </div>
                    </Preview>
                ))}
            </div>
        </Template>
    )
}