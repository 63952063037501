import { createGlobalStyle } from "styled-components";
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "../assets/css/vendor/bootstrap.min.css"
import "../assets/css/styles.min.css"
import "../assets/js/utils/svg-loader.js"

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Koulen&display=swap');

:root {
    --primary: #18004c;
    --secondary: #5a1b73;
    --button-primary: #5a1b73;
    --button-primary-hover: #36194c;
    --text-light: #5a1b73;
    font-size: 22px;
}
button {
    cursor: pointer;
}

[disabled] {
    opacity: 0.6;
    cursor: not-allowed
}

button[aria-label="close"] {
    display: none;
}

.month1 {
  background-color: #deb1fd;
}

.month2 {
  background-color: #aebaff;
}

.month3 {
  background-color: #90ddff;
}

.content-grid {
    transition: transform 0.4s ease-in-out 0s;
    margin: 0 auto;
}

.form__input__focus:focus ~ .form__label,
.form__input__focus:not(:placeholder-shown).form__input__focus:not(:focus) ~ .form__label {
    background-color: #fff;
    padding: 0 6px;
    font-size: 0.75rem;
    top: -6px;
    left: 12px;
}

.form-link,
.form-input label {
  color: var(--text-light);
}

.hexagon-image-30-32 {
    border-radius: 50%;
    overflow: hidden;
    padding-bottom: calc(100% - 6px);
    border: 3px solid #5d6ffb;
}

.hexagon-image-30-32 img {
    max-width: 100%;
    height: auto;
}

.user-avatar.online:before {
    background-color: #1df377;
    margin-left: 3px;
    margin-top: 1px;
}

.perfect-circle {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.user-avatar-content {
    width: 100%;
}

.perfect-circle.large {
    padding-bottom: calc(100% - 10px);
    border-radius: 50%;
    border: 5px solid #5d6ffb;
}

.perfect-circle img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
}

.user-avatar-border {
    width: 100%;
}

.overlay {
    width: 100%; height: 100%; background-color: rgba(21, 21, 31, 0.96); position: fixed; top: 0px; left: 0px; z-index: 100000; opacity: 0; visibility: hidden; transition: opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
}

.pop-box {
    position: absolute; left: 50%; z-index: 100001; opacity: 0; visibility: hidden; transform: translate(0px, -40px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s; top: 0px; margin-left: -192px;
}

.Toastify {
    z-index: 100001;
    position: fixed;
}

// ToDo: move rule from style.css to here
.page-loader {
  z-index: 100000 !important;
}

.visible {
    .overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(21, 21, 31, 0.96);
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 100000;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;
    }

    .popup-box {
        position: absolute; left: 50%; z-index: 100001; opacity: 1; visibility: visible; transform: translate(0px, 0px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s; top: 100px; margin-left: -192px;
    }
}

.action-list .action-list-item.unread:after {
  width: 12px;
  height: 12px;
}

@media (min-width: 1365px) {
    .login {
        margin-top: -313px !important;
    }
}

::after, ::before {
    display: block;
}
input[type="email"]::placeholder, input[type="text"]::placeholder {
    color: var(--text-light);
    font-size: .875rem;
    font-weight: 500;
}
::selection {
    color: #fff;
    background-color: #23d2e2;
}

input[type="email"], input[type="text"] {
    height: 54px;
    padding: 0 18px;
}
input[type="email"], input[type="text"], select, textarea {
    background-color: #fff;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    transition: border-color .2s ease-in-out;
}
button, input[type="email"], input[type="text"], select, textarea {
    width: 100%;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 700;
}
button, input[type="email"], input[type="text"], label, select, textarea {
    font-family: Rajdhani,sans-serif;
}
button, input {
    overflow: visible;
}
button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.blocked {
    filter: grayscale(1) !important;
    color: #aeaeae !important;
}
.cursor-pointer {
    cursor: pointer;
}

.quest-item .quest-item-cover{
    overflow: hidden;
}

.navigation-widget.closed .menu {
    margin-top: 26px;
    overflow-y: auto;
    height: calc(100% - 100px);
}

.section-filters-bar {
  height: auto;
  padding-top: 32px;
  padding-bottom: 32px;
}

@media (max-width: 680px) {
    .header .header-actions.search-bar .interactive-input input {
        height: 100%;
    }
}
`