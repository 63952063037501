import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

//imagens
import Stadium from "../../assets/img/landing/stadium.jpg"

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 50px;
    background-image: url(${Stadium});

    .button.secondary{
        background-color: #4e246e;

        :hover{
            background-color: #36194c;
         }
    }
`;

export const Body = styled.div`
    width: 100%;
    padding: 5rem;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
    position: relative;

    p {
        line-height: 1.5rem;
    }

    @media (max-width: 840px) {
      padding-left: 32px;
      padding-right: 32px;
    }
`

export const FormRow = styled.div`
  flex-direction: row;
  justify-content: space-between;

  .form-item {
    width: 48%;
  }

  @media (max-width: 840px) {
    .form-item {
      width: 100%;
      margin-top: 0px;

      button {
        margin-top: 16px !important;
      }
    }
  }
`

export const Iframe = styled.iframe`
    width: 80vw;
    max-width: 100%;
    height: 30vw;

    @media (max-width: 840px) {
      height: 100vw;
    }
`;