import { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import BannerTitleComponent from "../../components/BannerTitle";
import Template from "../../template";
import * as S from './style';

import { useModal } from '../../contexts/ModalContext';

function AdminNoticeUser ({match}) {
  const {userId} = match.params;
  const token = localStorage.getItem('token');
  const [message, setMessage] = useState("");
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState(null);
  const { openModal } = useModal();
  const { doLogout } = useAuth();

  const handleSend = useCallback(async (evt) => {
    evt.preventDefault();
    setSaving(true);
    const data = {
      userId: user.id,
      message,
    }
    
    await api.post('notice', data, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    openModal({body: <div className='form-row'>A mensagem "{message}" foi enviada!</div>, title: 'Mensagem enviada'});
    setMessage('');
    setSaving(false);
  }, [token, message, openModal, user]);

  const fetchUserData = useCallback(async () => {
    try {
      const response = await api.get(`/user/${userId}`);
      setUser(response.data);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error('O token expirou', { theme: "colored" });
        doLogout();
        return;
      }
    }
  }, [userId, doLogout]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  if (!user) return <></>

  return <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Account Hub"
        subTitle="Info de perfil, mensagens, configuração e muito mais!"
        imgAlt="accounthub-icon"
      />
     <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Notificações</p>

              <h2 className="section-title">Envie uma mensagem</h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Usuário {user.name}</p>

              <div className="widget-box-content">
                <form className="form" action="" method="POST" onSubmit={handleSend}>
                  <div className="form-row split">
                    <div className="form-item">
                      <S.TextAreaContainer>
                        <S.TextAreaLabel htmlFor="message">Mensagem</S.TextAreaLabel>
                        <S.TextArea name="message" id="message" rows="10" onChange={(evt) => setMessage(evt.target.value)} value={message || ""} />
                      </S.TextAreaContainer>
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <button
                        type="submit"
                        className="button primary add-field-button"
                        style={{ 'margin-top': '0', float: 'right' }}
                        disabled={saving}
                      >{saving ? 'Salvando...' : 'Salvar'}</button>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
  </Template>
}

export default AdminNoticeUser;