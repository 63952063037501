import React from "react";
import * as S from './style';

export default function NotFound() {
    return (
        <S.Content>
          <div className="error-section">

            <div className="error-section-info">

                <p className="error-section-subtitle">Oops!!...</p>

                <p className="error-section-text">No momento estamos em manutenção, tente mais tarde ou entre em contato com nosso suporte<br />
                    <a href="mailto:contato@gigantesdaexecucao2022.com">contato@gigantesdaexecucao2022.com</a>
                </p>
            </div>
          </div>
        </S.Content>
    )
}