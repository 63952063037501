import styled from 'styled-components';

export const OrdenedList = styled.ol`
  counter-reset: list;
`;

export const ListItem = styled.li`
  :before {
    display: inline;
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
  }
`;