import styled, { css } from 'styled-components'

export const Container = styled.div``;

export const Toggle = styled.div`
  margin-top: 32px;
  display: flex;
  background-color: #ccc;
  border-radius: 10px;
`;

export const Button = styled.button`
  flex: 1;
  border-radius: 0;
  box-shadow: 4px 7px 12px 0 rgba(97,93,250,.2);
  background-color: #ccc;
  display: inline-block;
  height: auto;
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
  outline: none;

  ${({active}) => active && css`
    background-color: var(--primary); 
    color: #fff;
    border-radius: 10px;
  `}

  &:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const TableArea = styled.div`
  .table-header-title, .table-title { font-size: 0.65rem !important; }
  .table.table-top-friends .table-column:nth-child(2),
  .table.table-top-friends .table-column:nth-child(3),
  .table.table-top-friends .table-header-column:nth-child(2),
  .table.table-top-friends .table-header-column:nth-child(3),
  .table.table-quests .table-column:nth-child(3),
  .table.table-quests .table-header-column:nth-child(3),
  .table.table-top-friends .table-column:nth-child(4),
  .table.table-top-friends .table-column:nth-child(5),
  .table.table-top-friends .table-header-column:nth-child(4),
  .table.table-top-friends .table-header-column:nth-child(5) {
    display: table-cell;
  }
`;
