import React, { Fragment, useEffect } from 'react';
import { ToastContainer } from "react-toastify"
import { BrowserRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { GlobalStyle } from './styles'
import NotifyProvider from './contexts/NotifyContext';
import ModalProvider from './contexts/ModalContext';
import AuthProvider from './contexts/AuthContext';
import Router from './route';
import history from "./services/history"
import Cookie from './components/Cookie'
import MenuProvider from "./contexts/MenuContext"
import DesktopMenuProvider from './contexts/DesktopMenuContext';
import '@fortawesome/fontawesome-free/css/all.min.css'

export default function App () {
  
  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      //ReactGA.initialize(process.env.REACT_APP_GA_ID)
      //ReactGA.pageview(window.location.pathname + window.location.search);
      console.log('[GA] GA is loaded')
    } else {
      console.log('[GA] GA ID env is not defined')
    }
  }, [])
    return (
    <Fragment>
      <BrowserRouter history={history}>
        <DesktopMenuProvider>
          <AuthProvider>
            <NotifyProvider>
              <ModalProvider>
                <MenuProvider>
                  <Router />
                  <ToastContainer autoClose={3000} />
                  <GlobalStyle />
                </MenuProvider>
              </ModalProvider>
            </NotifyProvider>
          </AuthProvider>
        </DesktopMenuProvider>
      </BrowserRouter>

      <Cookie />
    </Fragment>
  );
}

