import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import BannerTitleComponent from '../BannerTitle'
import CardWidget from '../CardWidget'
import Template from "../../template";
import BannerTitle from "../../assets/img/banner/overview-icon.png"
import logoLMT from '../../assets/mecanicas/logo-lmt.png'
import logoGKA from '../../assets/mecanicas/logo-gka.png'
import logoCEC from '../../assets/mecanicas/logo-cec.png'
import logoAtacado from '../../assets/mecanicas/logo-atc.png'
import logoFood from '../../assets/mecanicas/logo-food.png'
import logoHib from '../../assets/mecanicas/logo-hib.png'
import logoTd from '../../assets/mecanicas/logo-td.png'
import logoVj from '../../assets/mecanicas/logo-vj.png'
import logoMerchan from '../../assets/mecanicas/logo-merchan.png'
import { useAuth } from '../../contexts/AuthContext';
import * as S from './style'

function MecanicasSeletor () {
  const { user } = useAuth();
  const mecanicas = useMemo(() => {
    if (user.canal === "STAFF") {
      return [
        { name: 'LMT', logo: logoLMT },
        { name: 'GKA', logo: logoGKA },
        { name: 'C&C', logo: logoCEC },
        { name: 'ATC', logo: logoAtacado },
        { name: 'FOOD+DIA+FARMA', logo: logoFood },
        // { name: 'FDF', logo: logoFood },
        // { name: 'DIST HIB', logo: logoHib },
        { name: 'HIB', logo: logoHib },
        // { name: 'DIST TD', logo: logoTd },
        { name: 'TD', logo: logoTd },
        { name: 'VJ', logo: logoVj },
        // { name: 'MERCHAN', logo: logoMerchan },
        { name: 'MERCHANDISING', logo: logoMerchan },
      ];
    }

    return [
      { name: 'LMT', logo: logoLMT },
      { name: 'GKA', logo: logoGKA },
      { name: 'C&C', logo: logoCEC },
      { name: 'ATC', logo: logoAtacado },
      { name: 'FOOD+DIA+FARMA', logo: logoFood },
      { name: 'FDF', logo: logoFood },
      { name: 'DIST HIB', logo: logoHib },
      { name: 'HIB', logo: logoHib },
      { name: 'DIST TD', logo: logoTd },
      { name: 'TD', logo: logoTd },
      { name: 'VJ', logo: logoVj },
      { name: 'MERCHAN', logo: logoMerchan },
      { name: 'MERCHANDISING', logo: logoMerchan },
    ].filter(c => c.name === user.canal);
  }, [user]);

  return <Template>

    <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Mecânicas"
        subTitle="Todas as mecânicas do site!"
        imgAlt="overview-icon"
    />

      <div className="section-header">
          <div className="section-header-info">
              <p className="section-pretitle">Mecânicas</p>

              <h2 className="section-title">Mecânicas</h2>
              <small style={{fontSize: '.75rem', fontWeight: '400'}}>
                As mecânicas abaixo correspondem a ONDA 4. Para consultar as mecânicas da ONDA 1, ONDA 2 ou ONDA 3 consulte Regulamento da ONDA 1, ONDA 2 ou ONDA 3.<br />
                <span style={{fontSize: '115%'}}>* Exclusivo para os colaboradores da MDLZ</span>
              </small>
          </div>

      </div>

    <div className="grid">
      <S.Grid>
        {mecanicas.map(m => <S.Item>
          <Link to={`/mecanicas/${m.name}`}>
            <CardWidget status={'Mecânica de'} message={<><strong>{m.name}</strong> <img src={m.logo} alt="" /></>}></CardWidget>
          </Link>
        </S.Item>)}
      </S.Grid>
    </div>
  </Template>
}

export default MecanicasSeletor;
