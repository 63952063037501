import { useMemo } from 'react'
import Template from "../../template"
import CardQuiz from '../../components/CardQuiz';
import { useFetch } from '../../hooks/useFetch';
import PageLoaderComponent from "../../components/PageLoader";
import BannerTitleComponent from '../../components/BannerTitle';
import * as S from './style'

import selo from "../../assets/img/banner/forums-icon.png";

export default function TesteQuiz() {
    const { data } = useFetch('/quiz');

    const quizzes = useMemo(() => {
      if(!data) return [];
      const enableAt = new Date(2022, 0, 1, 0, 0, 0);
      const disableAt = new Date(2023, 0, 1, 0, 0, 0);
      return data.quizzes.map(q => ({...q, enableAt, disableAt}));
    }, [data])

    if(!data) return <PageLoaderComponent />;

    return (
        <Template>
          
          <BannerTitleComponent
            imgUrl={selo}
            title="Jogada extra"
            subTitle="Jogue nosso quiz!"
            imgAlt="selo"
          />
            
          {quizzes.length > 0 && <S.Grid>
            {quizzes.map(quiz => <CardQuiz template="trofeu" key={quiz.id} quiz={quiz}></CardQuiz>)}
          </S.Grid>}
          {quizzes.length === 0 && <p style={{ textAlign: "center", marginTop: '64px', fontSize: '1rem' }}><strong>Em breve</strong></p>}
        </Template>
    )
}