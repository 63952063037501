import styled from 'styled-components';

export const Button = styled.div`
  button{
    border-radius: 10px;
    background-color: #4e246e;
    color: #fff;
    border: 1px solid #dedeea;
    color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      background-color: #36194c;
    }
  }
`