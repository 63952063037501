import React, { useMemo, useCallback, useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom"
import { toast } from "react-toastify";
import InputText from '../../../components/Forms/InputText';

// Template
import Template from "../../../template"

// Component
import BannerTitleComponent from "../../../components/BannerTitle"
import PageLoaderComponent from "../../../components/PageLoader"

// Image
import BannerTitle from "../../../assets/img/banner/marketplace-icon.png"

// Hooks
import { useFetch } from "../../../hooks/useFetch"

// Services
import api from "../../../services/api"

export default function Product () {
    const [currentSku, setCurrentSku] = useState();
    const [amount, setAmount] = useState();
    const { cod } = useParams();
    const history = useHistory();

    // Request API
    const { data } = useFetch(`products/${cod}`)

    const displayName = useMemo(() => {
        return data?.displayName.substr(0, 42) + '...';
    }, [data]);

    const menorPreco = useCallback(() => {
        const valor = data.skus.reduce((acc, item) => Math.min(item.preco, acc), Infinity) / 100;
        return valor.toFixed(2).toString().replace(/\./g, ',');
    }, [data])

    async function handleAddToCart (item) {
        try {
            await api.patch(`cart/${currentSku}/${amount}`)
            history.push("/carrinho")
        } catch (e) {
            console.log(e.response)
            toast.error(e.response.data.message, {
                theme: "colored"
            })
        }
    }

    useEffect(() => {
        if (!data) return;
        setCurrentSku(data.skus[0].codigo)
        setAmount(1);
    }, [data]);

    if (!data) {
        return <PageLoaderComponent />
    }

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Marketplace"
                subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
                imgAlt="marketplace-icon"
            />

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Produto</p>

                    <h2 className="section-title" style={{ maxWidth: '800px' }}>{data.displayName}</h2>
                </div>

                <div className="section-header-actions">
                    <Link className="section-header-subsection" to="/marketplace">Marketplace</Link>

                    <p className="section-header-subsection">{displayName}</p>
                </div>
            </div>

            <div className="grid grid-9-3">
                <div className="marketplace-content grid-column">
                    <div style={{ backgroundColor: '#fff', borderRadius: '12px' }}>
                        <img
                            src={`https://www.${data.lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=${data.fotoGrande}`}
                            alt={data.displayName}
                            style={{ margin: '0 auto', display: 'block' }}
                        />
                    </div>

                    <div className="tab-box">
                        <div className="tab-box-options">
                            <div className="tab-box-option active">
                                <p className="tab-box-option-title">Descrição</p>
                            </div>
                        </div>

                        <div className="tab-box-items">
                            <div className="tab-box-item">
                                <div className="tab-box-item-content">
                                    <p className="tab-box-item-paragraph" dangerouslySetInnerHTML={{ __html: data.descricaoLonga.replace(/&lt;/g, "<").replace(/&gt;/g, ">") }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="marketplace-sidebar">
                    <div className="sidebar-box">
                        <div className="sidebar-box-items">
                            <p className="price-title big"><span className="currency"></span>{menorPreco()}</p>

                            <br />

                            <InputText id="amount" label="Quantidade" name="quantidade" value={amount} onChange={setAmount} large />

                            {data.skus.length > 1 && <div className="form-select small">
                                <label for="items-filter-category">Variações</label>
                                <select id="items-filter-category" name="items_filter_category" value={currentSku} onChange={(e) => console.log(e.target.value)}>
                                    {data.skus.map(s => <option value={s.codigo}>{s.modelo}</option>)}
                                </select>
                                <svg className="form-select-icon icon-small-arrow">
                                    <use href="#svg-small-arrow"></use>
                                </svg>
                            </div>}

                            <button
                                className="button primary"
                                onClick={() => handleAddToCart(data)}
                            >
                                Adicione ao carrinho!
                            </button>
                        </div>

                        <p className="sidebar-box-title medium-space">Detalhes do item</p>

                        <div className="sidebar-box-items">
                            <div className="information-line-list">

                                {data.categoria.length && <div className="information-line">
                                    <p className="information-line-title">Categoria</p>

                                    <p className="information-line-text"><Link to={`/category/${data.categoria[0].id}`}>{data.categoria[0].nome}</Link></p>
                                </div>}

                                <div className="information-line">
                                    <p className="information-line-title">Fabricante</p>

                                    <p className="information-line-text"><span className="bold">{data.fabricante}</span></p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Template>
    )
}