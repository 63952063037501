import styled, { css } from 'styled-components';

const sizeList = {
    large: css`width: 87px;height: 100px; margin: 0 auto;`,
    "extra-large": css`width: 120px; height: 140px;` 
}

export const Hexagon = styled.div`
    position: relative;
    width: 35px;
    height: 40px;
    bottom: 5px;
    right: 1px;

    ${({size}) => sizeList[size]}

    .shape {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
`;

export const HexagonOverlay = styled.img`
  position: absolute;
  inset: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  object-fit: cover;
  z-index: 2;
`;