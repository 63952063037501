import { useMemo, useEffect, useState } from "react";
import { useFetch } from "../../hooks/useFetch";
import Template from "../../template"
import CartItem from "../../components/Cart/Item";
import BannerTitle from "../../assets/img/banner/marketplace-icon.png"
import PageLoaderComponent from "../../components/PageLoader";
import BannerTitleComponent from "../../components/BannerTitle"

export default function Cart() {
  const [products, setProducts] = useState(null);
  const { data } = useFetch('/cart/6382');

  const totalProdutos = useMemo(() => {
    return products ? products.length : 0;
  }, [products]);

  const totalItems = useMemo(() => {
    return products ? products.reduce((acc, item) => item.amount + acc, 0) : 0;
  }, [products]);

  const priceTotal = useMemo(() => {
    const total = products ? products.reduce((acc, item) => {
      return item.sku.preco * item.amount + acc;
    }, 0) : 0;
    return (total / 100).toFixed(2).replace(/\./, ',');
  }, [products]);

  const totalCredito = 0;
  const saldoFinal = useMemo(() => {
    const total = products ? products.reduce((acc, item) => {
      return item.sku.preco * item.amount + acc;
    }, 0) : 0;

    return ((totalCredito - total) / 100).toFixed(2).replace(/\./, ',');
  }, [products]);

  const handleRemoveItem = (itemIndex) => {
    const filteredProducts = products.filter((prod, key) => key !== itemIndex);
    setProducts(filteredProducts);
  }

  const handleUpdateItemAmount = (itemIndex, amount) => {
    if (amount === 0) {
      handleRemoveItem(itemIndex);
      return
    }
    const prod = [...products];
    prod[itemIndex].amount = amount;
    setProducts(prod);
  }

  useEffect(() => {
    if (data)
      setProducts(data.products);
  }, [data]);

  if (!products) return <PageLoaderComponent />;
  return (<Template>
    <BannerTitleComponent
      imgUrl={BannerTitle}
      title="Marketplace"
      subTitle="O melhor lugar para comprar ou trocar os seus pontos!"
      imgAlt="marketplace-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle">Procure seu</p>

        <h2 className="section-title">Carrinho de compras <span className="highlighted">{totalProdutos}</span></h2>
      </div>
    </div>

    <div className="grid grid-9-3 small-space">
      <div className="grid-column">
        <div className="table-wrap" data-simplebar="init"><div className="simplebar-wrapper" style={{ margin: '0px' }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ right: "0px", bottom: '0px' }}><div className="simplebar-content-wrapper" style={{ height: 'auto', overflow: 'scroll hidden' }}><div className="simplebar-content" style={{ padding: '0px' }}>
          <div className="table table-cart split-rows">
            <div className="table-header">
              <div className="table-header-column">
                <p className="table-header-title">Item</p>
              </div>

              <div className="table-header-column padded-left">
                <p className="table-header-title">Quantidade</p>
              </div>

              <div className="table-header-column centered padded-left">
                <p className="table-header-title">Preço</p>
              </div>

              <div className="table-header-column padded-big-left"></div>
            </div>

            <div className="table-body same-color-rows">
              {products.map((p, key) => <CartItem {...p} onUpdateAmount={(amount) => handleUpdateItemAmount(key, amount)} onRemove={() => handleRemoveItem(key)} />)}
            </div>
          </div>
        </div></div></div></div><div className="simplebar-placeholder" style={{ width: "auto", height: "416px" }}></div></div><div className="simplebar-track simplebar-horizontal" style={{ visibility: 'visible' }}><div className="simplebar-scrollbar" style={{ width: '840px', display: 'block', transform: 'translate3d(0px, 0px, 0px)' }}></div></div><div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}><div className="simplebar-scrollbar" style={{ height: "0px", display: "none" }}></div></div></div>


      </div>

      <div className="grid-column">
        <div className="sidebar-box margin-top">
          <p className="sidebar-box-title">Total do pedido</p>

          <div className="sidebar-box-items">
            <p className="price-title big"><span className="currency"></span> {priceTotal}</p>

            <div className="totals-line-list">
              <div className="totals-line">
                <p className="totals-line-title">Carrinho ({totalItems})</p>

                <p className="price-title"><span className="currency"></span> {priceTotal}</p>
              </div>

              <div className="totals-line">
                <p className="totals-line-title">Créditos</p>

                <p className="price-title"><span className="currency"></span> {totalCredito}</p>
              </div>

              <div className="totals-line">
                <p className="totals-line-title">Saldo Final</p>

                <p className="price-title"><span className="currency"></span> {saldoFinal}</p>
              </div>
            </div>

            <a className="button primary" href="checkout">Continuar para o checkout</a>


          </div>
        </div>
      </div>
    </div>
  </Template >)
}