import TemplateMecanica from '../../template';
import rankingImage from '../../assets/img/ranking_quiz-onda-4.jpg';
import rankingImageMobile from '../../assets/img/ranking_quiz-onda-4-mobile.jpg';
import * as S from './style'

function RankingQuizTang() {
  return <S.Content>
    <TemplateMecanica>
      <picture>
        <source srcset={rankingImageMobile} media="(max-width: 768px)" />
        <S.RankingImage src={rankingImage} />
      </picture>
    </TemplateMecanica>
  </S.Content>
}

export default RankingQuizTang;
