import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import Template from "../../../template";
import PageLoader from '../../../components/PageLoader'
import api from "../../../services/api";
import * as S from './style';

function Survey() {
  const [respostas, setRespostas] = useState()
  const loadRespostas = useCallback(async () => {
    try {
      const response = await api.get(`/pesquisa/respostas`);
      console.log(response);
      setRespostas(response.data.respostas)
    } catch (err) {
      if (!axios.isAxiosError(err)) {
        toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' });
        return;
      }

      console.log(err.response.data);
      toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' });
    }
  }, []);
  
  const handleDownload = useCallback(async () => {
    try {
      const response = await api.get(`/pesquisa/respostas/export`, { responseType: 'arraybuffer' })
      const blob = new Blob([response.data])
      const anchor = document.createElement("a");
      const url = URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = `respostas.xlsx`;
      document.body.appendChild(anchor);
      anchor.click();
      setTimeout(function() {
          document.body.removeChild(anchor);
          window.URL.revokeObjectURL(url);  
      }, 0);
    } catch (err) {}
  }, [])

  useEffect(() => {
    loadRespostas();
  }, [loadRespostas]);

  if (!respostas) return <PageLoader />
  return <Template>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>

        <h2 className="section-title">Pesquisa</h2>
      </div>
    </div>

    <S.Grid>
      <div className="widget-box">
        <button class="primary button" onClick={handleDownload}>Exportar</button>
        <S.Table>
          <tr>
            <th>ID do Usuário</th>
            <th>Pergunta</th>
            <th>Resposta</th>
          </tr>
          {respostas && respostas.map(r => <tr>
            <td>{r.id}</td>
            <td>{r.name}</td>
            <td>{r.answer}</td>
          </tr>)}
        </S.Table>
      </div>

    </S.Grid>
  </Template>
}

export default Survey;
