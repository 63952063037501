import React, { useState } from "react";
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import * as S from './style';

//Component
import Modal from "../../../components/Modal"

// Services
import api from "../../../services/api"

export default function Recovery () {
    const { token } = useParams();
    const history = useHistory();
    const [modal] = useState(true)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')

    async function handleConfirmResetPassword (e) {
        e.preventDefault()
        if (newPassword !== confirmPassword) {
            setError('Verifique se as senhas são iguais!')
        } else {
            try {
                const response = await api.patch(`session/recovery/${token}`, {
                    password: newPassword
                })
                toast.success(response.data.message, {
                    theme: "colored"
                });

                history.push("/");
            } catch (err) {
                toast.error(err.response.data.message, {
                    theme: "colored"
                });
            }
        }
    }

    return (
      <S.Container>
        <div className="landing">
            <Modal showModal={modal} title="Redefinir senha">
                <form className="form">
                    <div className="form-row">
                        <div className="form-item">
                            <div className="form-input small mb-3">
                                <input
                                    type="password"
                                    placeholder=""
                                    className="form__input__focus"
                                    onChange={e => setNewPassword(e.target.value)}
                                />
                                <label className="form__label">senha</label>
                            </div>
                            <div className="form-input small mb-3">
                                <input
                                    type="password"
                                    placeholder=""
                                    className="form__input__focus"
                                    onChange={e => setConfirmPassword(e.target.value)}
                                />
                                <label className="form__label">confirmar senha</label>
                            </div>
                            <div className="text-center mb-3">
                                {error && <span className="text-danger">{error}</span>}
                            </div>
                            <div className="mb-3">
                                <button
                                    className="popup-box-action full button secondary"
                                    onClick={(e) => handleConfirmResetPassword(e)}
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
      </S.Container>
    )
}