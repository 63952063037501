
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as S from './style'

function PoliticaDaPlataforma () {
  return <S.Container className="landing">
    <PerfectScrollbar >
        <S.Body>
            <div className="text-center mb-5">
                <h1>Termos e Condições</h1>
            </div>

            <S.FormRow className="form-row">
              <div className="widget-box">
        
    
                <p style={{fontSize: '24px', padding: '3% 15%', textAlign: 'center'}} className="widget-box-title">TERMOS DE USO</p>
            
                <div className="widget-box-content">

                    <div style={{padding: '1% 0'}} className="simple-accordion">
                      <div className="simple-accordion-header accordion-trigger">

                        <div className="simple-accordion-content accordion-content">
                          <p style={{fontSize: '1.125rem'}} className="simple-accordion-text accordion-trigger">Por favor, certifique-se de ter lido e entendido nossos “TERMOS DE USO”, que consistem
                              em contrato firmado entre a Casa de Bragança Martech, empresa devidamente inscrita sob CNPJ n 26.054.515/0001-68, Motivare Marketing empresa 
                              devidamente inscrita sob CNPJ n 65.086.480/0002-09 e o Usuário após a manifestação do “Aceite”.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">O “Aceite” dos termos descritos a seguir é indispensável para qualquer forma de utilização da aplicação. 
                              O cadastro como Usuário não é permitido para pessoa juridicamente incapaz, 
                              bem como para aqueles que não concordem plenamente com os Termos de Uso.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              O aceite livre, expresso e informado à presente Política se dará no momento do clique do Usuário no botão "Aceito", 
                              quando de seu primeiro acesso à Plataforma, estando a sua utilização condicionada à integral aceitação do quanto disposto 
                              no presente documento, sendo certo que, caso o Usuário não concorde com o quanto aqui estipulado, deverá abster-se de utilizar a Plataforma. 
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              O Usuário, desde já, declara estar ciente de que poderá, a qualquer momento, revogar o consentimento fornecido quando de seu primeiro acesso à Plataforma, 
                              por meio de pedido encaminhado à central de atendimento da plataforma. Contudo, a revogação do consentimento importará também na cessação do acesso à Plataforma, 
                              pelo Usuário, o qual não conseguirá fazer uso de suas funcionalidades.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              O Usuário é integralmente responsável pela utilização do aplicativo em seu nome e/ou
                              com a sua senha, por quaisquer encargos, demandas e/ou questionamentos decorrentes desta utilização, 
                              devendo tomar, portanto, todas as medidas e cautelas necessárias para manter em sigilo referidas informações.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              A Casa de Bragança Martech emprega medidas administrativas, lógicas, gerenciais e físicas razoáveis para proteger seus dados pessoais contra perdas, 
                              roubos e acesso, uso e alterações não autorizadas. Essas medidas são elaboradas para oferecer um nível de segurança adequado aos riscos de processamento de seus dados pessoais.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Apesar desses esforços, nenhum sistema de informação pode ser 100% seguro, por isso não podemos garantir a segurança absoluta de suas informações. Os usuários também têm um papel 
                              a cumprir para manter seus dados seguros. Recomendamos que você use uma senha exclusiva e complexa para sua conta e que não compartilhe estas credenciais com terceiros. Você só deve 
                              conceder direitos de acesso a pessoas que conhece e em quem confia e, mesmo assim, deve ter cuidado ao conceder todos os direitos de acesso. Você deve monitorar sua conta regularmente. 
                              Se você acredita que alguém obteve acesso à sua conta sem sua permissão, entre em contato com nosso cliente e conosco imediatamente para que possamos investigar.

                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Para utilização da aplicação, o Usuário deverá possuir acesso   internet em condições
                              compatíveis para seu uso, sendo de exclusiva responsabilidade do Usuário a obtenção, manutenção e custeio de tal acesso e equipamentos necessários.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              A Moderação da aplicação atenderá as necessidades de seus Usuários, e não tolerará nenhum tipo de tratamento discriminatório de qualquer natureza seja raça, sexo, cor, gênero, classe social, 
                              se comprometendo as tomar as medidas cabíveis caso tenha conhecimento de alguma prática discriminatória.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              O Usuário obriga-se a manter sigilosas as informações confidenciais, não podendo,
                              sob qualquer pretexto, utilizá-las para finalidade diversa da execução deste Contrato,
                              divulgá-las, revelá-las e/ou reproduzi-las, sem a concordância expressa da EMPRESA na qual trabalha, 
                              respondendo pelos danos morais e materiais decorrentes de tal ato.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              A presente Política é aplicável à coleta de dados realizada pela Casa de Bragança Martech por meio de suas Plataformas. Dados coletados em quaisquer outros portais, inclusive nos Portais dos Parceiros, 
                              serão de responsabilidade exclusiva do respectivo responsável e podem estar sujeitos a outras políticas ou regras, cabendo ao Usuário verificar a adequação delas.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Não coletamos intencionalmente informações pessoais de indivíduos que não tenham a idade mínima exigida. Você deve ter 18 anos ou mais para utilizar o serviço fornecido pela plataforma de incentivo. 
                              Indivíduos abaixo da idade aplicável podem usar nossos serviços apenas por meio da conta de um dos pais ou responsável legal, com o consentimento e envolvimento deles. Se você é pai ou responsável legal 
                              que acredita que seu filho forneceu informações pessoais sem o seu consentimento, você pode nos pedir para excluir essas informações entrando em contato conosco.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Coletamos informações sobre você quando usa nossos serviços. Além disso, terceiros podem coletar informações a seu respeito quando você usa nossos serviços. As informações coletadas podem incluir ou refletir 
                              informações pessoais que podem identificá-lo, bem como informações não pessoais.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Alguns dados requeridos pelos nossos clientes através do uso de nossos serviços (como páginas de site ou formulários) são informações pessoais identificáveis, que podem incluir, não se limitando a, seu nome, endereço,
                                número de telefone, endereço de email e informações da sua empresa. Essas informações serão inseridas por você em resposta às solicitações da empresa na qual trabalha.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Além desses pontos, quando você interage com algum material construído para a campanha de incentivo, o cadastro para recebimento dos mesmos pode ser solicitado via formulários de ferramentas de automação ou landing pages.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Para fazer uma compra, pode ser necessário fornecer um método de pagamento válido (por exemplo, cartão de crédito ou conta PayPal). Suas informações de pagamento serão coletadas e processadas por nossos fornecedores de pagamento autorizados. 
                              Não armazenamos diretamente números de cartão de crédito ou débito no curso normal do processamento de transações.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Você pode nos enviar dados para fins limitados, como solicitação de suporte ao cliente; responder a um questionário; participar de um estudo ou pesquisa; entrar em concursos ou sorteios; ou inscrever-se para receber comunicações nossas ou de nossos clientes. 
                              Os dados customizados são de responsabilidade de nossos clientes e possuem finalidade de orientação estratégica de negócio para os mesmos.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Coletamos automaticamente certos tipos de dados quando você usa nossos serviços, independentemente de você ter uma conta. Esses dados incluem seu endereço IP, informações técnicas sobre seu dispositivo (tipo de navegador, sistema operacional, 
                              informações básicas do dispositivo, data e horário da sua solicitação, geolocalização), a página da web que você visitou ou a consulta de pesquisa que você inseriu antes de chegar até nós e suas atividades. Podemos rastrear suas atividades usando cookies e tecnologias semelhantes.
                                Ao utilizar nossos serviços, você concorda com o uso desses métodos conforme estabelecido em nossa Política de Cookies.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Podemos usar seus dados para os seguintes fins:
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Identificação e autenticação:</strong>  usamos seus dados para verificar quando você acessa sua conta.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Operando nossos serviços:</strong> usamos seus dados para fornecer nossos serviços, processar e atender pedidos, fornecer suporte ao cliente e, de outra forma, cumprir nossas obrigações contratuais com você.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Comunicação com você:</strong> usamos seus dados quando nos comunicamos com você, por exemplo, quando enviamos e-mails automáticos do sistema e respondemos a um suporte ao cliente.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Melhorando nossos serviços:</strong> usamos seus dados para entender como nossos serviços estão sendo usados e como podemos melhorá-los. Em geral, analisamos dados agregados, 
                              em vez de dados específicos do usuário. Podemos, no entanto, precisar analisar um caso específico para resolver um problema específico como, por exemplo, um bug que afeta apenas algumas contas.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Personalizando sua experiência:</strong> usamos seus dados para personalizar o serviço para você.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Exercendo nossos direitos:</strong> onde for razoavelmente necessário, usamos seus dados para exercer nossos direitos legais e prevenir o abuso de nosso serviço.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Conformidade legal:</strong> usamos seus dados onde somos legalmente obrigados a fazê-lo. Por exemplo, podemos precisar coletar seus dados para responder a uma intimação ou ordem judicial.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong> Protegendo suas informações:</strong> quando apropriado, podemos tornar anônimos, fazer backup e excluir certos dados.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Fornecedores autorizados:</strong> podemos compartilhar seus dados com fornecedores terceirizados que nos ajudam a operar nossos serviços, processar pedidos e cumprir suas instruções e obrigações contratuais. 
                              Isso inclui processadores de pagamento, redes de entrega de conteúdo (CDNs), serviços de hospedagem baseados em nuvem, serviços de monitoramento, provedores de serviços de e-mail, garantia de qualidade 
                              e fornecedores de teste, fornecedores de prevenção de fraude e abuso, fornecedores de gerenciamento de relacionamento com o cliente (CRM) e fornecedores de remessa.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong> Análise: </strong> podemos compartilhar seus dados com provedores de análise que nos ajudam a entender como os clientes estão usando nossos serviços.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Certas situações jurídicas:</strong> podemos compartilhar seus dados onde acreditamos que a divulgação seja necessária para cumprir uma obrigação legal ou em conexão com uma transação corporativa.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Podemos fornecer links para serviços online que não possuímos ou operamos. Esses serviços estão fora desta Política de Privacidade e não temos controle sobre os dados que eles coletam. Por exemplo, se você clicar em um anúncio,
                                poderá ser levado ao site do anunciante. Uma vez em um site de terceiros, seus dados podem ser coletados por terceiros. Nós encorajamos você a ler as políticas de privacidade de tais serviços antes de usá-los.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Você pode usar nosso serviço por meio de sites de terceiros e para enviar ofertas. Por exemplo, você pode assistir a um vídeo que está embutido em um site de terceiros ou usar um de nossos aplicativos em uma plataforma de terceiros. 
                              Esta Política de Privacidade cobre nossos aplicativos e reprodutores de vídeo, mas não abrange nenhum site de terceiros ou reprodutor de vídeo de terceiros.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Nós retemos seus dados enquanto você tiver uma conta. Quando você fecha uma conta nós anonimizamos seus dados pessoais, ou seja, não mantemos nenhum dado que possa te identificar. Podemos reter registros de informações coletadas automaticamente 
                              (para análises internas e fins de segurança); e suas informações transacionais (para fins de auditoria, impostos e financeiros).
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Retemos os conteúdos excluídos em nossos servidores por um curto período, caso você deseje reverter a exclusão. Depois de excluir um conteúdo, talvez não seja possível recuperá-lo.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                          <strong>Política de cookies</strong>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Esta Política de Cookies explica o que eles são e como os usamos. É importante ler esta política para entender o que são esses cookies e quais os tipos que usamos, ou seja, as informações coletadas pelos cookies, como essas informações são usadas e como controlar as preferências de uso.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                          <strong>O que são os cookies?</strong>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Cookies são pequenos arquivos de texto usados para guardar pequenas informações. Eles são armazenados no seu dispositivo no momento em que o site é carregado no seu navegador. São os cookies que ajudam a fazer com que o site funcione corretamente tornando-o mais seguro,
                                proporcionando uma melhor experiência do usuário, entendendo como o site funciona, analisando o que funciona e o que precisa ser aprimorado.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                          <strong>Como nós usamos os cookies?</strong>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Como a maioria dos serviços on-line, nosso site usa cookies primários e de terceiros para diversas finalidades. Os cookies primários são necessários, principalmente, para que o site funcione da maneira correta e cuida para que não seja coletado nenhum dado de identificação pessoal.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Já os cookies de terceiros têm, como função principal, entender o desempenho do site, a forma como você interage com ele, fornecer anúncios relevantes para você e, em geral, prover uma melhor experiência do usuário ajudando a acelerar futuras interações com o nosso site, além de manter nossos serviços seguros.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Os cookies podem ser de "sessão" ou "persistentes". Um cookie de sessão expira (ou seja, é excluído) quando você fecha o navegador. Um cookie persistente permanece até que expire ou você exclua os cookies através das configurações do seu navegador. As datas de expiração são definidas nos próprios cookies e podem variar em duração, 
                              dependendo da finalidade do cookie.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                            <strong>Que tipos de cookies nós usamos?</strong>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Essenciais:</strong> alguns cookies são essenciais para que você possa experimentar todas as funcionalidades dos nossos serviços. Eles nos permitem manter as sessões do usuário ativas e evitar ameaças à segurança, lembrando, mais uma vez, que eles não coletam e nem armazenam nenhuma informação pessoal. 
                              Eles permitem, por exemplo, que você faça login na sua conta e faça o checkout de compras com segurança.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Estatísticos:</strong> estes cookies armazenam informações tais como o número de visitantes do site, o número de visitas únicas, quais páginas do site foram visitadas, a fonte da visita, etc. Esses dados nos ajudam a entender e analisar o conteúdo e o desempenho do site e onde é preciso fazer melhorias.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Preferências:</strong> estes cookies nos ajudam a armazenar configurações e preferências de navegação, como preferências de idioma, por exemplo, para que você tenha uma melhor experiência e maior eficiência em futuras visitas ao site.
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <strong>Como eu posso controlar as preferências?</strong>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Você pode limitar os cookies definidos no seu navegador, executando as etapas descritas abaixo. Observe que recusar cookies pode afetar sua capacidade de usar nossos serviços. Você pode alterar as configurações do seu navegador para excluir cookies que já foram definidos e rejeitar novos cookies. 
                              Para saber mais, visite as páginas de ajuda do seu navegador:
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
                                  Firefox
                              </a>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <a href="https://support.google.com/chrome/answer/95647">
                                  Chrome
                              </a>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                                  Safari
                              </a>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy">
                                  Microsoft Edge
                              </a>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                                  Internet Explorer
                              </a>
                          </p>

                          <p style={{fontSize: '18px', padding: '1% 0'}} className="simple-accordion-text accordion-trigger">
                              Você também pode visitar nossos sites no modo "privado" ou "anônimo" do seu navegador; nesse caso, os cookies serão definidos, mas excluídos quando você fechar o navegador.
                          </p>

                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </S.FormRow>
        </S.Body>
      </PerfectScrollbar>

    </S.Container>
}

export default PoliticaDaPlataforma;
