import { useCallback, useEffect, useMemo, useState } from 'react';
import api from '../../services/api';

function FiltroResultados({ OnFilter, OnChangeCanais }) {
  const [filter, setFilter] = useState({ tipo: 'results', onda: 1 });
  const [canais, setCanais] = useState([]);
  const [nomes, setNomes] = useState();
  const [kpis, setKPIs] = useState();
  const [matriculasGa, setMatriculasGa] = useState();
  const [matriculasGr, setMatriculasGr] = useState();
  const [matriculas, setMatriculas] = useState();
  const [holdings, setHoldings] = useState();
  const [matriculasPromotor, setMatriculasPromotor] = useState();

  const isNotGKA = useMemo(() => {
    if (!canais) return true
    const channel = canais.find(c => c.name === 'GKA')
    if (!channel) return true
    console.log(`isNotGKA: ${filter.canal !== channel.id}`)
    return filter.canal !== channel.id
  }, [filter, canais])

  const hasSupervisor = useMemo(() => {
    return filter.onda.toString() === "1" || filter.channelName !== 'MERCHANDISING'
  }, [filter])

  const handleSetCanais = useCallback(async (evt) => {
    let channelName;
    const channel = canais.find(c => c.id === evt.target.value);
    if (channel) channelName = channel.name;
    let tipo = 'results';
    if (channelName === 'MERCHANDISING') {
      console.log(channelName);
      tipo = 'results'
    }
    setFilter({ ...filter, canal: evt.target.value, channelName, nome: '', kpi: '', matriculaGa: '', matriculaGr: '', matricula: '', cdgHolding: '', tipo });
    if (OnChangeCanais) OnChangeCanais(evt.target.value, channel);
    setNomes();
    setKPIs();
    setMatriculasGa();
    setMatriculasGr();
    setMatriculas();
    if (!evt.target.value) return;
    console.log({ channelId: evt.target.value, tipo });
    const response = await api.get(`/results/gr`, {
      params: { channelId: evt.target.value, tipo }
    });
    setMatriculasGr(response.data.gr);
  }, [filter, canais, OnChangeCanais]);

  const handleSetNome = useCallback(async (evt) => {
    setFilter({ ...filter, nome: evt.target.value, kpi: '', matriculaGa: '', matriculaGr: '', matricula: '', cdgHolding: '' })
    setKPIs();
    setMatriculasGa();
    setMatriculasGr();
    setMatriculas();
    if (!evt.target.value) return;
    const response = await api.get(`/results/kpis/${evt.target.value}`);
    setKPIs(response.data.kpis);
  }, [filter]);

  const handleSetKPI = useCallback(async (evt) => {
    setFilter({ ...filter, kpi: evt.target.value })
  }, [filter]);

  const handleSetMatriculasGR = useCallback(async (evt) => {
    setFilter({ ...filter, matriculaGr: evt.target.value, kpi: '', matriculaGa: '', matricula: '', cdgHolding: '', matriculaPromotor: '' })
    setKPIs();
    setMatriculasGa();
    setMatriculas();
    setHoldings();
    setMatriculasPromotor();
    if (!evt.target.value) return;
    let response
    if (isNotGKA) {
      if (hasSupervisor) {
        response = await api.get(`/results/ga`, { params: { ...filter, matriculaGr: evt.target.value } });
        setMatriculasGa(response.data.ga);
        return;
      }
      
      response = await api.get(`/results/ex`, { params: { ...filter, matriculaGr: evt.target.value } });
      setMatriculas(response.data.executivos);
      return;
    }
    response = await api.get(`/results/holding`, { params: { ...filter, matriculaGr: evt.target.value } });
    setHoldings(response.data.holdings);

  }, [filter, isNotGKA, hasSupervisor])

  const handleSetMatriculasGA = useCallback(async (evt) => {
    setFilter({ ...filter, matriculaGa: evt.target.value, matricula: '', cdgHolding: '', matriculaPromotor: '', kpi: '' })
    setMatriculas();
    setMatriculasPromotor()
    setKPIs();
    if (!evt.target.value) return;
    const response = await api.get(`/results/ex`, { params: { ...filter, matriculaGa: evt.target.value } });
    setMatriculas(response.data.executivos);
  }, [filter])

  const loadKpis = useCallback(async (evt) => {
    setKPIs();
    if (!evt.target.value) return;
    const response = await api.get(`/results/kpis`, { params: { ...filter, matricula: evt.target.value } });
    setKPIs(response.data.kpis);
    return;
  }, [filter])

  const handleSetHolding = useCallback(async (evt) => {
    setFilter({ ...filter, cdgHolding: evt.target.value })
    loadKpis(evt);
  }, [filter, loadKpis])

  const handleSetMatriculasEx = useCallback(async (evt) => {
    setFilter({ ...filter, matricula: evt.target.value, matriculasPromotor: '', kpi: '' })
    if (filter.channelName === 'MERCHANDISING') {
      setMatriculasPromotor();
      setKPIs();
      if (!evt.target.value) return;
      const response = await api.get(`/results/promotor`, { params: { ...filter, matricula: evt.target.value } });
      setMatriculasPromotor(response.data.promotores);
      return;
    }

    loadKpis(evt);

  }, [filter, loadKpis])

  const loadCanaisData = useCallback(async () => {
    setCanais([]);
    const response = await api.get('/results/channels');
    setCanais(response.data.channels);
  }, [])

  const handleFilter = useCallback((evt) => {
    evt.preventDefault();
    if (OnFilter) OnFilter(filter);
  }, [filter, OnFilter]);

  useEffect(() => {
    loadCanaisData();
  }, [loadCanaisData])
  return <div className="section-filters-bar v2" style={{ height: 'auto' }}>
    <form className="form">
      <div className="form-item split medium">
        <div className="form-select">
          <label htmlFor="quest-filter-show">Onda</label>
          <select id="quest-filter-show" defaultValue={filter.onda} onChange={(evt) => setFilter({ ...filter, onda: evt.target.value })}>
            <option value="">Selecionar</option>
            <option value="1">Onda 1</option>
            <option value="2">Onda 2</option>
            <option value="3">Onda 3</option>
            <option value="4">Onda 4</option>
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>

        {canais && <div className="form-select">
          <label htmlFor="quest-filter-criteria">Canal</label>
          <select id="quest-filter-criteria" onChange={handleSetCanais}>
            <option value="">Selecionar</option>
            {canais.map(c => <option value={c.id}>{c.name}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {nomes && <div className="form-select">
          <label htmlFor="quest-filter-criteria">Nome da Holding</label>
          <select id="quest-filter-criteria" onChange={handleSetNome}>
            <option selected={filter.nome === ''} value="">Selecionar</option>
            {nomes.map(n => <option value={n.cod}>{n.name}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {matriculasGr && <div className="form-select">
          <label htmlFor="quest-filter-criteria">{filter.channelName === 'MERCHANDISING' ? 'Nome do Gerente' : "Nome do GR"}</label>
          <select id="quest-filter-criteria" value={filter.matriculaGr} onChange={handleSetMatriculasGR}>
            <option selected={filter.matricula === ''} value="">Selecionar</option>
            {matriculasGr.map(c => <option value={c.matricula}>{c.name || c.matricula}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

      </div>
      <div className="form-item split medium" style={{ marginTop: '32px' }}>
        {!isNotGKA && holdings && <div className="form-select">
          <label htmlFor="quest-filter-criteria">{filter.channelName === 'MERCHANDISING' ? 'Nome do Supervisor' : "Nome da holding"}</label>
          <select id="quest-filter-criteria" value={filter.cod} onChange={handleSetHolding}>
            <option value=''>Selecionar</option>
            {holdings.map(c => <option value={c.cod}>{c.name}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {isNotGKA && matriculasGa && hasSupervisor && <div className="form-select">
          <label htmlFor="quest-filter-criteria">{filter.channelName === 'MERCHANDISING' ? 'Nome do Supervisor' : "Nome do GA"}</label>
          <select id="quest-filter-criteria" value={filter.matriculaGa} onChange={handleSetMatriculasGA}>
            <option value={filter.matricula}>Selecionar</option>
            {matriculasGa.map(c => <option value={c.matricula}>{c.name || c.matricula}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {isNotGKA && matriculas && <div className="form-select">
          <label htmlFor="quest-filter-criteria">{filter.channelName === 'MERCHANDISING' ? 'Nome do Coordenador' : "Nome do Executivo"}</label>
          <select id="quest-filter-criteria" value={filter.matricula} onChange={handleSetMatriculasEx}>
            <option selected={filter.matricula === ''} value="">Selecionar</option>
            {matriculas.map(c => <option value={c.matricula}>{c.name || c.matricula}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {(matriculasPromotor && filter.channelName === 'MERCHANDISING') && <div className="form-select">
          <label htmlFor="quest-filter-criteria">Nome do Promotor</label>
          <select id="quest-filter-criteria" value={filter.matriculaPromotor} onChange={(evt) => { setFilter({ ...filter, matriculaPromotor: evt.target.value }); loadKpis(evt); }}>
            <option selected={filter.matricula === ''} value="">Selecionar</option>
            {matriculasPromotor.map(c => <option value={c.matricula}>{c.name || c.matricula}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        {kpis && <div className="form-select">
          <label htmlFor="quest-filter-order">KPI</label>
          <select id="quest-filter-order" onChange={handleSetKPI}>
            <option selected={filter.kpi === ''} value="">Selecionar</option>
            {kpis.map(k => <option value={k.id}>{k.name}</option>)}
          </select>
          <svg className="form-select-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>}

        <button className="button secondary" onClick={handleFilter}>Filtrar</button>
      </div>
    </form>
  </div>
}

export default FiltroResultados;
