import { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from "react-router-dom"
import { toast } from 'react-toastify';
import axios from 'axios';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useAuth } from '../../../contexts/AuthContext';
import { useModal } from '../../../contexts/ModalContext';
import FormUserModal from '../../../components/FormUserModal';
import Modal from '../../../components/Modal';
import PageLoaderComponent from '../../../components/PageLoader';

import regulamentoMerchanOnda4Desktop from "../../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Merchan 0109.pdf";
import regulamentoMerchanOnda4Mobile from "../../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Merchan 0109.pdf";
import regulamentoVendasOnda4Desktop from "../../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Vendas 1309.pdf";
import regulamentoVendasOnda4Mobile from "../../../assets/documents/onda4/2022. Onda 4 Gigantes da Execução Vendas 1309.pdf";
// Services
import api from '../../../services/api';

// Styles
import * as S from "./style"

export default function Activation() {
  const { token } = useParams();
  const { user, doLogout } = useAuth();
  const { openModal } = useModal();
  const history = useHistory();
  const [term, setTerm] = useState({});

  const [preModal, setPreModal] = useState(false);
  const [modal, setModal] = useState(false);

  async function handleRejectTerm() {
    openModal({
      body: <div className="form-row">Você tem certeza que NÃO deseja participar da campanha “Gigantes da Execução” com a Mondelez?</div>,
      title: "NÃO participar",
      onConfirm: rejectTerm,
      onCancel: () => { },
      size: 'small',
      confirmText: "Não quero participar",
      cancelText: "Voltar"
    });
  }

  async function rejectTerm() {
    try {
      const response = await api.get(`/activate/${token.replace("token=", '')}/reject`)
      toast.success(response.data.message, {
        theme: "colored"
      });

      doLogout();
    } catch (err) {
      toast.error(err.response.data.message, {
        theme: "colored"
      });
    }
  }

  const handleConfirmTerm = useCallback(async (user, address, acceptInfo, acceptTerms, password, newPassword) => {
    try {
      await api.patch(`/me/password`, { password, newPassword });
      await api.put(`/me/${user.id}`, { ...user, address });

      const response = await api.post(`/activate/${token.replace("token=", '')}`, { acceptInfo, acceptTerms })
      toast.success(response.data.message, { theme: "colored" });

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.user));

      history.push("/home");

    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(err.response.data.message, { theme: 'colored' });
        return
      }
      toast.error("Ocorreu um erro e não foi possivel atualizar os dados", { theme: 'colored' });
    }
  }, [token, history]);

  const handleOpenModal = useCallback(() => {
    setPreModal(true);
  }, []);

  const regulamentoOnda4Desktop = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda4Desktop;

    return regulamentoVendasOnda4Desktop;
  }, [user]);

  const regulamentoOnda4Mobile = useMemo(() => {
    if (!user) return;
    if (user.equipe === "MERCHAN") return regulamentoMerchanOnda4Mobile;

    return regulamentoVendasOnda4Mobile;
  }, [user]);

  useEffect(() => {
    const Data = async () => {
      try {
        const { data } = await api.get(`term/${token.replace("token=", '')}`)
        setTerm(data)
      } catch (err) {
        if (!err.response) {
          toast.error('Ocorreu um erro no servidor', {
            theme: "colored"
          });
          return;
        }

        toast.error(err.response.data.message, {
          theme: "colored"
        });
        // history.push("/");
      }
    };
    Data();
  }, [token]);

  if (!term) <PageLoaderComponent />

  return (<>
    <S.Container className="landing">
      <PerfectScrollbar >
        <S.Body>
          <div className="text-center mb-5">
            <h1>Termos e Condições</h1>
          </div>

          <S.Iframe className='desk-visible' src={regulamentoOnda4Desktop} title="Regulamento" />
          <S.Iframe className='mobile-visible' src={regulamentoOnda4Mobile} title="Regulamento" />

          <S.FormRow className="form-row">
            <div className="form-item">
              <button className="button medium secondary mt-5" type="text" onClick={handleRejectTerm}>
                Recuso os termos
              </button>
            </div>

            <div className="form-item">
              <button className="button medium secondary mt-5" type="text" onClick={handleOpenModal}>
                Aceito os termos
              </button>
            </div>
          </S.FormRow>
        </S.Body>
      </PerfectScrollbar>

    </S.Container>
    {preModal &&
      <Modal showModal={true} title={''} onClose={() => { setModal(true); setPreModal(false) }} size='small'>
        <div className="form">
          <div className="form-row">
            Chegamos à ONDA 4!
            <br />
            <br />
            Muita atenção ao seu cadastro.
            <br />
            <br />
            Mantenha-o atualizado e verifique se suas informações estão corretas, caso contrário você ficará impedido de participar das ações e de computar seus pontos na PREMMIAR. Lembrando que para navegar pela plataforma, basta digitar sua senha antiga ou criar uma nova.
            <br />
            <br />
            Bora dar aquela conferida?
          </div>

          <div className="popup-box-actions medium void">
            <button className="button medium secondary" onClick={() => { setModal(true); setPreModal(false) }}>Ok</button>
          </div>
        </div>
      </Modal>}
    {modal && <FormUserModal user={user} onClose={() => { setModal(false) }} onConfirm={handleConfirmTerm} />}
  </>
  )
}