import React, { useState, useEffect, useMemo, useCallback } from "react";
import api from "../../services/api";
import Template from "../../template"
import { useFetch } from "../../hooks/useFetch"
import { useAuth } from "../../contexts/AuthContext"
import ProgressBar from "../../components/ProgressBar";
import PageLoaderComponent from '../../components/PageLoader';
import BannerTitleComponent from "../../components/BannerTitle"
import QuestItem from '../../components/QuestItem'
import Open from "../../assets/img/quest/openq-b.png"
import Done from "../../assets/img/quest/completedq-b.png"
import BannerTitle from "../../assets/img/banner/quests-icon.png"
import { useUpdateData } from "../../hooks/useUpdateData";

export default function Quests() {
    const [questList, setQuestList] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [filter, setFilter] = useState({});
    const [filter2, setFilter2] = useState({});
    const { user } = useAuth();
    const { data } = useFetch('quest')

    const fetchData = useCallback(async (params) => {
        const { data } = await api.get('quest/filter', {
            params
        });
        setQuestList(data.content);
    }, []);

    const handleFilter = useCallback(async (e) => {
        e.preventDefault();
        fetchData(filter);
    }, [filter, fetchData])

    const handleFilter2 = useCallback(async (e) => {
        e.preventDefault();
        let f = data.content;
        if (filter2.quest_status === "Completas") {
          f = f.filter(q => q.qp_completed_at !== null);
        }
        if (filter2.quest_status === "Incompletas") {
          f = f.filter(q => q.qp_completed_at === null);
        }
        if(filter2.channel) {
          f = f.filter(a => a.quest_canal === filter2.channel || a.quest_canal === '')
        }
        
        if(filter2.order === "new") {
          f = f.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
        }
        if(filter2.order === "old") {
          f = f.sort((a, b) => a.created_at > b.created_at ? -1 : 1)
        }
        setFiltered(f);
        return f;
    }, [filter2, data])

    const channels = useMemo(() => {
      if (!data) return [];
      
      return [...new Set(data.content.map(d => d.quest_canal).filter(c => c !== null))];
    }, [data])

    const ordened = useMemo(() => {
      if (!filtered) return []
      const mapped = filtered.reduce((acc, progress) => {
        const index = acc.findIndex(p => p.quest_kpi_id === progress.quest_kpi_id)
        if (index === -1) {
          let totalReward = 0;
          if (progress.qp_progress >= progress.quest_total_progress)
          {
            totalReward = parseInt(progress.quest_reward_value, 10);
          }
          acc.push({...progress, totalReward});
          return acc;
        }


        if (acc[index].quest_total_progress < progress.quest_total_progress)
        {
          acc[index].quest_name = progress.quest_name;
          acc[index].quest_total_progress = progress.quest_total_progress;
        }
        
        if (acc[index].qp_progress >= progress.quest_total_progress)
        {
          acc[index].totalReward += parseInt(progress.quest_reward_value, 10);
        }

        return acc
      }, []);

      return mapped;
    }, [filtered])

    const periodoDado = useUpdateData(filter.channel);

    useEffect(() => {
        fetchData(null);
    }, [fetchData]);

    useEffect(() => {
      if (!data) return [];
      setFiltered(data.content);
    }, [data]);


    if (!data) return <PageLoaderComponent />
    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="KPI"
                subTitle="Complete KPI para ganhar estrelas!"
                imgAlt="quests-icon"
            />


            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Complete qualquer KPI!</p>

                    <h2 className="section-title">KPI Disponíveis</h2>

                    {!filter2.channel && <p style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>Dados parciais</p>}
                    {filter2.channel && <p style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{periodoDado(filter2.channel)}</p>}
                </div>
            </div>

            {user.canal === "STAFF" && <div className="section-filters-bar v2">
                <form className="form">
                    <div className="form-item split medium">
                        <div className="form-select">
                            <label htmlFor="quest-filter-show">KPI</label>
                            <select id="quest-filter-show" name="quest_filter_show" model={filter2.quest_status} onChange={(e) => setFilter2({ ...filter2, quest_status: e.target.value })}>
                                <option value="">Todos os KPI</option>
                                <option value="Completas">KPI Completos</option>
                                <option value="Incompletas">KPI Incompletos</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select">
                            <label htmlFor="quest-filter-criteria">Filtrar por</label>
                            <select id="quest-filter-criteria" name="quest_filter_criteria" model={filter2.channel} onChange={(e) => setFilter2({ ...filter2, channel: e.target.value })}>
                                <option value="">Todos os canais</option>
                                {channels.map(c => <option key={c} value={c}>{c === "FDF" ? "Food+Dia+Farma" : c}</option>)}
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select">
                            <label htmlFor="quest-filter-order">Ordenador por</label>
                            <select id="quest-filter-order" name="quest_filter_order" model={filter2.order} onChange={(e) => setFilter2({ ...filter2, order: e.target.value })}>
                                <option value="new">Mais recente</option>
                                <option value="old">Mais antiga</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <button className="button secondary" onClick={handleFilter2}>Filtrar KPI</button>
                    </div>
                </form>
            </div>}

            <div className="grid grid-3-3-3-3 centered">
                {ordened.map((q, key) => (<QuestItem key={key} {...q} />))}
            </div>

            <div className="section-header">
                <div className="section-header-info">
                    <p className="section-pretitle">Ganhe EXP e suba de nível</p>

                    <h2 className="section-title">Histórico de KPI</h2>
                </div>
            </div>

            <div className="section-filters-bar v2">
                <form className="form">
                    <div className="form-item split medium">
                        <div className="form-select">
                            <label htmlFor="quest-filter-show">KPI</label>
                            <select id="quest-filter-show" name="quest_filter_show" model={filter.quest_status} onChange={(e) => setFilter({ ...filter, quest_status: e.target.value })}>
                                <option value="">Todos os KPI</option>
                                <option value="Completas">KPI Completos</option>
                                <option value="Incompletas">KPI Incompletos</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select" style={{visibility: 'hidden'}}>
                            <label htmlFor="quest-filter-criteria">Filtrar por</label>
                            <select id="quest-filter-criteria" name="quest_filter_criteria" model={filter.quest_reward} onChange={(e) => setFilter({ ...filter, quest_reward: e.target.value })}>
                                <option value="Todas">Todas as KPI</option>
                                <option value="Moedas">KPI de Moedas</option>
                                <option value="Experiência">KPI de EXP</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <div className="form-select">
                            <label htmlFor="quest-filter-order">Ordenador por</label>
                            <select id="quest-filter-order" name="quest_filter_order" model={filter.order} onChange={(e) => setFilter({ ...filter, order: e.target.value })}>
                                <option value="new">Mais recente</option>
                                <option value="old">Mais antiga</option>
                            </select>
                            <svg className="form-select-icon icon-small-arrow">
                                <use href="#svg-small-arrow"></use>
                            </svg>
                        </div>

                        <button className="button secondary" onClick={handleFilter}>Filtrar KPI</button>
                    </div>
                </form>
            </div>

            {questList ? <div className="table table-quests split-rows">
                <div className="table-header">
                    <div className="table-header-column">
                        <p className="table-header-title">KPI</p>
                    </div>

                    <div className="table-header-column">
                        <p className="table-header-title">Descrição</p>
                    </div>

                    <div className="table-header-column centered padded-big-left">
                        <p className="table-header-title">Prêmio</p>
                    </div>

                    <div className="table-header-column padded-big-left">
                        <p className="table-header-title">Progresso</p>
                    </div>
                </div>
                {questList.map((m, key) => <div className="table-body same-color-rows" key={key}>
                    <div className="table-row small">
                        <div className="table-column">
                            <div className="table-information">
                                <img className="table-image" src={m.qp_progress >= m.quest_total_progress ? Done : Open} alt="openq-s" />

                                <p className="table-title">{m.quest_name.replace(/.* - ([^-]+)$/, '$1')}</p>
                            </div>
                        </div>

                        <div className="table-column">
                            <p className="table-text">{m.quest_description.split(/[\r\n]/gi).map(e => <span key={e}>{e}<br /></span>)}</p>
                        </div>

                        <div className="table-column centered padded-big-left">
                            <p className="text-sticker void">
                                <svg className="text-sticker-icon icon-plus-small">
                                    <use href="#svg-plus-small"></use>
                                </svg>
                                {m.quest_reward_type_name !== 'Medalhas' ? `${m.quest_reward_value} ${m.quest_reward_type_name}` : "Medalha"}
                            </p>
                        </div>

                        <div className="table-column padded-big-left">
                            <div className="progress-stat-wrap">
                                <div className="progress-stat">
                                    <div id="quest-line-sm" className="progress-stat-bar" style={{ width: "200px", height: "4px", position: "relative" }}>
                                        <ProgressBar current={m.qp_progress} max={m.quest_total_progress || m.task_percent} />
                                    </div>

                                    <div className="bar-progress-wrap">
                                        <p className="bar-progress-info medium negative">
                                            <span className="bar-progress-text no-space">
                                              {m.qp_progress}
                                              <span className="bar-progress-unit">/</span>
                                              {m.quest_total_progress || m.task_percent}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
                :
                <div className="loader-bars">
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                    <div className="loader-bar"></div>
                </div>}
        </Template>
    )
}