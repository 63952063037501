import React from "react";
import CardWidget from '../../components/CardWidget';
import UserStatus from "../../components/UserStatus";
import { useNotify } from '../../contexts/NotifyContext';
import * as S from './style';

// Template
import Template from "../../template"

// Components
import BannerTitleComponent from "../../components/BannerTitle"

// Image
import BannerTitle from "../../assets/img/banner/newsfeed-icon.png"

export default function Newsfeed () {
    const { notices } = useNotify();
    console.log(notices);
    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Canal de notícias"
                subTitle="Veja as últimas novidades aqui!"
                imgAlt="newsfeed-icon"
            />

            <div className="grid mobile-prefer-content">
                <div className="grid-column">
                    {notices.length > 0 && notices.map(notice =>
                        <CardWidget status={<UserStatus user={notice.sender} createdAt={notice.createdAt} />} message={<div dangerouslySetInnerHTML={{ __html: notice.message }}></div>}></CardWidget>
                    )}

                    {notices.length === 0 && <S.Center>Você ainda não tem nenhuma mensagem</S.Center>}
                </div>
            </div>


        </Template >
    )
}