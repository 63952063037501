import styled from 'styled-components';

export const ImgPreview = styled.img`
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
`;

export const Content = styled.div`
  @media (max-width: 375px) {
    .section-filters-bar-actions {
      flex-direction: column;
    }

    .form+.filter-tabs {
      margin-left: 0 !important;
    }
  }
`;

export const Tabs = styled.div`
  @media (max-width: 375px) {
    flex-direction: column;
    .filter-tab{ 
      width: 100%;
      text-align: center;
      margin-top: 8px;
      &:before, &:after {
        display: none;
      }
    }
  }

`;
