import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components'

export const Content = styled.div`
  img.responsive {
    width: 100%;
    display: block;
    height: auto;
  }

  img.desktop-visible {
    display: none;
  }
  img.mobile-visible {
    display: none;
  }

  @media (max-width: 765px) {
    .large img.mobile-visible {
      display: block;
    }
  }

  @media (min-width: 765px) {
    .large img.desktop-visible {
      display: block;
    }
  }

  @media (max-width: 1065px) {
    .small img.mobile-visible {
      display: block;
    }
  }

  @media (min-width: 1065px) {
    .small img.desktop-visible {
      display: block;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 32px;
`

export const Button = styled(RouterLink).attrs(() => {
  return { className: 'button secondary' }
})`
  flex: 1;
`
