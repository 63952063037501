import { useCallback, useMemo } from 'react'
import * as S from './style'

function ResultsTable({ results, periodoDado, onda }) {
  const mes1 = useMemo(() => {
    if (onda.toString() === '1') return 'Fev';
    if (onda.toString() === '2') return 'Mai';
  }, [onda])
  const mes2 = useMemo(() => {
    if (onda.toString() === '1') return 'Mar';
    if (onda.toString() === '2') return 'Jun';
  }, [onda])
  const calcResultado = useCallback((result) => {
    let value = Math.floor(result.resultado1);
    return `${value}%`;
  }, [])

  const numeric = useCallback(value => {
    return new Intl.NumberFormat('pt-BR', {}).format(value)
  }, [])

  if (!results) return 'Carregando...'
  return <div className="widget-box" style={{ marginTop: '32px' }}>
    <p className="widget-box-title">Resumo do Resultado</p>

    {periodoDado}

    <S.BoxContent className="widget-box-content no-margin-top">
      <div className="table table-top-friends join-rows">
        <div className="table-header">
          <div className="table-header-column">
            <p className="table-header-title">Cliente/ Holding</p>
          </div>

          <div className="table-header-column">
            <p className="table-header-title">KPI</p>
          </div>

          <div className="month1 table-header-column centered padded">
            <p className="table-header-title">Target {mes1}</p>
          </div>

          <div className="month1 table-header-column centered padded">
            <p className="table-header-title">Resultado {mes1}</p>
          </div>

          <div className="month2 table-header-column centered padded">
            <p className="table-header-title">Target {mes2}</p>
          </div>

          <div className="month2 table-header-column centered padded">
            <p className="table-header-title">Resultado {mes2}</p>
          </div>

          {onda.toString() === '1' && <div className="month3 table-header-column centered padded">
            <p className="table-header-title">Target Abr</p>
          </div>}

          {onda.toString() === '1' && <div className="month3 table-header-column centered padded">
            <p className="table-header-title">Resultado Abr</p>
          </div>}

          <div className="table-header-column centered padded">
            <p className="table-header-title">Target da Onda</p>
          </div>

          <div className="table-header-column centered padded">
            <p className="table-header-title">Realizado da Onda</p>
          </div>

          <div className="table-header-column centered padded">
            <p className="table-header-title">Resultado da Onda</p>
          </div>
        </div>

        <div className="table-body">
          {results.map(result => <div key={result.id} className="table-row tiny">
            <div className="table-column">
              <div style={{ fontWeight: 'bold' }}>
                <p className="user-status-title"><span className="bold" href="">{result.nomeClienteHolding}</span></p>
              </div>
            </div>

            <div className="table-column">
              <p className="table-title" style={{ fontSize: '.75rem' }}>{result.kpi.name}</p>
            </div>

            <div className="month1 table-column centered padded">
              <p className="table-title">{numeric(result.targetPeriodo1 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="month1 table-column centered padded">
              <p className="table-title">{numeric(result.realizadoPeriodo1 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="month2 table-column centered padded">
              <p className="table-title">{numeric(result.targetPeriodo2 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="month2 table-column centered padded">
              <p className="table-title">{numeric(result.realizadoPeriodo2 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            {onda.toString() === '1' && <div className="month3 table-column centered padded">
              <p className="table-title">{numeric(result.targetPeriodo3 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>}

            {onda.toString() === '1' && <div className="month3 table-column centered padded">
              <p className="table-title">{numeric(result.realizadoPeriodo3 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>}

            <div className="table-column centered padded">
              <p className="table-title">{Math.floor(result.target1 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="table-column centered padded">
              <p className="table-title">{Math.floor(result.realizado1 / (result.kpi.type === 1 ? 1 : 100))}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="table-column centered padded">
              <p className="table-title">{calcResultado(result)}</p>
            </div>

            <div className="table-column centered padded">
            </div>

          </div>)}

        </div>
      </div>
    </S.BoxContent >
  </div>
}

export default ResultsTable;
