import React from 'react';
import logo from '../../assets/img/landing/logo-principal.png';
import Loading from "../Loading"
import * as S from './style'

export default function PageLoaderComponent () {
    return (
        <div>
            <div className="page-loader">
                <div className="page-loader-decoration">
                    <S.Logo src={logo} alt="carregando..." />
                </div>

                <div className="page-loader-info">
                    <p className="page-loader-info-title">Gigantes da execução</p>

                    <p className="page-loader-info-text">Carregando...</p>
                </div>

                <Loading />
            </div>
        </div>
    )
}