import { useCallback, useEffect, useRef } from 'react';

/**
 * Add ClickOutside event to element
 * 
 * @param {callback} onClickOutside - Callback to perform when click outside
 * @returns [wrappedRef] - Add this to ref on element
 */
export function useClickOutside (onClickOutside) {
  const wrapperRef = useRef(null);

  const handleClickOutside = useCallback((evt) => {
    if(wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
      onClickOutside();
    }
  }, [wrapperRef, onClickOutside]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef, handleClickOutside]);

  return [wrapperRef]
}