import styled from 'styled-components';

export const Label = styled.label``;

export const Input = styled.input`
  margin-right: .5rem;
`;

export const Error = styled.div`
  color: var(--danger);
  font-weight: bold;
  margin: 8px 0 0 0;
  padding: 0;
`;