import * as S from './style';

function InputButton ({children, onClick}) {

  return <S.Button>
    <button className="button medium" onClick={onClick}>{children}</button>
  </S.Button>
  
}

export default InputButton;