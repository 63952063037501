import { Fragment, useState, useCallback } from 'react'
import { Link } from 'react-router-dom';
import NoticeGroup from './NoticeGroup';
import { useAuth } from '../../contexts/AuthContext';
import { useMenuActive } from "../../contexts/MenuContext"
import { useMenu } from '../../contexts/DesktopMenuContext';
import api from '../../services/api';
import logo from '../../assets/img/landing/logo-principal.png';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useModal } from '../../contexts/ModalContext'
import * as S from "./style"

export default function Header () {
    const [activeSettings, setActiveSettings] = useState(false)
    const { activeMenuMobile, setActiveMenuMobile } = useMenuActive();
    const { open, setOpen } = useMenu();
    const [wrapperRef] = useClickOutside(() => setActiveSettings(false));

    const { doLogout } = useAuth();
    const { openModal } = useModal();

    const handleDownloadCetificate = useCallback(async (onda) => {
      try {
        const response = await api.get(`/certificate`, { params: { onda }, responseType: 'arraybuffer' })
        const blob = new Blob([response.data])
        console.log(blob);
        const anchor = document.createElement("a");
        const url = URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = `certificado-onda${onda}.pdf`;
        document.body.appendChild(anchor);
        anchor.click();
        setTimeout(function() {
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);  
        }, 0);
      } catch (err) {
        openModal({
          body: <div className='form-row'>Você não tem certificado para baixar!<br /><br /></div>, title: 'Sinto muito'})
      }
    }, [openModal])

    const openMenu = () => setOpen((open) => !open);
    return (
        <Fragment>
            <header className="header">
                <div className="header-actions">
                    <div className="sidemenu-trigger navigation-widget-trigger" onClick={() => openMenu()}>
                        <svg className="icon-grid">
                            <use href="#svg-grid"></use>
                        </svg>
                    </div>

                    <div className="mobilemenu-trigger navigation-widget-mobile-trigger" onClick={() => setActiveMenuMobile(!activeMenuMobile)}>
                        <div className="burger-icon inverted">
                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>

                            <div className="burger-icon-bar"></div>
                        </div>
                    </div>

                    <div className="header-brand">
                        <h1 className="header-brand-text">&nbsp;</h1>
                    </div>
                </div>

                <div className="header-actions search-bar">
                    <div className="interactive-input dark">
                        <S.LogoContainer className="logo" marginLeft={open ? '80' : '300'}>
                            <S.Logo src={logo} alt="logo" />
                        </S.LogoContainer>

                    </div>
                </div>

                {/* <div className="header-actions">
                    <div className="progress-stat">
                        <div className="bar-progress-wrap">
                            <p className="bar-progress-info">Próximo: <span className="bar-progress-text"></span></p>
                        </div>

                        <div id="logged-user-level" className="progress-stat-bar" style={{ width: "110px", height: "4px", position: "relative" }}>
                            <canvas style={{ position: "absolute", top: "0px", left: "0px" }} width="110" height="4"></canvas>
                            <canvas style={{ position: "absolute", top: "0px", left: "0px" }} width="110" height="4"></canvas>
                        </div>
                    </div>
                </div> */}

                <div className="header-actions">
                    <div className="action-list dark">
                        <NoticeGroup />
                    </div>

                    <div className="action-item-wrap">
                        <div className="action-item dark header-settings-dropdown-trigger" onClick={() => setActiveSettings(!activeSettings)}>
                            <svg className="action-item-icon icon-settings">
                                <use href="#svg-settings"></use>
                            </svg>
                        </div>

                        <S.Dropbox active={activeSettings} className="dropdown-navigation header-settings-dropdown" ref={wrapperRef}>

                            <Link class="dropdown-navigation-link" to="/faq">Perguntas frequentes</Link>
                            <Link class="dropdown-navigation-link" to="/regulamento">Regulamento</Link>
                            <Link class="dropdown-navigation-link" to="/fale-conosco">Fale conosco</Link>
                            <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(1)}>Certificado da onda 1</S.DownloadButton>
                            <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(2)}>Certificado da onda 2</S.DownloadButton>
                            <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(3)}>Certificado da onda 3</S.DownloadButton>
                            <S.DownloadButton class="dropdown-navigation-link" onClick={() => handleDownloadCetificate(4)}>Certificado da onda 4</S.DownloadButton>
                            <Link class="dropdown-navigation-link" to="/perfil">Meu perfil</Link>

                            <S.LeaveButton
                                onClick={doLogout}
                                className="dropdown-navigation-button button small secondary"
                                type="button"
                            >
                              <i class="fas fa-door-closed"></i>
                              <span>Sair</span>
                            </S.LeaveButton>
                        </S.Dropbox>
                    </div>
                </div>
            </header>
        </Fragment>
    )
}