import Template from "../../template"
import CardQuiz from '../../components/CardQuiz';
import { useFetch } from '../../hooks/useFetch';
import PageLoaderComponent from "../../components/PageLoader";
import BannerTitleComponent from '../../components/BannerTitle';
import * as S from './style'

import selo from "../../assets/img/banner/forums-icon.png";

export default function Quiz() {
    const { data } = useFetch('/quiz');

    if(!data) return <PageLoaderComponent />;

    return (
        <Template>
          
          <BannerTitleComponent
            imgUrl={selo}
            title="Jogada extra"
            subTitle="Jogue nosso quiz!"
            imgAlt="selo"
          />
            
          {data.quizzes.length > 0 && <S.Grid>
            {data.quizzes.map(quiz => <CardQuiz template="trofeu" key={quiz.id} quiz={quiz}></CardQuiz>)}
          </S.Grid>}
          {data.quizzes.length === 0 && <p style={{ textAlign: "center", marginTop: '64px', fontSize: '1rem' }}><strong>Em breve</strong></p>}
        </Template>
    )
}