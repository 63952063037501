export const MaskDocument = (v) => {
    v = v.replace(/\D/g, "")

    if (v.length <= 11) {
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d)/, "$1.$2")
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
        v = v.replace(/^(\d{2})(\d)/, "$1.$2")
        v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
        v = v.replace(/\.(\d{3})(\d)/, ".$1/$2")
        v = v.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return v
}

export const MaskPhone = (v) => {
    let r = v.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 11) {
        r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 7) {
        r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
        r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (v.trim() !== "") {
        r = r.replace(/^(\d*)/, "($1");
    }
    return r;
}

export const MaskCEP = (v) => {
    let r = v.replace(/\D/g, "");
    
    r = r.substr(0, 8);
    r = r.replace(/^(\d{5})(\d{1,3})/g, "$1-$2");
    console.log(r);
    
    return r;
}

export const MaskCPF = (v) => {
    let r = v.replace(/\D/g, "");
    
    r = r.replace(/(\d{3})(\d)/, "$1.$2")
    r = r.replace(/(\d{3})(\d)/, "$1.$2")
    r = r.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    
    return r;
}

export const MaskDate = (value) => {
    let date = value.replace(/\D/g, "");

    date = date.replace(/(\d{2})(\d)/, "$1/$2")
    date = date.replace(/(\d{2})(\d{1,4})/, "$1/$2")
    
    return date;
}