import styled from 'styled-components'

export const ContentGrid = styled.div`
  padding: 112px 50px 100px;

  &.large {
    width: calc(100% - 80px);
    transform: translate(40px, 0px);
    transition: all 0.4s ease-in-out 0s;
  }
  
  &.small {
    width: calc(100% - 300px);
    transform: translate(150px, 0px);
    transition: all 0.4s ease-in-out 0s;
  }
  @media (max-width: 1366px){
    padding: 112px 112px 200px;
  }

  @media (max-width: 680px) {
    padding: 92px 0 200px;

    &.small, &.large {
      width: calc(100% - 80px);
      transform: none;
      transition: none;
    }
  }
`

export const ContentGridMecanica = styled.div`
  &.large {
    width: calc(100% - 80px);
    transform: translate(80px, 80px);
    transition: all 0.4s ease-in-out 0s;
  }
  
  &.small {
    width: calc(100% - 300px);
    transform: translate(300px, 80px);
    transition: all 0.4s ease-in-out 0s;
  }

  @media (max-width: 680px) {
    &.small, &.large {
      width: calc(100%);
      transform: none;
      transition: none;
    }
  }
`