import { useState, useCallback, useEffect } from "react";
import axios from 'axios'
import { toast } from 'react-toastify'
import Template from "../../template"
import api from '../../services/api'
import BannerTitleComponent from "../../components/BannerTitle"
import MemberComponent from '../../components/Member'
import PaginationComponent from '../../components/Pagination'
import { useAuth } from "../../contexts/AuthContext";
import Filter from "../../components/Filter"
import BannerTitle from "../../assets/img/banner/members-icon.png"
import * as S from './style'

export default function Members () {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState('')
  const [membersData, setMembersData] = useState(null);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const { doLogout } = useAuth();

  const loadData = useCallback(async (filter) => {
    try {
      setMembersData(null);
      setTotalMembers(0);
      setTotalPages(0);
      setPage(0);
      const response = await api.get('/user', {
        params: filter
      });
      setMembersData(response.data.users);
      setTotalMembers(response.data.totalMembers);
      setTotalPages(response.data.totalPages);
      setPage(response.data.currentPage);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.status === 403) {
          toast.error('O token expirou', { theme: 'colored' })
          doLogout();
        }
      }
    }
  }, [doLogout]);

  const handleSearchByName = useCallback(async () => {
    setFilter((filter) => ({...filter, name: search.value, page: 1}));
    loadData({...filter, name: search.value, page: 1});
  }, [search.value, loadData, filter]);

  const handleSearchByType = useCallback(async (type) => {
    setFilter((filter) => ({...filter, type, page: 1}));
    loadData({...filter, type, page: 1});
  }, [loadData, filter]);

  const handleSearchOrder = useCallback(async () => {
    setFilter((filter) => ({...filter, order: !filter.order, page: 1}));
    loadData({...filter, order: !filter.order, page: 1});
  }, [loadData, filter]);

  const handlePageChange = useCallback(async (page) => {
    setFilter((filter) => ({...filter, page}));
    loadData({...filter, page});
  }, [loadData, filter]);

  useEffect(() => {
    loadData({});
  }, [loadData]);

  return (
    <Template>
      <S.Content>
        <BannerTitleComponent
            imgUrl={BannerTitle}
            title={`Membros (${totalMembers})`}
            subTitle="Todos os membros da comunidade!"
            imgAlt="member-icon"
        />
        
        <Filter onChange={setSearch} onSubmit={handleSearchByName} label="Buscar amigos">
          <S.Tabs className="filter-tabs">
            <div className={`filter-tab ${filter.type === 'ativo' ? 'active' : ''}`} onClick={()=> handleSearchByType('ativo')}>
              <p className="filter-tab-text">Ativos</p>
            </div>

            <div className={`filter-tab ${filter.type === 'novo' ? 'active' : ''}`} onClick={()=> handleSearchByType('novo')}>
              <p className="filter-tab-text">Novos usuários</p>
            </div>

            <div className={`filter-tab ${filter.order ? 'active' : ''}`} onClick={()=> handleSearchOrder()}>
              <p className="filter-tab-text">Alfabético</p>
            </div>
          </S.Tabs>
        </Filter>

        {membersData && <div className="grid grid-4-4-4 centered">
          {membersData.map(member => <MemberComponent member={member} />)}
        </div>}

        <PaginationComponent currentPage={page} totalPages={totalPages} onChagePage={handlePageChange} />

        {membersData && <p className="section-results-text">Mostrar {membersData.length} de {totalMembers} membros</p>}
      </S.Content>
    </Template>
  )
}