import { useState, useEffect, useCallback } from "react";
import axios from 'axios';
import { toast } from 'react-toastify';
import api from "../../services/api";
import PageLoader from '../../components/PageLoader';
import CardWidget from '../../components/CardWidget';
import UserStatus from "../../components/UserStatus";
import * as S from './style';

// Template
import Template from "../../template"

// Components
import BannerTitleComponent from "../../components/BannerTitle"

// Image
import BannerTitle from "../../assets/img/banner/newsfeed-icon.png"

export default function NewsfeedHistory() {
    const [ notices, setNotices ] = useState();

    const loadNotices = useCallback(async () => {
      try {
        const response = await api.get(`/notice/history`);
        setNotices(response.data.messages)
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' });
          return;
        }

        console.log(err.response.data);
        toast.error('Ocorreu um erro interno no servidor', { theme: 'colored' });
      }
    }, [setNotices]);

    useEffect(() => {
      loadNotices();
    }, [loadNotices]);
    
    if (!notices) return <PageLoader />

    return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Histórico de notícias"
                subTitle="Todas as notícias do seu canal aqui!"
                imgAlt="newsfeed-icon"
            />

            <div className="grid mobile-prefer-content">
                <div className="grid-column">
                    {notices.length > 0 && notices.map(notice =>
                        <CardWidget status={<UserStatus user={notice.sender} createdAt={notice.createdAt} />} message={<div dangerouslySetInnerHTML={{ __html: notice.message }}></div>}></CardWidget>
                    )}

                    {notices.length === 0 && <S.Center>Você ainda não tem nenhuma mensagem</S.Center>}
                </div>
            </div>


        </Template >
    )
}