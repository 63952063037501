import { useEffect, useMemo } from "react";
import * as S from './style';

export default function Modal ({showModal, onClose, title, children, size}) {
  const modalSize = useMemo (() => ['mid', 'small'].includes(size) ? size : 'small', [size]);

  useEffect (() => {
    if (showModal) {
      window.scrollTo(0, 0);
    }
  }, [showModal]);
  return (
    <S.ModalContainer className={showModal && 'visible'}>
      <div className="overlay" onClick={onClose}></div>
      <S.Modal className={`popup-box ${modalSize} popup-event-creation`}>
        <div className="popup-close-button popup-event-creation-trigger" onClick={onClose}>
          <svg className="popup-close-button-icon icon-cross">
            <use href="#svg-cross"></use>
          </svg>
        </div>

        <p className="popup-box-title">{title}</p>

        {children}
      </S.Modal>
    </S.ModalContainer>
  )
}