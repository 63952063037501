import React, { useContext, useState } from "react";

const MenuContext = React.createContext();

export default function MenuProvider ({ children }) {
    const [activeMenuMobile, setActiveMenuMobile] = useState(true);

    return (
        <MenuContext.Provider value={{ activeMenuMobile, setActiveMenuMobile }}>
            {children}
        </MenuContext.Provider>
    );
};

export function useMenuActive () {
    const context = useContext(MenuContext)

    if (!context) throw new Error("useCount must be used within a MenuProvider");

    const { activeMenuMobile, setActiveMenuMobile } = context;

    return { activeMenuMobile, setActiveMenuMobile };
}
