import React from "react";
import { Link } from "react-router-dom"
import * as S from './style';

export default function NotFound() {
    return (
      <S.Content>
        <div className="error-section">
            <p className="error-section-title">404</p>

            <div className="error-section-info">

                <p className="error-section-subtitle"><span>404<br /></span>Oops!!...</p>

                <p className="error-section-text">Caso o erro continue, por favor, fale com nosso suporte<br />
                    <a href="mailto:contato@gigantesdaexecucao2022.com">contato@gigantesdaexecucao2022.com</a>
                </p>

                <Link className="button medium primary" to="/">Voltar ao início</Link>
            </div>
        </div>
      </S.Content>
    )
}