import React from 'react';
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import BannerTitleComponent from '../../components/BannerTitle';
import Dropzone from '../../components/Dropzone';
import Template from '../../template';

function AdminUpload ({ type }) {
  // Endpoints enabled at backend
  let enableType = {
    results: {
      text: 'Resultados',
      url: '/results',
    },
    ranking: {
      text: 'Ranking',
      url: '/rank-results'
    }
  };
  
  return (
    <Template>
      <BannerTitleComponent
      imgUrl={AccountHubIcon}
      title="Account Hub"
      subTitle="Info de perfil, mensagens, configuração e muito mais!"
      imgAlt="accounthub-icon"
      />
      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Upload {enableType[type].text}</p>

              <h2 className="section-title">Envie uma planilha para atualizar</h2>
            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <p className="widget-box-title">Upload {enableType[type].text}</p>
              <div className="widget-box-content">
                <Dropzone apiEndpoint={enableType[type].url}/>              
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
    )
  }
  
  export default AdminUpload;