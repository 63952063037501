import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";

export default function CartItem(props) {
  const [amount, setAmount] = useState(0);
  const { onRemove, onUpdateAmount } = props;
  const skuId = props.sku.codigo;
  const { lojista, fotoMedia } = props.sku.produto;
  const handleIncreaseAmount = async () => {
    await api.patch(`/cart/${skuId}/${amount + 1}`);
    onUpdateAmount(amount + 1);
    setAmount(amount + 1);
  }
  const handleDecreaseAmount = async () => {
    await api.patch(`/cart/${skuId}/${amount - 1}`);
    onUpdateAmount(amount - 1);
    setAmount(amount - 1);
  }
  const handleRemoveFromCart = async () => {
    try {
      await api.patch(`/cart/${skuId}/0`);
      if (onRemove) onRemove();
    } catch (e) {

    }
  }
  const descricaoLonga = useMemo(() => {
    return props.sku.produto.descricaoLonga.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
  }, [props.sku.produto.descricaoLonga])
  const id = useMemo(() => {
    return props.sku.produto.codigo;
  }, [props.sku.produto.codigo]);
  useEffect(() => {
    setAmount(props.amount);
  }, [props]);
  return (<div className="table-row medium">
    <div className="table-column">
      <div className="product-preview tiny" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Link to={`/product/${id}`}>
          <figure className="product-preview-image liquid" style={{ background: `url('https://www.${lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=${fotoMedia}') center center / cover no-repeat` }}>
            <img src={`https://www.${lojista}-imagens.com.br/Control/ArquivoExibir.aspx?IdArquivo=${fotoMedia}`} alt="item-01" style={{ display: 'none' }} />
          </figure>
        </Link>

        <div className="product-preview-info">
          <p className="product-preview-title"><Link to={`/product/${id}`}>{props.sku.produto.displayName}</Link></p>

          <p className="product-preview-category digital"><Link to={`/product/${id}`} dangerouslySetInnerHTML={{ __html: descricaoLonga }}></Link></p>
        </div>
      </div>
    </div>

    <div className="table-column padded-left">
      <div className="form-counter">
        <p className="form-counter-value">{amount}</p>

        <div className="form-counter-controls">
          <div className="form-counter-control form-counter-control-increase" onClick={handleIncreaseAmount}>
            <svg className="form-counter-icon icon-small-arrow">
              <use href="#svg-small-arrow"></use>
            </svg>
          </div>

          <div className="form-counter-control form-counter-control-decrease" onClick={handleDecreaseAmount}>
            <svg className="form-counter-icon icon-small-arrow">
              <use href="#svg-small-arrow"></use>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div className="table-column centered padded-left">
      <p className="price-title"><span className="currency"></span> {(props.sku.preco / 100).toFixed(2).replace(/\./, ',')} Moedas</p>
    </div>

    <div className="table-column padded-big-left">
      <div className="table-action">
        <svg className="icon-delete" onClick={handleRemoveFromCart}>
          <use href="#svg-delete"></use>
        </svg>
      </div>
    </div>
  </div>)
}