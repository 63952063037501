import { useCallback, useEffect, useState, useMemo } from 'react';
import { isAxiosError } from 'axios';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import Template from '../../template';
import InputSelect from '../../components/Forms/InputSelect';
import PageLoader from '../../components/PageLoader';
import BannerTitleComponent from '../../components/BannerTitle';
import RankingGeral from '../../components/RankingGeral';
import RankingSTAFF from '../../components/RankingGeral/RankingSTAFF';
import Icon from '../../assets/img/banner/overview-icon.png';
import { useModal } from '../../contexts/ModalContext';
import * as S from './style'

function Ranking () {
  const [ranking, setRanking] = useState();
  const [onda, setOnda] = useState();
  const [canal, setCanal] = useState(null);
  const [channelName, setChannelName] = useState(null);
  const [cargo, setCargo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { user, doLogout } = useAuth();
  const { openModal } = useModal()

  const loadPageData = useCallback(async () => {
    setIsLoading(true)
    try {
      const results = await api.get(`/ranking/${onda || 1}`);
      setRanking(results.data || []);
      setIsLoading(false)
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
        }
      } else {
        doLogout();
      }
    }
  }, [doLogout, onda]);

  const updateOnda = useCallback(async (onda) => {
    setOnda(onda);
    setCanal();
    const channel = channelName ? channelName : user.canal;
    try {
      const results = await api.get(`/ranking/${channel}/${onda || 1}`);
      setCanal(results.data);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
        }
      }
    }
  }, [doLogout, channelName, user]);

  const updateCanal = useCallback(async (canal) => {
    setChannelName(canal);
    setCanal();
    try {
      const results = await api.get(`/ranking/${canal}/${onda || 1}`, {
        params: {
          onda: onda || 1
        }
      });
      setCanal(results.data);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
        }
      }
    }
  }, [doLogout, onda]);

  const gr_name = useMemo(() => {
    if (!user) return false;
    const canalReal = channelName || user.canal
    const isMerchan = canalReal === 'MERCHANDISING'
    return isMerchan ? 'Gerente ' : 'GR';
  }, [channelName, user]);

  const ga_name = useMemo(() => {
    if (!user) return false;
    const canalReal = channelName || user.canal
    const isMerchan = canalReal === 'MERCHANDISING'
    return isMerchan ?  'Supervisor' : 'GA';
  }, [channelName, user]);

  const ex_name = useMemo(() => {
    if (!user) return false;
    const canalReal = channelName || user.canal
    const isMerchan = canalReal === 'MERCHANDISING'
    return isMerchan ?  'Coordenador' : 'Executivo';
  }, [channelName, user]);
  const promotor_name = useMemo(() => {
    return 'Promotor';
  }, []);

  const canSeeGR = useMemo(() => {
    if (!ranking) return false;
    if (!user) return false;
    const canalReal = channelName || user.canal
    const isMerchan = canalReal === 'MERCHANDISING'
    const canSee = user.canal === 'STAFF' || ranking.meu_cargo === 'GR'
    return isMerchan && canSee
  }, [channelName, user, ranking])

  const canSeeGA = useMemo(() => {
    if (!ranking) return false;
    if (!user) return false;
    if (onda > 1 && user.canal === 'MERCHANDISING') return false;
    const canSee = user.canal === 'STAFF' || ['GR', 'GA'].includes(ranking.meu_cargo)
    return canSee
  }, [user, ranking, onda])
  const canSeeEx = useMemo(() => {
    if (!ranking) return false;
    if (!user) return false;
    const canSee = user.canal === 'STAFF' || ['GR', 'GA', 'EX'].includes(ranking.meu_cargo)
    return canSee
  }, [user, ranking])
  const canSeePromotor = useMemo(() => {
    if (!ranking) return false;
    if (!user) return false;
    const canalReal = channelName || user.canal
    const isMerchan = canalReal === 'MERCHANDISING'
    return isMerchan
  }, [channelName, user, ranking])

  const isOwner = useMemo(() => {
    if (!ranking) return false
    const canalReal = channelName || user.canal
    if (canalReal === 'MERCHANDISING') return false
    if (ranking.meu_cargo === 'GR') return true
    if (ranking.meu_cargo === 'GA' && cargo === 'executivo') return true
    return false
  }, [ranking, cargo, channelName, user])

  useEffect(() => {
    loadPageData();
  }, [loadPageData, openModal]);

  if (!ranking || !user) return <PageLoader />
  return <Template>
    
    <BannerTitleComponent
      imgUrl={Icon}
      title="Ranking"
      subTitle=""
      imgAlt="accounthub-icon"
    />

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle">Resultados</p>
  
        <h2 className="section-title">Ranking</h2>
      </div>
    </div>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">

            {user.canal === "STAFF" && <>
              <S.Filtros>
              <InputSelect label="Selecione uma onda" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Onda 1</option>
                  <option value="2">Onda 2</option>
                  <option value="3">Onda 3</option>
                  <option value="4">Onda 4</option>
                </InputSelect>
                <InputSelect label="Selecione um Canal" onChange={(evt) => updateCanal(evt)} large={true}>
                  <option value="">Selecione um Canal</option>
                  <option value="VJ">VJ</option>
                  <option value="GKA">GKA</option>
                  <option value="LMT">LMT</option>
                  <option value="FOOD+DIA+FARMA">FOOD+DIA+FARMA</option>
                  <option value="MERCHANDISING">Merchan</option>
                  <option value="C&C">C&C</option>
                  <option value="TD">TD</option>
                  <option value="HIB">HIB</option>
                  <option value="ATC">ATC</option>
                </InputSelect>
                <InputSelect label="Selecione o cargo" onChange={(evt) => setCargo(evt)} large={true}>
                  <option value="">Selecione o cargo</option>
                  {canSeeGR && <option value="gerente_regional">{gr_name}</option>}
                  {canSeeGA && <option value="gerente_de_area">{ga_name}</option>}
                  {canSeeEx && <option value="executivo">{ex_name}</option>}
                  {canSeePromotor && <option value="promotor">{promotor_name}</option>}
                </InputSelect>
              </S.Filtros>
              {canal && !isLoading && <RankingSTAFF onda={onda} isOwner={true} ranking={cargo ? canal[cargo].ranking : []} tipo={cargo} canal={channelName} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
            </>}
            {user.canal !== "STAFF" && 
            <>
              <S.FiltrosCargo>
              <InputSelect label="Selecione uma onda" onChange={(evt) => updateOnda(evt)} large={true}>
                  <option value="1">Onda 1</option>
                  <option value="2">Onda 2</option>
                  <option value="3">Onda 3</option>
                  <option value="4">Onda 4</option>
                </InputSelect>
                <InputSelect label="Selecione o cargo" onChange={(evt) => setCargo(evt)} large={true}>
                  <option value="">Selecione o cargo</option>
                  {canSeeGR && <option value="gerente_regional">{gr_name}</option>}
                  {canSeeGA && <option value="gerente_de_area">{ga_name}</option>}
                  {canSeeEx && <option value="executivo">{ex_name}</option>}
                  {canSeePromotor && <option value="promotor">{promotor_name}</option>}
                </InputSelect>
              </S.FiltrosCargo>
              {!isLoading && <RankingGeral onda={onda} isOwner={isOwner} ranking={cargo ? ranking[cargo].ranking : []} myPosition={cargo ? ranking[cargo].my_position : undefined} tipo={cargo} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
                </>}
            
          </div>

        </div>
      </div>
    </div>

    <S.FooterTable>
      <table>
      <thead>
        <tr><td colspan="11">Critérios de desempate VENDAS</td></tr>
        <tr>
          <td>Canal</td>
          <td style={{ minWidth: '100px' }}>1º</td>
          <td style={{ minWidth: '100px' }}>2º</td>
          <td style={{ minWidth: '100px' }}>3º</td>
          <td style={{ minWidth: '100px' }}>4º</td>
          <td style={{ minWidth: '100px' }}>5º</td>
          <td style={{ minWidth: '100px' }}>6º</td>
          <td style={{ minWidth: '100px' }}>7º</td>
          <td style={{ minWidth: '100px' }}>8º</td>
          <td style={{ minWidth: '100px' }}>9º</td>
          <td style={{ minWidth: '100px' }}>10º</td>
        </tr>
      </thead>
      <tbody>
      <tr>
        <td>GKA</td>
        <td>ESTRELAS</td>
        <td>MSL BOS TANG</td>
        <td>MSL BOS LACTA</td>
        <td>MSL BOS MDLZ</td>
        <td>MSL HZ MDLZ</td>
        <td>PE TANG</td>
        <td>PE LACTA</td>
        <td>PE MDLZ</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>LMT</td>
        <td>ESTRELAS</td>
        <td>MSL BOS TANG</td>
        <td>MSL BOS LACTA</td>
        <td>MSL BOS MDLZ</td>
        <td>MSL HZ MDLZ</td>
        <td>PE TANG</td>
        <td>PE LACTA</td>
        <td>PE MDLZ</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>C&C</td>
        <td>ESTRELAS</td>
        <td>MSL BOS TANG</td>
        <td>MSL BOS LACTA</td>
        <td>MSL BOS MDLZ</td>
        <td>MSL HZ MDLZ</td>
        <td>PE TANG</td>
        <td>PE LACTA</td>
        <td>PE MDLZ</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>DIST TD</td>
        <td>ESTRELAS</td>
        <td>POS BIS XTRA</td>
        <td>POS  MDLZ</td>
        <td>SKU POR PDV</td>
        <td>MSL BOS 5+ LACTA</td>
        <td>MSL BOS 1a4 LACTA</td>
        <td>MSL BOS 5+ MDLZ</td>
        <td>MSL BOS 1a4 MDLZ</td>
        <td>MSL HZ 5+ MDLZ</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>DIST HIB</td>
        <td>ESTRELAS</td>
        <td>POS BIS XTRA</td>
        <td>POS MDLZ</td>
        <td>SKU POR PDV</td>
        <td>MSL BOS 5+ LACTA</td>
        <td>MSL BOS 1a4 LACTA</td>
        <td>MSL BOS 5+ MDLZ</td>
        <td>MSL BOS 1a4 MDLZ</td>
        <td>MSL HZ 5+ MDLZ</td>
        <td>MSL HZ 1Aa MDLZ</td>
      </tr>
      <tr>
        <td>DIST VJ</td>
        <td>ESTRELAS</td>
        <td>POS BIS XTRA</td>
        <td>POS MDLZ</td>
        <td>SKU POR PDV</td>
        <td>POS TRID+HALLS</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>ATC</td>
        <td>ESTRELAS</td>
        <td>POS BIS XTRA</td>
        <td>POS MDLZ</td>
        <td>POS TRID+HALLS</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr>
        <td>FOOD+FARMA+DIA</td>
        <td>ESTRELAS</td>
        <td>SELL IN TOTAL MONDELEZ</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      </tbody>
      <tfoot>
        <tr>
          <td className='head' colspan="11">Critérios de desempate MERCHAN * Segue o mesmo do LMT</td>
        </tr>
        <tr>
          <td colspan="11">* Apenas para quem atende unicamente LASA, PE Lacta passa a ser o 6º critério, pois não trabalha com Tang</td>
        </tr>
      </tfoot>
      </table>
    </S.FooterTable>
  </Template>
}

export default Ranking;
