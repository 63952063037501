import styled from 'styled-components'
import ovos from '../../../assets/mecanicas/ovos.png'

export const Pascoa = styled.div`
  background-attachment: fixed;
  background-size: cover;
`;

export const Container = styled.div`
  background-image: url(${ovos});
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: right -10% top;

  @media (max-width: 1366px) {
    background-position: right -10% top 30%;
  }

  @media (max-width: 1024px) {
    background-image: none;
  }
`;

export const ImageMobileOnce = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;

  @media (min-width: 767px) {
    display: none;
  }
`;

export const Title = styled.div`
  position: relative;
  z-index: 99;
  font-size: 80px;
  
  h1{
    color: #fff;
    font-family: impact;
    margin-bottom: 10px;

    @media (max-width: 425px) {
      font-size: 42px;
    }
  }

  span{
    color: #fec200;
  }
`

export const Formas = styled.div`
  position: relative;
  left: 90px;
  width: 100%;
  max-width: 600px;
  translate: scale(50%);

  img {
    width: 100%;
    max-width: 600px;
    position: relative;
    bottom: 90px;
  }

  p {
    color: #fff;
    font-size: 40px;
    margin-bottom: 10px;
    font-family: Arial;
    font-weight: bold;
    left: 60px;
    position: absolute;
    top: -30px;

    @media (max-width: 425px) {
      top: -55px;
      left: 40px;
      font-size: 24px;
    }
  }

  .vantagens {
    position: absolute;
    text-align: center;
    top: 15%;
    left: 27%;
    
    h4 {
      color: #f19d0b;
      font-size: 60px;
      font-family: Arial;
      font-weight: bold;
    }

    h5 {
      color: #681991;
      font-size: 24px;
      font-family: Arial;
      font-weight: bold;
    }

    h1 {
      color: #681991;
      font-size: 70px;
      font-family: impact;
      margin: 35px 0;
    }

    h2 {
      color: #000;
      font-size: 50px;
      font-family: Arial;
      margin-bottom: 15px;
    }

    h3 {
      color: #f19d0b;
      font-size: 40px;
      font-family: impact;
    }

    @media (max-width: 425px) {
      left: 18%;
      top: 4%;

      h4 {
        font-size: 20px;
      }

      h5 {
        font-size: 18px;
      }

      h1 {
        margin-top: 10px;
        font-size: 26px;
        margin-bottom: 10px;
      }

      h2 {
        font-size: 21px;
      }

      h3 {
        font-size: 16px;
      }

    }
  }
`;

export const SubTitle = styled.div`
  width: 100%;
  height: auto;
  max-width: 500px;
  margin-top: 30px;
  position: relative;
  z-index: 999;
  margin-bottom: 45px;
  
  .amarelo {
    background-color: #fec200;
    border-radius: 50px;
    text-align: center;
  }

  h2 {
    font-size: 55px;
    color: #681991;
    font-family: impact;
    margin-top: 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
    position: relative;
    z-index: 99;
    transform: scale(80%) translateX(-13%);

    @media (max-width: 900px) {
      .site h1 {
        font-size: 22px;
      }
    }

    @media (max-width: 855px) {
      .size {
        bottom: 10%;
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
`;