import * as S from './style';

function ProgressBar(props) {
  
  return (
    <S.Progress {...props} />
  );
}

export default ProgressBar;
