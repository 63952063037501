import React from "react";
import moldura from '../../assets/img/avatar/moldura.png';

// Styles
import * as S from "./style"

export default function HexagonComponent ({src, size}) {
    return  <S.Hexagon size={size}>
          <S.HexagonOverlay src={moldura} />
          <div className="shape">
            <img src={src} alt="Avatar" />
          </div>
    </S.Hexagon>
}