import styled, { css } from 'styled-components';

const sizes = {
  small: css`
    height: 6px;
    border-radius: 3px;
  `,
  large: css`
    height: 12px;
    border-radius: 6px;
  `
}

export const Progress = styled.div`
  width: 100%;
  ${({size}) => sizes[size || 'small']}
  background-color: #e7e8ee;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: block;
    border-radius: 3px;
    width: ${({current, max}) => current*100/max}%;
    content: "";
    background: rgb(97,104,246);
    background: linear-gradient(90deg, rgba(97,104,246,1) 0%, rgba(79,208,252,1) 100%);
    transition: width 2s ease;
  }
`