import * as S from './style'
import Template from '../../../template';
import fundo from '../../../assets/mecanicas/fundo-pascoa.jpg';
import pascoaImage from '../../../assets/img/pascoa-merchan.png'
import logoGigantes from '../../../assets/mecanicas/logo-principal.png'
import '../style.css'

function MecanicasPascoaMerchan () {
  return <S.Pascoa className="pascoa" style={{backgroundImage: `url(${fundo})`}}><Template>
    <S.Container>

    <div className="gigantes">
      <img src={logoGigantes} alt="Logo gigantes da execução 2022" />
    </div>

    <S.Title>
      <h1>MECÂNICA <span>PÁSCOA</span></h1>
    </S.Title>

    <S.SubTitle>
      <div className="amarelo">
        <h2>Bateu Levou</h2>
      </div>
    </S.SubTitle>

    <S.Image src={pascoaImage} alt="" className="mobile-once" />

  </S.Container>
</Template></S.Pascoa>
}

export default MecanicasPascoaMerchan;
