import Template from "../../template";
import ProgressBar from "../../components/ProgressBar";
import HexagonComponent from "../../components/Hexagon";
import { VictoryPie, VictoryLabel } from 'victory';
import * as S from './style';
import { useCallback } from "react";

function Regulamento () {
  const progress = 30;
  const progress2 = 20;
  const progress3 = 40;
  const progress4 = 10;

  const color1 = 'rgba(97,104,246,1)';
  const color2 = '#000';
  const color3 = '#c00';
  const color4 = '#cc0'

  const calculateProgressBar = useCallback(({datum}) => {
    if (datum.x === 1) return 'rgba(97,104,246,1)';
    if (datum.x === 2) return '#000';
    if (datum.x === 3) return '#c00';
    if (datum.x === 4) return '#cc0';
    return 'transparent'
  },[]);
  

  return <Template>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
  
        <h2 className="section-title">&nbsp;</h2>
      </div>
    </div>

    <S.Grid>
      <div className="widget-box">
        <p style={{fontSize: '24px', padding: '2% 0%'}} className="widget-box-title">Top Friends Activity</p>

        <S.Tabela>
          <table>
            <tr>
              <th>FRIEND</th>
              <th></th>
              <th>REACTIONS</th>
              <th>COMMENTS</th>
              <th>SHARES</th>
              <th>REPLIES</th>
              <th>POST ENGAGEMENT</th>
            </tr>
            <tr>
              <td><HexagonComponent size="large" /></td>
              <td>Nick Grisson<br /><span>Friended: Jan 14, 2017</span></td>
              <td>1569</td>
              <td>750</td>
              <td>109</td>
              <td>223</td>
              <td><ProgressBar current={88} max={100} /></td>
              <td>&nbsp;88%</td>
            </tr>
          </table>
        </S.Tabela>
      </div>

      <div className="widget-box">
        <p style={{fontSize: '20px', padding: '2% 0%'}} className="widget-box-title">Engagements</p>

        <svg viewBox="0 0 400 400" width={"100%"}>
          <VictoryPie
              standalone={false}
              width={400} height={400}
              data={[{ x: 1, y: progress }, { x: 2, y: progress2 }, { x: 3, y: progress3 }, { x: 4, y: progress4 }]}
              innerRadius={120}
              cornerRadius={0}
              labels={() => null}
              style={{data: { fill: calculateProgressBar  }}}
          />
          <VictoryLabel
              textAnchor="middle" verticalAnchor="middle"
              x={200} y={200}
              text={`${Math.round(0)}%`}
              style={{ fontSize: 75, fontWeight: 'bold' }}
            />
        </svg>

        <S.Engagements>
          <S.Nubers color={color1}>
            <div className="colors"></div>
            <h3>{progress}</h3>
            <p>reactions</p>
          </S.Nubers>

          <S.Nubers color={color2}>
            <div className="colors bar2"></div>
            <h3>{progress2}</h3>
            <p>comments</p>
          </S.Nubers>

          <S.Nubers color={color3}>
            <div className="colors bar3"></div>
            <h3>{progress3}</h3>
            <p>shares</p>
          </S.Nubers>
          
          <S.Nubers color={color4}>
            <div className="colors bar4"></div>
            <h3>{progress4}</h3>
            <p>replies</p>
          </S.Nubers>
        </S.Engagements>

      </div>

    </S.Grid>
  </Template>
}

export default Regulamento;
