import styled from 'styled-components';
import { Link as StyledLink } from "react-router-dom";

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
`;

export const Tabela = styled.div`
  table{
    width: 100%;
  }
  th{
    font-weight: 100;
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    font-weight: bold;
    font-size: 1rem;
  }

  @media (max-width: 1178px) {
    .avatar {
      display: none;
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 8fr 2fr;
`;

export const Engagements = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 5fr 5fr;
`;

export const Nubers = styled.div`
text-align: center;
  .colors{
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: ${({color})=>color};
  }
  p{
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    text-transform: uppercase;
  }
`;

export const Link = styled(StyledLink)`

`

export const Pergunta = styled.div`
  text-align: center;
  h4{
    margin-bottom: 20px;
  }
  button{
    border-radius: 0;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }
  a{
    font-family: Rajdhani,sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }   
`

export const Star = styled.img`
  max-width: 50px;
  width: auto;
  display: block;
  margin: 0;
`

export const StarCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-light);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  img {
    margin-left: 16px;
  }

  @media (max-width: 681px) {
    font-size: 1rem;
    img { max-width: 20px; }
  }
`;

export const PositionColumn = styled.td`
  @media (max-width: 681px) {
    img {
      display: none;
    }
    
    div {
      color: #000;
    }
  }
`
export const Name = styled.td`
  font-size: 1rem;

  @media (max-width: 681px) {
    font-size: .75rem;
  }
`