import { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useAuth } from '../../contexts/AuthContext';
import api from "../../services/api";
import Template from "../../template"
import AccountHubIcon from '../../assets/img/banner/accounthub-icon.png';
import PageLoaderComponent from "../../components/PageLoader";
import InputText from '../../components/Forms/InputText';
import InputSelect from '../../components/Forms/InputSelect';
import { MaskCPF, MaskPhone } from "../../utils/mask";
import * as S from './style';

// Component
import BannerTitleComponent from "../../components/BannerTitle"

export default function AddNewUser (props) {
  const {doLogout} = useAuth();
  const [user, setUser] = useState({});
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true,
  });

  const handleUserCreate = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true,
    })

    try {
      await api.post(`/user`, {...user});
      toast.success('Os dados foram salvos', { theme: "colored" });
    } catch (e) {
      if (!e.response.data.message) {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: "colored" });
        return;
      }

      if (e.response.data.message === 'Token inválido') {
        doLogout();
        toast.error(e.response.data.message, { theme: "colored" });
        return;
      }

      toast.error(e.response.data.message, { theme: "colored" });
    }
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [user, doLogout]);

  useEffect(() => {
    setButton({
      name: 'Salvar dados',
      isDisabled: false,
    })
  }, [])

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={AccountHubIcon}
        title="Perfil"
        subTitle="Info de perfil e muito mais!"
        imgAlt="accounthub-icon"
      />

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Novo usuário</p>

              <h2 className="section-title">Informação de usuário</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre o novo usuário</p>

              <div className="widget-box-content">
                <form className="form" action="" method="POST" onSubmit={handleUserCreate}>
                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome Completo" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} required />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CPF" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskCPF(value) })}  maxLength="14" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                    <div className="form-item">
                      <InputSelect name="canal" id="canal" label="Canal" onChange={(value) => setUser({...user, canal: value})} large={true}>
                        <option value="">Selecione uma opção</option>
                        <option value="VJ">VJ</option>
                        <option value="C&C">C&C</option>
                        <option value="Merchandising">Merchandising</option>
                        <option value="GKA">GKA</option>
                        <option value="LMT">LMT</option>
                        <option value="FOOD+DIA+FARMA">FOOD+DIA+FARMA</option>
                        <option value="SAC">SAC</option>
                        <option value="XX">XX</option>
                        <option value="ATC">ATC</option>
                        <option value="PRD">PRD</option>
                        <option value="DIS TD">DIS TD</option>
                        <option value="COM">COM</option>
                        <option value="DIST HIB">DIST HIB</option>
                        <option value="HIBRIDO">HIBRIDO</option>
                        <option value="DIST TD">DIST TD</option>
                      </InputSelect>
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="matricula" label="Matrícula" id="matricula" value={user.matricula} onChange={(value) => setUser({ ...user, matricula: value })} />
                    </div>
                    <div className="form-item">
                      <InputSelect name="equipe" id="equipe" label="Equipe" onChange={(value) => setUser({...user, equipe: value})} large={true}>
                        <option value="">Selecione uma opção</option>
                        <option value="MERCHAN">MERCHAN</option>
                        <option value="COMERCIAL">COMERCIAL</option>
                      </InputSelect>
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <S.SubmitButton type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>


          </div>
        </div>
      </div>
    </Template >
  )
}