import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import HexagonComponent from '../../components/Hexagon';
import RankingImage from '../../components/RankingImage';
import star from '../../assets/img/banner/star.png';
import * as S from './style';

const Row = ({rowData, isOwner, isRankingVisible}) => {
  const name = useMemo(() => {
    if (!isOwner) return rowData.name.toUpperCase()
    return <Link to={`/resultados/${rowData.id}`}>{rowData.name.toUpperCase()}</Link>
  }, [rowData.name, rowData.id, isOwner])
  return <tr>
    {isRankingVisible && <td><RankingImage position={rowData.rank} /></td>}
    <td style={{padding: '16px'}} className="avatar"><HexagonComponent size="large" src={`${process.env.REACT_APP_IMG_URL}${rowData.path}`} /></td>
    <td style={{fontSize: '1rem'}}>
      {name}
    </td>
    <td>
      <S.StarCell>
        {rowData.estrelas}
        <S.Star src={star} alt="Estrela" />
      </S.StarCell>
    </td>
  </tr>
}


function RankingGeral ({ranking, myPosition, canal, tipo, isOwner, onda}) {
  const isRankingVisible = useMemo(() => {
    if (onda !== '5') return true
    return false
  }, [onda]);
  const nomeTipoRanking = useMemo(() => {
    switch(tipo) {
      case 'promotor':
        return 'Promotor';
      case 'gerente_de_area':
        return canal === 'MERCHANDISING' ? 'Supervisor' : 'GA';
      case 'gerente_regional':
        return canal === 'MERCHANDISING' ? 'Gerente' : 'GR';
      case 'executivo':
        return canal === 'MERCHANDISING' ? 'Coordenador' : 'Executivo';
      default:
        return '';
    }
  }, [tipo, canal])
  return <div className="widget-box-content">
    <S.Pergunta>
      <h4>Ranking {canal} {nomeTipoRanking}</h4>
      <p style={{fontSize: '.75rem', fontWeight: 'bold'}}>Ranking aplicado de acordo com o critério de desempate</p>

      <div className="widget-box">
        <S.Tabela>
          <table>
            {!isRankingVisible && <tr>
              <td colSpan={4} style={{fontSize: '1rem', padding: '32px 0', fontWeight: 'bold'}}>CURIOSO PARA SABER QUEM SERÃO OS GANHADORES DA ONDA 4?<br />Aguarde a live do dia 27 de Fevereiro</td>
            </tr>}
            {(isRankingVisible || isOwner) && <tr>
              <th>Posição no ranking</th>
              <th></th>
              <th className="avatar"></th>
              <th>Estrelas</th>
            </tr>}

            {(isRankingVisible || isOwner) && ranking.map(r => <Row isOwner={isOwner} rowData={r} isRankingVisible={isRankingVisible} />)}

            {isRankingVisible && <tr>
              <td colSpan={4}>&nbsp;</td>
            </tr>}

            <tr>
              <td colSpan={4} style={{fontSize: '1rem', borderTop: '1px solid #ccc', padding: '16px'}}>
                Sua posição
              </td>
            </tr>
            {myPosition && <Row isOwner={true} rowData={myPosition} isRankingVisible={isRankingVisible} />}
          </table>
        </S.Tabela>
      </div>
    </S.Pergunta>
  </div>
}

export default RankingGeral;
