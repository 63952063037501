import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory, Link } from "react-router-dom"
import { useAuth } from '../../contexts/AuthContext';

//imagens
import Logo from "../../assets/img/landing/logo-principal.png"

// Services
import { api } from '../../services/api';

// Styles
import { ContainerLogin, ContainerRegister, Principal } from "./style"

// Component
import Modal from "../../components/Modal"

// utils
import { MaskDocument, MaskPhone } from "../../utils/mask"

export default function Login () {
    const [email, setEmail] = useState('');
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [document, setDocument] = useState('');
    const [passwordType, setPasswordType] = useState('password');
    const [cellphone, setCellphone] = useState('');
    const [loginOrRegister, setLoginOrRegister] = useState('login');
    const [modal, setModal] = useState(false);
    const history = useHistory();
    const { doLogin } = useAuth();

    async function handleLogin (event) {
        event.preventDefault();
        try {
            const {data} = await doLogin(email, password);
            const { activationToken } = data.user;
            if(!activationToken) {
                history.push("/home");
                return;
            }

            history.push(`/activation/${activationToken}`);

        } catch (err) {
          if(err.response) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
            return;
          }

          toast.error('Ocorreu um erro no servidor', {
            theme: "colored"
          });
        }
    }

    async function handleRegister (event) {
        event.preventDefault();
        try {
            await api.post('register', {
                name: user,
                document: document,
                email: email,
                cellphone: cellphone,
                password: password,
                companyId: "0f904b99-ff70-4deb-ba7f-d2e3286ae9db"
            })

            setEmail('');
            setUser('');
            setPassword('');
            setCellphone('');
            setDocument('');
            setLoginOrRegister('login');

            toast.success('Verifique seu email para finalizar o seu cadastro!', {
                theme: "colored"
            });

        } catch (err) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
        }
    }

    async function handleRedefinePassword (event) {
        event.preventDefault();
        try {
            const result = await api.post('session/recovery', {
                email: email,
            })
            toast.success(result.data.message, {
                theme: "colored"
            });

            setEmail('')
            setModal(false);
        } catch (err) {
            toast.error(err.response.data.message, {
                theme: "colored"
            });
        }
    }

    function handleInputType (e) {
        const charCode = e.target.value.charCodeAt()

        if (charCode > 47 && charCode < 58) {
            setEmail(MaskDocument(e.target.value))
        } else {
            setEmail(e.target.value)
        }
    }

    return (
        <Principal>
            <div>
                <div className="landing">
                    {/*<div className="landing-decoration"></div>*/}

                    <div className="landing-info">
                        <div className="logo">
                            <img className="form-box-decoration overflowing" src={Logo} alt="logo" />
                        </div>

                        {/*<div className="landing-info-pretitle"></div>
                        <h1 className="landing-info-title">BEM VINDO</h1>
                        <p className="landing-info-text">A nova geração de engajamento &amp; comunidade! conectada com seus colegas e competindo com nossas quests e badges apostando no crescimento profissional e pessoal!</p>*/}

                        {/*<div className="tab-switch">
                            <p className={`tab-switch-button login-register-form-trigger ${loginOrRegister === "login" && 'active'}`}
                                onClick={() => setLoginOrRegister('login')}
                            >
                                Login
                            </p>

                            <p className={`tab-switch-button login-register-form-trigger ${loginOrRegister === "register" && 'active'}`}
                                onClick={() => setLoginOrRegister('register')}
                            >
                                Registrar
                            </p>
                        </div>*/}
                    </div>

                    <div className="landing-form">
                        {loginOrRegister === 'login' ?
                            <ContainerLogin className="form-box login-register-form-element login">
                                {/*<img className="form-box-decoration overflowing" src={Rocket} alt="rocket" />*/}

                                <h2 className="form-box-title">Login</h2>

                                <form className="form" onSubmit={handleLogin}>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input value={email} type="text" id="login-username" className="form__input__focus" placeholder=""
                                                    onChange={handleInputType}
                                                    required
                                                />
                                                <label className="form__label login-username">Email / CPF ou Matrícula</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input small">
                                                <input type={passwordType} id="login-password" className="form__input__focus" placeholder=""
                                                    onChange={e => setPassword(e.target.value)}
                                                    required
                                                />
                                                {
                                                  passwordType === "password" ?
                                                  <button type="button" className='button primary' onClick={() => setPasswordType("text")}>
                                                    <i class="far fa-eye"></i>
                                                  </button> :
                                                  <button type="button" className='button primary' onClick={() => setPasswordType("password")}>
                                                    <i class="far fa-eye-slash"></i>
                                                  </button>
                                                }
                                                <label className="form__label login-password">Senha</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row space-between">
                                        <div className="form-item">
                                            <p className="form-link cursor-pointer" type="text" onClick={() => setModal(true)}>Recuperar a senha?</p>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <button className="button medium secondary" type="submit">
                                                Acessar
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </ContainerLogin>
                            :
                            <ContainerRegister className="form-box login-register-form-element register">
                                {/*<img className="form-box-decoration" src={Rocket} alt="rocket" />*/}

                                <h2 className="form-box-title">Crie sua conta!</h2>

                                <form className="form" onSubmit={handleRegister}>
                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input placeholder="" type="email" id="register-email" name="register_email" className="form__input__focus"
                                                    onChange={e => setEmail(e.target.value)}
                                                    required
                                                />
                                                <label className="form__label register-email">Email</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input placeholder="" type="text" id="register-username" name="register_username" className="form__input__focus"
                                                    onChange={e => setUser(e.target.value)}
                                                    required
                                                />
                                                <label className="form__label register-username">Nome</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input value={document} placeholder="" type="text" id="register_document" name="register_document" className="form__input__focus"
                                                    onChange={e => setDocument(MaskDocument(e.target.value))}
                                                    minLength={11}
                                                    maxLength={18}
                                                    required
                                                />
                                                <label className="form__label register_document">CPF / CNPJ</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input value={cellphone} placeholder="" type="text" id="register_cellphone" name="register_cellphone" className="form__input__focus"
                                                    onChange={e => setCellphone(MaskPhone(e.target.value))}
                                                    required
                                                />
                                                <label className="form__label register_cellphone">Celular</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <div className="form-input">
                                                <input placeholder="" type="password" id="register-password" name="register_password" className="form__input__focus"
                                                    onChange={e => setPassword(e.target.value)}
                                                    required
                                                />
                                                <label className="form__label register-password">Senha</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-item">
                                            <button className="button medium primary" type="submit">Registrar</button>
                                        </div>
                                    </div>
                                </form>

                                <p className="form-text">Você receberá a confirmação de cadastro por e-mail.Se você tiver problemas, 
                                    <Link to="/#"> contate-nos!</Link>!
                                </p>
                            </ContainerRegister>
                        }
                    </div>
                </div>
            <Modal showModal={modal} title="Redefinir senha" onClose={() => setModal(false)}>
                <form className="form">
                    <div className="form-row">
                        <div className="form-item">
                            <div className="form-input small mb-3">
                                <input value={email} type="text" id="login-username" className="form__input__focus" placeholder=""
                                    onChange={handleInputType}
                                    required
                                />
                                <label className="form__label">Email / CPF ou CPNJ</label>
                            </div>
                            <div className="mb-3">
                                <button
                                    className="popup-box-action full button secondary"
                                    onClick={(e) => handleRedefinePassword(e)}
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </div>
    </Principal>
    )
}