import styled from "styled-components";

export const ModalContainer = styled.div`
  position: absolute;
    display: flex;
    justify-content: center;
    height: 1px;
    overflow: hidden;
    font-size: 1.25rem;
    color: var(--text-light);

    &.visible {
      position: static;
    }

    &.visible .popup-box {
        left: auto;
        margin-left: auto;
        height: auto;
    }

    .popup-box-title {
      font-size: 2rem;
    }
`;

export const Modal = styled.div`
    &.small {
      max-width: 500px;
    }
`