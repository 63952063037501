import { useCallback, useMemo } from 'react'
import * as S from './style';

function RankingTable ({results, periodoDado, onda}) {
  const mes1 = useMemo(() => {
    if (onda.toString() === '1') return 'Fev';
    if (onda.toString() === '2') return 'Mai';
  }, [onda])
  const mes2 = useMemo(() => {
    if (onda.toString() === '1') return 'Mar';
    if (onda.toString() === '2') return 'Jun';
  }, [onda])

  const calcResultado = useCallback((result) => {
    const isAbsolute = result.kpi.canal === 'MERCHAN' && result.kpi.name.startsWith('PONTO EXTRA');
    const signal = isAbsolute ? '' : '%';
    let results = result.resultado1
    if (isAbsolute) {
      results /= 100;
    }
    return `${results}${signal}`;
  }, [])

  if (!results) return 'Carregando...'
  return <div className="widget-box" style={{marginTop: '32px'}}>
    <p className="widget-box-title">Resultados por KPI</p>

    {periodoDado}

    <S.BoxContent className="widget-box-content no-margin-top">
      <div className="table table-top-friends join-rows">
        <div className="table-header">

          <div className="table-header-column">
            <p className="table-header-title">KPI</p>
          </div>
    
          <div className="month1 table-header-column centered padded">
            <p className="table-header-title">Target {mes1}</p>
          </div>

          <div className="month1 table-header-column centered padded">
            <p className="table-header-title">Resultado {mes1}</p>
          </div>

          <div className="month2 table-header-column centered padded">
            <p className="table-header-title">Target {mes2}</p>
          </div>
          
          <div className="month2 table-header-column centered padded">
            <p className="table-header-title">Resultado {mes2}</p>
          </div>

          {onda.toString() === '1' && <div className="month3 table-header-column centered padded">
            <p className="table-header-title">Target Abr</p>
          </div>}

          {onda.toString() === '1' && <div className="month3 table-header-column centered padded">
            <p className="table-header-title">Resultado Abr</p>
          </div>}

          <div className="table-header-column centered padded">
            <p className="table-header-title">Target da Onda</p>
          </div>

          <div className="table-header-column centered padded">
            <p className="table-header-title">Realizado da Onda</p>
          </div>

          <div className="table-header-column centered padded">
            <p className="table-header-title">Resultado da Onda</p>
          </div>

          <div className="table-header-column centered padded">
            <p className="table-header-title">Estrelas</p>
          </div>
        </div>
    
        <div className="table-body">
          {results.map(result => <div key={result.id} className="table-row tiny">

            <div className="table-column">
              <p className="table-title" style={{fontSize: '.75rem'}}>{result.kpi ? result.kpi.name : result.kpiId}</p>
            </div>
            
            <div className="month1 table-column centered padded">
              <p className="table-title">{result.targetPeriodo1 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>
    
            <div className="month1 table-column centered padded">
              <p className="table-title">{result.realizadoPeriodo1 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="month2 table-column centered padded">
              <p className="table-title">{result.targetPeriodo2 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            <div className="month2 table-column centered padded">
              <p className="table-title">{result.realizadoPeriodo2 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>

            {onda.toString() === '1' && <div className="month3 table-column centered padded">
              <p className="table-title">{result.targetPeriodo3 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>}
    
            {onda.toString() === '1' && <div className="month3 table-column centered padded">
              <p className="table-title">{result.realizadoPeriodo3 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>}
    
            <div className="table-column centered padded">
              <p className="table-title">{result.target1 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>
    
            <div className="table-column centered padded">
              <p className="table-title">{result.realizado1 / (result.kpi.type === 1 ? 1 : 100)}{result.kpi.type === 1 ? '%' : ''}</p>
            </div>
    
            <div className="table-column centered padded">
              <p className="table-title">{calcResultado(result)}</p>
            </div>
            
            <div className="table-column centered padded">
              <p className="table-title">{result.estrelas1}</p>
            </div>

          </div>)}

        </div>
      </div>
    </S.BoxContent>
  </div>
}

export default RankingTable;
